import http from './httpService';
import {formatError, ProcessMessages} from './MessageService'


export async function list() { 
    return http.get('/api/notification/list').then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: []});
    });
}
export async function store(data) { 

    let request = {
        'to': data.to,
        'subject': data.subject,
        'content' : data.content,
        "content_type" : data.content_type || "text"
    }
    

    return http.post('/api/notification/store', request).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}

export async function update(data) { 
    let request = {
        'id': data.id,
        'to': data.to,
        'subject': data.subject,
        'content' : data.content,
        "content_type" : data.content_type  || "text"
    }

    return http.post('/api/notification/' + data.id + '/update',request).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}

export async function send(data) { 
    
    let request = {
        'id': data
    }

    return http.post('/api/notification/' + data + '/send',request).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}
export async function sendAll(data) { 
    return http.post('/api/notification/' + data.id + '/sendAll').then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}

export async function destroy(data) { 
    let request = {
        'id': data.id
    }

    return http.delete('/api/notification/' + data.id + '/destroy',request).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}
