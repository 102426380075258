
import { Logout, isLogged } from './CommonActions';

import { filter, store, update, destroy } from '../../services/AddressService';

export const FILTER_CONFIRMED_ACTION = '[address filter action] confirmed filter';
export const FILTER_FAILED_ACTION = '[address filter action] failed filter';

export const RELOAD_ENABLE_ACTION = '[address reload action] enable reload';
export const RELOAD_DISABLE_ACTION = '[address reload action] disable reload';

export const SET_DATA_ACTION = '[address set data action] set data';

export const UPDATE_DATAFORMNEW_ACTION = '[address update data form new action] update data form new'

export const STORE_CONFIRMED_ACTION = '[address store action] confirmed store';
export const STORE_FAILED_ACTION = '[address store action] failed store';

export const UPDATE_CONFIRMED_ACTION = '[address update action] confirmed update';
export const UPDATE_FAILED_ACTION = '[address update action] failed update';

export const DESTROY_CONFIRMED_ACTION = '[capability destroy action] confirmed destroy';
export const DESTROY_FAILED_ACTION = '[capability destroy action] failed destroy';



export function storeAction(data) {
    return  (dispatch) => {
        
        store(data).then(response => {
            if (isLogged(response)) {
                dispatch(storeConfirmedAction(response.data.response || {}));	
                dispatch(enableReloadAction());
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function setDataAction(data) {
    return {
        type: SET_DATA_ACTION,
        payload: data,
    };
}

export function updateDataFormNewAction(data) {
    return {
        type: UPDATE_DATAFORMNEW_ACTION,
        payload: data,
    };
}


export function updateAction(data) {
    return  (dispatch) => {
        
        update(data).then(response => {
            if (isLogged(response)) {
                dispatch(updateConfirmedAction(response.data.response || {}));	
                dispatch(enableReloadAction());
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function updateConfirmedAction(data) {
    return {
        type: UPDATE_CONFIRMED_ACTION,
        payload: data,
    };
}


export function updateFailedAction(data) {
    return {
        type: UPDATE_FAILED_ACTION,
        payload: data,
    };
}

export function filterAction(params) {
    return (dispatch) => {

        filter(params).then(response => {
            if (isLogged(response)) {
                dispatch(filterConfirmedAction(response.data));
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function filterConfirmedAction(data) {
    return {
        type: FILTER_CONFIRMED_ACTION,
        payload: data,
    };
}

export function filterFailedAction(data) {
    return {
        type: FILTER_FAILED_ACTION,
        payload: data,
    };
}


export function enableReloadAction() {
    return {
        type: RELOAD_ENABLE_ACTION,
        payload: true,
    };
}

export function disableReloadAction() {
    return {
        type: RELOAD_DISABLE_ACTION,
        payload: false,
    };

}

export function storeConfirmedAction(data) {
    return {
        type: STORE_CONFIRMED_ACTION,
        payload: data,
    };
}


export function storeFailedAction(data) {
    return {
        type: STORE_FAILED_ACTION,
        payload: data,
    };
}


export function destroyAction(data) {
    return  (dispatch) => {
        
        destroy(data).then(response => {            
            if (isLogged(response)) {
                dispatch(destroyConfirmedAction(response.data));	
                dispatch(enableReloadAction());
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function destroyConfirmedAction(data) {
    return {
        type: DESTROY_CONFIRMED_ACTION,
        payload: data,
    };
}


export function destroyFailedAction(data) {
    return {
        type: DESTROY_FAILED_ACTION,
        payload: data,
    };
}
