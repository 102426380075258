import React, { useState, useEffect } from 'react';
import { useLocale } from "../../../../../context/Locale";
import ModalConfirm from '../../../Common/Modal/ModalConfirm';
import { updateAction, getAction } from '../../../../../store/actions/ModelActions.js'
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup } from "react-bootstrap";
import Select from '../../../Base/Select.js'


import { SizeOptions } from '../../../../../tools/size.js'
import { SaleLevelOptions } from '../../../../../tools/saleslevel.js'

const FormCompanyEdit = (props) => {

    const dispatch = useDispatch();
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const model = "company";

    const data = useSelector((store) => store.company.data);

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
    const [showComfirm, setShowConfirm] = useState(false);
    const saleLevelList = [].concat(SaleLevelOptions.filter(() => { return true }));
    const sizeList = SizeOptions.filter(() => { return true });

    useEffect(() => {

        if (step === "init") {
            if (props.data !== undefined && props.data.id !== undefined) {
                dispatch(getAction(model, {
                    id: props.data.id
                }));
            }
            setStep("edit");

        } else {
            setValue({ ...data });
        }

    }, [step, data]);

   


    const getValue = (id, defaultValue) => {
        return value[id] ? value[id] : defaultValue
    }

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;;
        setValue(newValue);
    }

    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {

        const request = {
            "id": value.id,
            "name": value.name,
            "category": value.category,
            "legal_id": value.legal_id,
            "size": value.size,
            "sales_level": value.sales_level,
            "branch_number": value.branch_number
        }

        dispatch(updateAction(model, request));
        setShowConfirm(false);

        if (props.onSave !== undefined) {
            props.onSave(request);
        }

    }

    function onCancelForm() {
        setShowConfirm(false);
        if (props.onCancel !== undefined) {
            props.onCancel();
        }
    }


    if (data.id === undefined) {
        return (<></>);
    } else {

        return (<>
            <div className="row">
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"legal_id"} className="form-label">{"Rut"}<span className="text-danger">*</span></label>
                    <input value={getValue("legal_id", "")} disabled={true} htmlFor="legal_id" type="text" id={"legal_id"} placeholder="" className="form-control"   ></input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"name"} className="form-label">Nombre<span className="text-danger">*</span></label>
                    <input value={getValue("name", "")} onChange={(e) => { onChange("name", e.target.value); e.preventDefault() }} htmlFor="name" type="text" id={"name"} placeholder="" className="form-control"  ></input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"category"} className="form-label">Rubro<span className="text-danger">*</span></label>
                    <input value={getValue("category", "")} onChange={(e) => { onChange("category", e.target.value); e.preventDefault() }} htmlFor="category" type="text" id={"category"} placeholder="" className="form-control"   ></input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"size"} className="form-label">Tamaño<span className="text-danger">*</span></label>
                    <Select
                        value={getValue("size", "")}
                        onChange={onChange} data={sizeList.map(
                            function (item) {
                                return { value: item.value, text: item.label }
                            }
                        )} text={"Seleccione"} id={"size"} ></Select>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"sales_level"} className="form-label">Nivel de Ventas<span className="text-danger">*</span></label>
                    <Select
                        value={getValue("sales_level", "")}
                        onChange={onChange} data={saleLevelList.map(
                            function (item) {
                                return { value: item.value, text: item.label }
                            }
                        )} text={"Seleccione"} id={"sales_level"} ></Select>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"branch_number"} className="form-label">{"Número de Locales"}<span className="text-danger">*</span></label>
                    <input value={getValue("branch_number", 0)} onChange={(e) => { onChange("branch_number", e.target.value); e.preventDefault() }} htmlFor="branch_number" type="number" min={0} id={"branch_number"} placeholder="" className="form-control"   ></input>
                </div>

            </div>
            <div className="row">
                <div className="col-xl-3 mb-3">
                    <ButtonGroup>
                        <Button onClick={() => onSaveFormConfirm()} className="btn-sm" variant="primary"> {"Guardar"}</Button>
                        <Button onClick={() => onCancelForm()} className="btn-sm" variant="secondary"> {"Cancelar"}</Button>
                    </ButtonGroup>
                </div>
            </div>
            <ModalConfirm
                show={showComfirm}
                title={"Confirmación"}
                message={"¿Está seguro de guardar los cambios realizados?"}
                cancelButtonText={"No"} confirmButtonText={"Sí"}
                onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
            >
            </ModalConfirm>
        </>);
    }


}

export default FormCompanyEdit;