
import { Logout, isLogged, Unmutex } from './CommonActions';
import { store, destroy, update, list, deleted, recover, filter,get } from '../../services/ApiService';

export const RELOAD_ENABLE_ACTION = '[model reload action] enable reload';
export const RELOAD_DISABLE_ACTION = '[model reload action] disable reload';

export const DESTROY_CONFIRMED_ACTION = '[model destroy action] confirmed destroy';
export const DESTROY_FAILED_ACTION = '[model destroy action] failed destroy';

export const FILTER_CONFIRMED_ACTION = '[model filter action] confirmed filter';
export const FILTER_FAILED_ACTION = '[model filter action] failed filter';

export const UPDATE_CONFIRMED_ACTION = '[model update action] confirmed update';
export const UPDATE_FAILED_ACTION = '[model update action] failed update';

export const RECOVER_CONFIRMED_ACTION = '[model recover action] confirmed recover';
export const RECOVER_FAILED_ACTION = '[model recover action] failed recover';

export const OPEN_FORMEDIT_ACTION = '[model open form edit action] open form edit'
export const UPDATE_DATAFORMEDIT_ACTION = '[model update data form edit action] update data form edit'

export const SET_TIMER_ACTION = '[model set timer action] set timer';
export const SET_DATA_ACTION = '[model set data action] set data';

export const GETALL_CONFIRMED_ACTION = '[model getall action] confirmed getall';
export const GETALL_FAILED_ACTION = '[model getall action] failed getall';


export const GET_CONFIRMED_ACTION = '[model get action] confirmed get';
export const GET_FAILED_ACTION = '[model get action] failed get';


export const GETDELETED_CONFIRMED_ACTION = '[model deleted action] confirmed deleted';
export const GETDELETED_FAILED_ACTION = '[model deleted action] failed deleted';



export const STORE_CONFIRMED_ACTION = '[model store action] confirmed store';
export const STORE_FAILED_ACTION = '[model store action] failed store';
export const OPEN_FORMNEW_ACTION = '[model open form new action] open form new'
export const UPDATE_DATAFORMNEW_ACTION = '[model update data form new action] update data form new'


export function SetModel(model, data){
    return { ...data,model : model};
}

export function SetModelAction(model,action){
    return action.replace("model", model);
}

export function setTimerAction(model,data) {
    
    return (dispatch) => {
        let beforeTime = data;
        let nowTime = (new Date()).getTime();
        let milliseconds = nowTime - beforeTime;

        if (milliseconds > 5 * 1000) {
            dispatch(setTimerConfirmedAction(model,{"time": nowTime}));
            dispatch(enableReloadAction(model));
        } else {
            dispatch(setTimerConfirmedAction(model,{"time": data}));

        }
    };
}



export function filterAction(model,data) {
    return (dispatch) => {

        filter(SetModel(model,data)).then(response => {
            if (isLogged(response)) {
                dispatch(filterConfirmedAction(model,response.data));
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function filterConfirmedAction(model,data) {
    return {
        type: SetModelAction(model,FILTER_CONFIRMED_ACTION),
        payload: data,
    };
}

export function filterFailedAction(model,data) {
    return {
        type: SetModelAction(model,FILTER_FAILED_ACTION),
        payload: data,
    };
}


export function updateAction(model,data) {
    return  (dispatch) => {
        
        update(SetModel(model,data)).then(response => {
            if (isLogged(response)) {
                dispatch(updateConfirmedAction(model,response.data.response || {}));	
                dispatch(enableReloadAction(model));
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function recoverAction(model,data) {
    return  (dispatch) => {
        
        recover(SetModel(model,data)).then(response => {
            if (isLogged(response)) {
                dispatch(recoverConfirmedAction(model,response.data.response || {}));	
                dispatch(enableReloadAction(model));
            } else {
                dispatch(Logout());
            }
        });
    };
}


export function getAllAction(model,data) {
    return (dispatch) => {

        list(SetModel(model,data)).then(response => {
            if (isLogged(response)) {
                dispatch( getAllConfirmedAction(model,response.data));
                dispatch(disableReloadAction(model));	
            } else {
                dispatch(Logout());
            }

            dispatch(Unmutex(model));
        });
    };
}

export function getAction(model,data) {
    return (dispatch) => {

        get(SetModel(model,data)).then(response => {
            if (isLogged(response)) {
                dispatch( getConfirmedAction(model,response.data.response));
                dispatch(disableReloadAction(model));	
            } else {
                dispatch(Logout());
            }

            dispatch(Unmutex(model));
        });
    };
}



export function getDeletedAction(model,data) {
    return (dispatch) => {

        deleted(SetModel(model,data)).then(response => {
            if (isLogged(response)) {
                dispatch( getDeletedConfirmedAction(model,response.data));
                dispatch(disableReloadAction(model));	
            } else {
                dispatch(Logout());
            }

            dispatch(Unmutex(model));
        });
    };
}



export function storeAction(model,data) {
    return  (dispatch) => {
        
        store(SetModel(model,data)).then(response => {
            if (isLogged(response)) {
                dispatch(storeConfirmedAction(model,response.data.response || {}));	
                dispatch(enableReloadAction(model));
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function destroyAction(model,data) {
    return  (dispatch) => {
        
        destroy(SetModel(model,data)).then(response => {            
            if (isLogged(response)) {
                dispatch(destroyConfirmedAction(model,response.data.response || {}));	
                dispatch(enableReloadAction(model));
            } else {
                dispatch(Logout());
            }
        });
    };
}


export function enableReloadAction(model) {
    return {
        type: SetModelAction(model,RELOAD_ENABLE_ACTION),
        payload: true,
    };
}

export function disableReloadAction(model) {
    return {
        type: SetModelAction(model,RELOAD_DISABLE_ACTION),
        payload: false,
    };

}


export function destroyConfirmedAction(model,data) {
    return {
        type: SetModelAction(model,DESTROY_CONFIRMED_ACTION),
        payload: data,
    };
}


export function destroyFailedAction(model,data) {
    return {
        type: SetModelAction(model,DESTROY_FAILED_ACTION),
        payload: data,
    };
}

export function updateConfirmedAction(model,data) {
    return {
        type: SetModelAction(model,UPDATE_CONFIRMED_ACTION),
        payload: data,
    };
}

export function recoverConfirmedAction(model,data) {
    return {
        type: SetModelAction(model,RECOVER_CONFIRMED_ACTION),
        payload: data,
    };
}



export function updateFailedAction(model,data) {
    return {
        type: SetModelAction(model,UPDATE_FAILED_ACTION),
        payload: data,
    };
}

export function recoverFailedAction(model,data) {
    return {
        type: SetModelAction(model,RECOVER_FAILED_ACTION),
        payload: data,
    };
}
export function openEditFormAction(model,data) {
    return {
        type: SetModelAction(model,OPEN_FORMEDIT_ACTION),
        payload: data,
    };
}



export function updateDataFormEditAction(model,data) {
    return {
        type: SetModelAction(model,UPDATE_DATAFORMEDIT_ACTION),
        payload: data,
    };
}

export function setTimerConfirmedAction(model,data) {
    return {
        type: SetModelAction(model,SET_TIMER_ACTION),
        payload: data,
    };
}

export function setDataAction(model,data) {
    return {
        type: SetModelAction(model,SET_DATA_ACTION),
        payload: data,
    };
}


export function  getAllConfirmedAction(model,data) {
    return {
        type: SetModelAction(model,GETALL_CONFIRMED_ACTION),
        payload: data,
    };
}

export function  getAllFailedAction(model,data) {
    return {
        type: SetModelAction(model,GETALL_FAILED_ACTION),
        payload: data,
    };
}


export function  getConfirmedAction(model,data) {
    return {
        type: SetModelAction(model,GET_CONFIRMED_ACTION),
        payload: data,
    };
}

export function  getFailedAction(model,data) {
    return {
        type: SetModelAction(model,GET_FAILED_ACTION),
        payload: data,
    };
}



export function  getDeletedConfirmedAction(model,data) {
    return {
        type: SetModelAction(model,GETDELETED_CONFIRMED_ACTION),
        payload: data,
    };
}

export function  getDeletedFailedAction(model,data) {
    return {
        type: SetModelAction(model,GETDELETED_FAILED_ACTION),
        payload: data,
    };
}

export function openNewFormAction(model,data) {
    return {
        type: SetModelAction(model,OPEN_FORMNEW_ACTION),
        payload: data,
    };
}


export function updateDataFormNewFormAction(model,data) {
    return {
        type: SetModelAction(model,UPDATE_DATAFORMNEW_ACTION),
        payload: data,
    };
}


export function storeConfirmedAction(model,data) {
    return {
        type: SetModelAction(model,STORE_CONFIRMED_ACTION),
        payload: data,
    };
}


export function storeFailedAction(model,data) {
    return {
        type: SetModelAction(model,STORE_FAILED_ACTION),
        payload: data,
    };
}
