import axios from 'axios';    

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    let item = JSON.parse(localStorage.getItem('userDetails'));
    // Do something before request is sent
    if(item !== undefined && item !== null && item.idToken!== undefined && item.idToken!== null){
        config.headers.Authorization = `Bearer ${item.idToken}`;
    }
    // OR config.headers.common['Authorization'] = `Bearer ${your_token}`;
    //config.baseURL = 'http://api.bundle.cl:8008';
    config.baseURL = process.env.REACT_APP_API_HOST;
    config.withCredentials= true;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
};