import React, { useState, useRef, useEffect } from 'react';
import MainPagetitle from '../../layouts/MainPagetitle';
import ModalOffcanvas from '../Common/Modal/ModalOffcanvas';
import ModalConfirm from '../Common/Modal/ModalConfirm';
import NavMenu from '../Common/NavMenu/NavMenu.js';

import { useSelector, useDispatch } from "react-redux";
import { useLocale } from "../../../context/Locale";

import {
    getAllAction,
    storeAction,
    openNewFormAction,
    destroyAction,
    updateAction,
    enableReloadAction as enableReloadUserRoleAction
} from '../../../store/actions/ModelActions'

import {
    getAllAction as  getAllActionUser
} from '../../../store/actions/UserListActions.js'


import FormUserSelector from './Form/FormUserSelector';
import Grid from '../Common/Grid/Grid';

const UserRole = () => {
    const pageName = "userroles";
    const model = "userrole";
    const capabilities = useSelector((store) => store.user.capabilities);
    const canViewAdmin = capabilities.map(function (c) { return c.code }).includes('MENU_ADMIN');
    const canView = capabilities.map(function (c) { return c.code }).includes('USERROLE_LIST');
    const role = useSelector((store) => store.role.data);

    const user = useSelector((store) => store.user.data);
    const path = useSelector((store) => store.common.path);

    const newItem = capabilities.map(function (c) { return c.code }).includes("USERROLE_CREATE");
    const destroyItem = capabilities.map(function (c) { return c.code }).includes("USERROLE_DESTROY");
    const exportData = capabilities.map(function (c) { return c.code }).includes("USERROLE_EXPORT");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [typeForm, setTypeForm] = useState("");
    const [titleForm, setTitleForm] = useState("");
    const [toDestroy, setToDestroy] = useState({});

    const [showComfirmNew, setShowConfirmNew] = useState(false);
    const [showComfirmEdit, setShowConfirmEdit] = useState(false);
    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);
    const [step, setStep] = useState("init");

    const dispatch = useDispatch();

    const list = useSelector((store) => store.userrole.list);
    const records = list.filter(rc => user ? (
        user ? user.id ? (String(user.id) === String(rc.user_id)) : true : true) : true);

    const gridConf = [
        { label: strings.table.userrole.header.user_id, key: "user_id", className: "", filter: true },
        { label: strings.table.userrole.header.user_email, key: "user_email", className: "", filter: true },
        { label: strings.table.userrole.header.user_name, key: "user_name", className: "", filter: true },
        { label: strings.table.userrole.header.role_code, key: "role_code", className: "", filter: true },
        { label: strings.table.userrole.header.role_description, key: "role_description", className: "", filter: true }
    ]

    const dataNewForm = useSelector((store) => store.userrole.dataNewForm);
    const dataEditForm = useSelector((store) => store.userrole.dataEditForm);

    const userRoleElement = useRef();

    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction(model, {}));
            dispatch(getAllActionUser({}));
            setStep("loaded");
        }

    }, [dispatch, step]);




    function onSaveFormConfirm() {
        if (typeForm === "FormNewUserRole") {
            setShowConfirmNew(true);
        }
        if (typeForm === "FormEditUserRole") {
            setShowConfirmEdit(true);
        }
        
    }

    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
    }

    function onDestroyForm() {
        dispatch(destroyAction(model, { ...toDestroy, id: toDestroy.user_id + "/" + toDestroy.role_code }));
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onSaveForm() {
        dispatch(enableReloadUserRoleAction(model));
        if (typeForm === "FormNewUserRole") {
            dispatch(storeAction(model, dataNewForm));

        }

        if (typeForm === "FormEditUserRole") {
            dispatch(updateAction(model, dataEditForm));
        }
        
        setShowConfirmNew(false);
        setShowConfirmEdit(false);
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onCancelForm() {
        setShowConfirmNew(false);
        setShowConfirmEdit(false);
        setShowConfirmDestroy(false);
        setToDestroy({});
    }

    function onClickNewForm() {
        setTypeForm("FormNewUserRole");
        setTitleForm(strings.form.addUserRole.title);

        dispatch(openNewFormAction(model,
            {
                "role_code": role.code || "",
                "user_id": user.id || ""
            }
        ));

        userRoleElement.current.showModal();
    }

    if (path !== pageName && path !== "") {
        return (<></>);
    } else {

        return (<>
            <MainPagetitle mainTitle={strings.page.userrole.title} pageTitle={strings.page.userrole.title} parentTitle={strings.page.home.title} />
            <div className="container-fluid">
                <NavMenu />
                {(canViewAdmin && canView) &&
                    <>
                        <FormUserSelector value={user ? user.id ? user.id : "" : ""} ></FormUserSelector>
                        <div className="row">
                            <div className="col-xl-12">
                                <Grid
                                    list={[...records]}
                                    title={"Roles de " + (user ? user.name ? " usuario " + user.name : "todos los usuarios" : "todos los usuarios")}
                                    conf={gridConf}
                                    onClickNewItem={onClickNewForm}
                                    onClickDestroy={onDestroyFornConfirm}

                                    newItem={newItem}
                                    destroyItem={destroyItem}
                                    exportData={exportData}

                                // onClickEdit={onClickEditForm}

                                ></Grid>
                            </div>
                        </div>
                    </>
                }
                <ModalOffcanvas
                    ref={userRoleElement}
                    Title={titleForm}
                    TypeForm={typeForm}
                    onSave={onSaveFormConfirm}
                    onCancel={onCancelForm}
                />
                <ModalConfirm
                    show={showComfirmNew}
                    title={"Confirmación"}
                    message={"¿Está seguro de agregar este rol a este usuario"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>

                <ModalConfirm
                    show={showComfirmEdit}
                    title={"Confirmación"}
                    message={"¿Está seguro de editar este rol de este usuario"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>
                <ModalConfirm
                    show={showComfirmDestroy}
                    title={"Confirmación"}
                    message={"¿Está seguro de eliminar este rol de este usuario"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                >
                </ModalConfirm>
            </div>

        </>);
    }

};

export default UserRole;