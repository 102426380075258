import React, { useState, useEffect } from 'react';
import ModalConfirm from '../../Common/Modal/ModalConfirm';
import { storeAction, getAction } from '../../../../store/actions/ModelActions.js'
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup } from "react-bootstrap";
import Select from '../../Base/Select.js'
import { AutoSendNotificationOptions } from '../../../../tools/notifications.js'


const FormSettingNotifications = (props) => {
    const dispatch = useDispatch();

    const model = "setting";
    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const canEditItem = HasCapability(capabilities, 'SETTING_EDIT');
    const data = (props.conf ? props.conf : {});

    const [showComfirm, setShowComfirm] = useState(false);

    const [step, setStep] = useState("init");

    const [value, setValue] = useState({});

    useEffect(() => {

        if (step === "init") {
            if (data !== undefined) {
                setValue({ ...data });
            } else {
                setValue({ "code": "ENABLE_AUTOSEND_NOTIFICATION", "value": "INACTIVO" });
            }
            setStep("edit");

        }

    }, [step, data]);

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;;
        setValue(newValue);
    }


    const onSaveFormConfirm = () => {
        setShowComfirm(true)
    }


    const getValue = (id, defaultValue) => {
        return value[id] ? value[id] : defaultValue
    }

    function onSaveForm() {

        const request = {
            "code": value.code || "ENABLE_AUTOSEND_NOTIFICATION",
            "value": value.value
        }

        dispatch(storeAction(model, request));
        setShowComfirm(false);

        if (props.onSave !== undefined) {
            props.onSave(request);
        }

    }

    function onCancelForm() {
        setShowComfirm(false);
        if (props.onCancel !== undefined) {
            props.onCancel();
        }
    }

    return (
        <>
            <div className="col-xl-12"> <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Notificaciones</h4>
                </div>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className="col-xl-3 mb-3">
                                    <label htmlFor={"value"} className="form-label">{"Envío automático"}<span className="text-danger">*</span></label>
                                    <Select
                                        value={getValue("value", "")}
                                        onChange={onChange} data={AutoSendNotificationOptions.map(
                                            function (item) {
                                                return { value: item.value, text: item.label }
                                            }
                                        )} text={"Seleccione"} id={"value"} className="form-control"  ></Select>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-xl-3 mb-3">
                                    <ButtonGroup>
                                        {canEditItem &&
                                            <Button onClick={() => onSaveFormConfirm()} className="btn-sm" variant="primary"> {"Guardar"}</Button>
                                        }
                                    </ButtonGroup>
                                </div>
                            </div>
                            <ModalConfirm
                                show={showComfirm}
                                title={"Confirmación"}
                                message={"¿Está seguro de guardar los cambios realizados?"}
                                cancelButtonText={"No"} confirmButtonText={"Sí"}
                                onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                            >
                            </ModalConfirm>
                        </form>
                    </div>
                </div>
            </div>
            </div>
        </>
    )

}

export default FormSettingNotifications;