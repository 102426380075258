import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";


// User
import {
    getCapabilitiesAction,
    getAllAction as getAllUserAction
} from '../../../store/actions/UserListActions'
import {
    disableReloadAction as disableReloadUserAction,
    disableReloadCapabilityAction as disableReloadUserCapabilityAction
} from '../../../store/actions/UserActions'



// Common
import { getUserAction, Mutex } from '../../../store/actions/CommonActions'


import {
    getAllAction,
    disableReloadAction
} from '../../../store/actions/ModelActions'


import { getAllAction as getAllNotificationAction } from '../../../store/actions/NotificationListActions'
import {
    disableReloadAction as disableReloadNotificationAction
} from '../../../store/actions/NotificationActions'

import {
    getAllAction as getAllReportAction,
    feesByInscriptionAction,
    feesByDateRangeAction,
    pendingFeesByDateRangeAction,
    defaulterFeesAction,
    feesByInscriptionByDateRangeAction,
    defaulterFeesVersusAmountBalanceAction,
    defaulterFeesVersusPaidAction
} from '../../../store/actions/ReportListActions'

import {
    disableReloadAction as disableReloadReportAction
} from '../../../store/actions/ReportActions'


const Loader = () => {

    const dispatch = useDispatch();
    const capabilities = useSelector((store) => store.user.capabilities);
    const mutex = useSelector((store) => store.common.mutex);

    const reloadUserCapabilities = useSelector((store) => store.user.reloadCapabilities);
    const reloadSystemCapability = useSelector((store) => store.capability.reload);
    const reloadUsers = useSelector((store) => store.user.reload);
    const reloadUser = useSelector((store) => store.common.reloadUser);
    const reloadCompanies = useSelector((store) => store.company.reload);
    const reloadFees = useSelector((store) => store.fee.reload);
    const reloadInscriptions = useSelector((store) => store.inscription.reload);
    const reloadNotifications = useSelector((store) => store.notification.reload);
    const reloadPayments = useSelector((store) => store.payment.reload);
    const reloadPeople = useSelector((store) => store.person.reload);
    const reportCode = useSelector((store) => store.report.reportCode);
    const reloadReports = useSelector((store) => store.report.reload);
    const paramsReport = useSelector((store) => store.report.params);
    const reloadRoles = useSelector((store) => store.role.reload);
    const reloadUserRoles = useSelector((store) => store.userrole.reload);
    const reloadRoleCapability = useSelector((store) => store.rolecapability.reload);
    const reloadFeeTemplates = useSelector((store) => store.feetemplate.reload);



    function reloadUserCapabilitiesCall() {
        dispatch(Mutex("reloadUserCapabilities"));
        dispatch(disableReloadUserCapabilityAction());
        dispatch(getCapabilitiesAction());
    }

    function reloadSystemCapabilityCall() {
        dispatch(Mutex("reloadSystemCapability"));
        dispatch(disableReloadAction('capability'));
        dispatch(getAllAction('capability', {}));
    }

    function reloadUserCall() {
        dispatch(Mutex("reloadUser"));
        dispatch(getUserAction());
    }
    function reloadUsersCall() {
        dispatch(Mutex("reloadUsers"));
        dispatch(disableReloadUserAction());
        dispatch(getAllUserAction());
    }

    function reloadFeeTemplatesCall() {
        dispatch(Mutex("reloadFeeTemplates"));
        dispatch(disableReloadAction('feetemplate'));
        dispatch(getAllAction('feetemplate', {}));
    }
    function reloadUserRolesCall() {
        dispatch(Mutex("reloadUserRoles"));
        dispatch(disableReloadAction('userrole'));
        dispatch(getAllAction('userrole', {}));
    }

    function reloadCompaniesCall() {
        dispatch(Mutex("reloadCompanies"));
        dispatch(disableReloadAction("company"));
        dispatch(getAllAction("company", {}));
    }

    function reloadFeesCall() {
        dispatch(Mutex("reloadFees"));
        dispatch(disableReloadAction('fee'));
        dispatch(getAllAction('fee', {}));
    }

    function reloadInscriptionsCall() {
        dispatch(Mutex("reloadInscriptions"));
        dispatch(disableReloadAction("inscription"));
        dispatch(getAllAction("inscription", {}));
    }

    function reloadNotificationsCall() {
        dispatch(Mutex("reloadNotifications"));
        dispatch(disableReloadNotificationAction());
        dispatch(getAllNotificationAction());
    }

    function reloadPaymentsCall() {
        dispatch(Mutex("reloadPayments"));
        dispatch(disableReloadAction('payment'));
        dispatch(getAllAction('payment', {}));
    }

    function reloadPeopleCall() {
        dispatch(Mutex("reloadPeople"));
        dispatch(disableReloadAction("person"));
        dispatch(getAllAction("person", {}));
    }

    function reloadReportsCall() {
        dispatch(Mutex("reloadReports"));
        if (reportCode === "feesByDateRange") {

            dispatch(disableReloadReportAction());
            dispatch(feesByDateRangeAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }


        if (reportCode === "pendingFeesByDateRange") {

            dispatch(disableReloadReportAction());
            dispatch(pendingFeesByDateRangeAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "feesByInscription") {
            dispatch(disableReloadReportAction());
            dispatch(feesByInscriptionAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "feesByInscriptionByDateRange") {
            dispatch(disableReloadReportAction());
            dispatch(feesByInscriptionByDateRangeAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }



        if (reportCode === "fees") {
            dispatch(disableReloadReportAction());
            dispatch(getAllReportAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "defaulterFees") {
            dispatch(disableReloadReportAction());
            dispatch(defaulterFeesAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "defaulterFeesVersusAmountBalance") {
            dispatch(disableReloadReportAction());
            dispatch(defaulterFeesVersusAmountBalanceAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "defaulterFeesVersusPaid") {
            dispatch(disableReloadReportAction());
            dispatch(defaulterFeesVersusPaidAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }
    }

    function reloadRolesCall() {
        dispatch(Mutex("reloadRoles"));
        dispatch(disableReloadAction('role'));
        dispatch(getAllAction('role', {}));
    }



    function reloadRoleCapabilityCall() {
        dispatch(Mutex("reloadRoleCapability"));
        dispatch(disableReloadAction('rolecapability'));
        dispatch(getAllAction('rolecapability', {}));
    }

    useEffect(() => {

        function process() {

            const isMutex = (mutex !== "");

            if (!isMutex) {

                if (reloadUserCapabilities) {
                    reloadUserCapabilitiesCall();

                } else if (reloadUser) {
                    reloadUserCall();

                } if (Array.isArray(capabilities) && capabilities.length > 0) {
                    if (reloadReports && capabilities.map(function (c) { return c.code }).includes('REPORT_LIST')) {
                        reloadReportsCall();
                    } else if (reloadFees && capabilities.map(function (c) { return c.code }).includes('FEE_LIST')) {
                        reloadFeesCall();
                    } else if (reloadPayments && capabilities.map(function (c) { return c.code }).includes('PAYMENT_LIST')) {
                        reloadPaymentsCall();
                    } /*else if (reloadSystemCapability && capabilities.map(function (c) { return c.code }).includes('CAPABILITY_LIST')) {
                        reloadSystemCapabilityCall();
                    } else if (reloadFeeTemplates && capabilities.map(function (c) { return c.code }).includes('FEETEMPLATE_LIST')) {
                        reloadFeeTemplatesCall();
                    } else if (reloadUsers && capabilities.map(function (c) { return c.code }).includes('USER_LIST')) {
                        reloadUsersCall();
                    } else if (reloadCompanies && capabilities.map(function (c) { return c.code }).includes('COMPANY_LIST')) {
                        reloadCompaniesCall();
                    } else if (reloadInscriptions && capabilities.map(function (c) { return c.code }).includes('INSCRIPTION_LIST')) {
                        reloadInscriptionsCall();
                    } else if (reloadNotifications && capabilities.map(function (c) { return c.code }).includes('INSCRIPTION_LIST')) {
                        reloadNotificationsCall();
                    } else if (reloadPeople && capabilities.map(function (c) { return c.code }).includes('PERSON_LIST')) {
                        reloadPeopleCall();
                    } else if (reloadRoles && capabilities.map(function (c) { return c.code }).includes('ROLE_LIST')) {
                        reloadRolesCall();
                    } else if (reloadUserRoles && capabilities.map(function (c) { return c.code }).includes('USERROLE_LIST')) {
                        reloadUserRolesCall();
                    } else if (reloadRoleCapability && capabilities.map(function (c) { return c.code }).includes('ROLE_LIST')) {
                        reloadRoleCapabilityCall();
                    }*/
                }

            }
        }

        process();

    });


    return (<></>);
}

export default Loader;
