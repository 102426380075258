import {
    FILTER_CONFIRMED_ACTION,
    FILTER_FAILED_ACTION,
    RELOAD_ENABLE_ACTION,
    RELOAD_DISABLE_ACTION,
    SET_DATA_ACTION

} from '../actions/ProvinceFilterActions';


const initialState = {
    list: [],
    reload: false
};

export function ProvinceReducer(state = initialState, action) {

      
    if (action.type === FILTER_CONFIRMED_ACTION) {
        return {
            ...state,
            list: action.payload,
            reload: false
        };
    }
    if (action.type === FILTER_FAILED_ACTION) {
        return {
            ...state,
            reload: false
        };
    }
    
    if (action.type === RELOAD_ENABLE_ACTION) {
        return {
            ...state,
            reload: true
        };
    }

    if (action.type === RELOAD_DISABLE_ACTION) {
        return {
            ...state,
            reload: false
        };
    }

    if (action.type === SET_DATA_ACTION) {
        return {
            ...state,
            data: action.payload
        };
    }

    return state;
}