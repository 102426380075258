import { Logout, isLogged } from './CommonActions';
import { enableReloadAction} from './UserActions';
import { update } from '../../services/UserService';

export const UPDATE_CONFIRMED_ACTION = '[user update action] confirmed update';
export const UPDATE_FAILED_ACTION = '[user update action] failed update';


export const OPEN_FORMEDIT_ACTION = '[user open form edit action] open form edit'
export const UPDATE_DATAFORMEDIT_ACTION = '[user update data form edit action] update data form edit'

export function updateAction(data) {
    return (dispatch) => {

        update(data).then(response => {

            if (isLogged(response)) {
                dispatch(updateConfirmedAction(response.data.response || {}));
                dispatch(enableReloadAction());
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function updateConfirmedAction(data) {
    return {
        type: UPDATE_CONFIRMED_ACTION,
        payload: data,
    };
}


export function updateFailedAction(data) {
    return {
        type: UPDATE_FAILED_ACTION,
        payload: data,
    };
}

export function openEditFormAction(data) {
    return (dispatch) => {
        dispatch(openEditFormConfirmedAction(data));
    };
}

export function openEditFormConfirmedAction(data) {
    return {
        type: OPEN_FORMEDIT_ACTION,
        payload: data,
    };
}

export function updateDataFormEditAction(data) {
    return (dispatch) => {
        dispatch(updateDataFormEditConfirmedAction(data));
    };
}

export function updateDataFormEditConfirmedAction(data) {
    return {
        type: UPDATE_DATAFORMEDIT_ACTION,
        payload: data,
    };
}
