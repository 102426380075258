
import FormSelect2 from '../../Form/FormSelect2.js'
import { useLocale } from "../../../../context/Locale/index.js";

const Select2UserId = (props) => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    return (
        <FormSelect2 { ...props} textlabel={strings.form.addUserRole.labelUserId} id="user_id"   ></FormSelect2>
    )
}
export default Select2UserId;