import { useSelector, useDispatch } from "react-redux";
import { updateDataFormNewFormAction } from '../../../../store/actions/UserStoreActions.js'

import InputUserName from '../Input/InputUserName.js'
import InputUserEmail from '../Input/InputUserEmail.js'
import InputUserPassword from '../Input/InputUserPassword.js'

const FormNewUser = (props) => {

    const dispatch = useDispatch();

    const dataForm = useSelector((store) => store.user.dataNewForm);

    function onChange(id, value) {
        let dataFormNew = { ...dataForm };
        dataFormNew[id] = value;
        dispatch(updateDataFormNewFormAction(dataFormNew));
    }

    if (props === undefined) {
        return (<div className="row"></div>)
    } else {
        let secureValues = {
            "name": dataForm.name || "",
            "email": dataForm.email || "",
            "password": dataForm.password || ""
        }

        return (
            <div className="row">
                <InputUserName value={secureValues.name} onChange={onChange}  ></InputUserName>
                <InputUserEmail value={secureValues.email} onChange={onChange}  ></InputUserEmail>
                <InputUserPassword value={secureValues.password} onChange={onChange}  ></InputUserPassword>
            </div>
        )
    }


}
export default FormNewUser;