import React, { useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { storeAction } from '../../../../store/actions/ModelActions.js'


import ModalConfirm from '../../Common/Modal/ModalConfirm.js';

import { useLocale } from "../../../../context/Locale/index.js";

const FormNewFeeTemplate = (props) => {

    const dispatch = useDispatch();
    const model="feetemplate";

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    
    const [showComfirm, setShowConfirm] = useState(false);
    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});


    const initialValue = {
        "name": "",
        "initial_fee": 0,
        "amount": 3000,
        "amount_fees": 12,
        "payment_day": 5,
        "status": "active",
        "comment": ""
    }

    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }

    

    const updateComponents = () => {
        if (step === "init") {
            setValue({ ...initialValue });

        }

        if (step !== "edit") {
            setStep("edit");
        }
    }

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;
        setValue(newValue);

        updateComponents();

    }

    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {

        let request = {
            status: 'active',
            name: value.name,
            initial_fee: value.initial_fee,
            amount: value.amount,
            amount_fees: value.amount_fees,
            payment_day: value.payment_day,
            comment: value.fee_comment
        }

        dispatch(storeAction(model,request));
        setShowConfirm(false);
        if(props.onSave !== undefined){
            props.onSave();
        }
    }

    function onCancelForm(){
        if(props.onCancel !== undefined){
            props.onCancel();
        }
    }

    if (step !== "edit") {
        updateComponents();
    }

    if (props === undefined) {
        return (<div className="row"></div>)
    } else {

        return (
            <>
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Datos</h4>
                    </div>
                    <div className="card-body">
                        <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                <div className="col-xl-6 mb-3">
                                        <label htmlFor={"name"} className="form-label">Nombre<span className="text-danger">*</span></label>
                                        <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"name"} type="text" value={getValue("name", "")} className="form-control" />
                                    </div>
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor={"initial_fee"} className="form-label">Cuota Inicial<span className="text-danger">*</span></label>
                                        <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"initial_fee"} type="number" value={getValue("initial_fee", 0)} min={1} className="form-control" />
                                    </div>
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor={"amount"} className="form-label">Monto Mensual<span className="text-danger">*</span></label>
                                        <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"amount"} type="number" value={getValue("amount", 0)} min={0} className="form-control" />
                                    </div>
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor={"amount_fees"} className="form-label">Cantidad de Cuotas<span className="text-danger">*</span></label>
                                        <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"amount_fees"} type="number" value={getValue("amount_fees", 0)} min={0} className="form-control" />
                                    </div>
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor={"payment_day"} className="form-label">Día de Pago<span className="text-danger">*</span></label>
                                        <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"payment_day"} type="number" value={getValue("payment_day", 1)} min={1} max={31} className="form-control" />
                                    </div>
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor={"comment"} className="form-label">Comentario<span className="text-danger">*</span></label>
                                        <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"comment"} type="text" value={getValue("comment", "")} className="form-control" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div>
                    <button onClick={ () => onSaveFormConfirm()} className="btn btn-sm btn-primary me-1">{strings.form.common.buttonAccept}</button>
                    <Link to={"#"} onClick={() => onCancelForm()} className="btn btn-sm btn-danger light ms-1">{strings.form.common.buttonCancel}</Link>
                </div>
                <ModalConfirm
                    show={showComfirm}
                    title={"Confirmación"}
                    message={"¿Está seguro de agregar este Plan de Cuotas"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>
            </>

        )
    }


}
export default FormNewFeeTemplate;