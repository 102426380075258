import http from './httpService';
import {formatError, ProcessMessages} from './MessageService'

export async function user() { 
    return http.get('/api/user/get').then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return(err);
    });
}

export async function capabilities() { 
    return http.get('/api/user/capabilities').then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: []});
    });
}


export async function list() { 
    return http.get('/api/user/list').then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}

export async function store(data) { 

    let request = {
        'name': data.name,
        'email': data.email,
        'password' : data.password
    }
    

    return http.post('/api/user/store', request).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}

export async function update(data) { 
    let request = {
        'id': data.id,
        'name': data.name,
    }

    return http.post('/api/user/' + data.id + '/update',request).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}

export async function destroy(data) { 
    let request = {
        'id': data.id
    }
    return http.delete('/api/user/' + data.id + '/destroy',request).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}