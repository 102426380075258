import { useSelector, useDispatch } from "react-redux";
import { updateParamsFormReportAction, getAllConfirmedAction } from '../../../../store/actions/ReportListActions.js'

import { setDataAction } from '../../../../store/actions/ModelActions.js'

import Select2 from '../../Base/Select2.js'

const FormReportFeesbyInscription = (props) => {
    const dispatch = useDispatch();

    const params = useSelector((store) => store.report.params);
    const reportCode = useSelector((store) => store.report.reportCode);

    // Lista de inscripciones
    const inscriptions = useSelector((store) => store.inscription.list);
    const inscription = useSelector((store) => store.inscription.data);

    function onChange(id, value) {
        let paramsNew = { ...params };
        paramsNew[id] = value;
        let newInscription = inscriptions.find(i => String(i.id) === String(value));
        dispatch(setDataAction("inscription",newInscription));
        dispatch(updateParamsFormReportAction(paramsNew));
        dispatch(getAllConfirmedAction([]));
    }


    if (props === undefined || !(reportCode === "feesByInscription" || reportCode === "feesByInscriptionByDateRange")) {
        return (<div></div>)
    } else {

        const inscriptionsList = inscriptions.map(function (item) {
            return {
                "value": item.id,
                "label": "( ID: " + item.id + ") " + item.company_name + " " + (item.comment || ""), 
            };
        });

        const inscriptionValue = inscriptionsList.find(i=> i.value === inscription.id);

        return (
            <div className="mb-3">
                <label className="col-sm-3 col-form-label">Inscripción</label>
                <Select2 id="inscription_id" defaultValue={inscriptionValue} options={inscriptionsList} onChange={onChange}  ></Select2>
            </div>
        );
    }
}

export default FormReportFeesbyInscription;