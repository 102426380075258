import { Logout, isLogged} from './CommonActions';

import { paginate} from '../../services/FeeService';

export const PAGINATE_CONFIRMED_ACTION = '[fee paginate action] confirmed paginate';
export const PAGINATE_FAILED_ACTION = '[fee paginate action] failed paginate';


export function paginateAction(params) {
    return  (dispatch) => {
        
        paginate(params).then(response => {
            if (isLogged(response)) {
                dispatch(paginateConfirmedAction(response.data));	
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function paginateConfirmedAction(data) {
    return {
        type: PAGINATE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function paginateFailedAction(data) {
    return {
        type: PAGINATE_FAILED_ACTION,
        payload: data,
    };
}
