import  DatePicker  from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('es', es)

const DateInput = (props) => {

    function onChange(value) {
        if(props.onChange !== undefined){
            props.onChange(props.id,value);
        }
    }
    
    if(props === undefined){
        return(<div></div>)
    }else {
        return (<DatePicker { ...props}   locale="es" dateFormat="dd/MM/yyyy" onChange={(date) => {onChange(date)}}  className="form-control" />)
    }
    
    
}
export default DateInput;