import React, { useState,useEffect } from 'react';
import MainPagetitle from '../../layouts/MainPagetitle';
import NavMenu from '../Common/NavMenu/NavMenu.js';
import { useSelector, useDispatch } from "react-redux";
import { useLocale } from "../../../context/Locale";

const NotificationTemplate = () => {
    const dispatch = useDispatch();
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const pageName = "notificationstemplate"
    const model="notificationtemplate";

    const path = useSelector((store) => store.common.path);
    const capabilities = useSelector((store) => store.user.capabilities);
    const canView = capabilities.map(function (c) { return c.code }).includes('PAGE_NOTIFICATIONTEMPLATE_LIST');
    
    const [step, setStep] = useState("init");

    useEffect(() => {

        if (step === "init") {
            setStep("loaded");
        }

    }, [dispatch,step]);

    if (path !== pageName && path !== "") {
        return (<></>);
    } else {
        return (
            <>
                <MainPagetitle mainTitle={"Plantillas de Notificaciones"} pageTitle={"Plantillas de Notificaciones"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu/>
                    {canView && 
                    <div className="row">
                        <div className="col-xl-12">
                           
                        </div>
                    </div>
                    }
                </div>

             
            </>
        );
    }

}

export default NotificationTemplate; 