
import { Logout, isLogged } from './CommonActions';

import { filter } from '../../services/ProvinceService';

export const FILTER_CONFIRMED_ACTION = '[province filter action] confirmed filter';
export const FILTER_FAILED_ACTION = '[province filter action] failed filter';

export const RELOAD_ENABLE_ACTION = '[province reload action] enable reload';
export const RELOAD_DISABLE_ACTION = '[province reload action] disable reload';

export const SET_DATA_ACTION = '[province set data action] set data';



export function setDataAction(data) {
    return {
        type: SET_DATA_ACTION,
        payload: data,
    };
}

export function filterAction(params) {
    return (dispatch) => {

        filter(params).then(response => {
            if (isLogged(response)) {
                dispatch(filterConfirmedAction(response.data));
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function filterConfirmedAction(data) {
    return {
        type: FILTER_CONFIRMED_ACTION,
        payload: data,
    };
}

export function filterFailedAction(data) {
    return {
        type: FILTER_FAILED_ACTION,
        payload: data,
    };
}


export function enableReloadAction() {
    return {
        type: RELOAD_ENABLE_ACTION,
        payload: true,
    };
}

export function disableReloadAction() {
    return {
        type: RELOAD_DISABLE_ACTION,
        payload: false,
    };

}
