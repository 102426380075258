const Label = (props) => {
    if(props === undefined){
        return(<label className="form-label"><span className="text-danger">*</span></label>)
    }else{
        return (
            <label htmlFor={props.htmlFor} className="form-label">{props.text}<span className="text-danger">*</span></label>
        )
    }
    
}
export default Label;