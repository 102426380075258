import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import MainPagetitle from '../../../layouts/MainPagetitle';
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import { Button } from "react-bootstrap";

import { useLocale } from "../../../../context/Locale";
import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'

const PersonView = (props) => {

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const dispatch = useDispatch();
    const data = useSelector((store) => store.person.dataEditForm);
    const capabilities = useSelector((store) => store.user.capabilities);

    const editItem = capabilities.map(function (c) { return c.code }).includes("PERSON_EDIT");

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});

    const [toEdit, setToEdit] = useState(false);
    
    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }

    useEffect(() => {

        if (step === "init") {
            setValue({
                "first_name": data.first_name,
                "last_name": data.last_name,
                "legal_id": data.legal_id,
                "gender": data.gender,
                "email": data.email,
                "phone": data.phone
            });

        }

        if (step !== "edit") {
            setStep("edit");
        }

    }, [dispatch, step, data]);


    if (props === undefined || toEdit) {
        return (<>
            {toEdit && (<Navigate to={routers.person.edit} replace={true} />)}
        </>)
    } else {


        return (

            <>

                <MainPagetitle mainTitle={"Ver Persona"} pageTitle={"Ver Persona"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Datos</h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="row">
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"legal_id"} className="form-label">Rut<span className="text-danger">*</span></label>
                                                    <input  id={"legal_id"} type="text" value={getValue("legal_id", "")} disabled={true} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"first_name"} className="form-label">Nombres<span className="text-danger">*</span></label>
                                                    <input  id={"first_name"} type="text" value={getValue("first_name", "")} disabled={true} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"last_name"} className="form-label">Apellidos<span className="text-danger">*</span></label>
                                                    <input  id={"last_name"} type="text" value={getValue("last_name", "")} disabled={true} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"gender"} className="form-label">Género<span className="text-danger">*</span></label>
                                                    <input  id={"gender"} type="text" value={getValue("gender", "")} disabled={true} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"email"} className="form-label">Correo<span className="text-danger">*</span></label>
                                                    <input  id={"email"} type="text" value={getValue("email", "")}  disabled={true} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"phone"} className="form-label">Teléfono<span className="text-danger">*</span></label>
                                                    <input  id={"phone"} type="tel" value={getValue("phone", "")} disabled={true} className="form-control" />
                                                </div>
                                            </div>
                                            {editItem && 
                                            <div>
                                                <Button onClick={() => setToEdit(true)} className="btn-sm" variant="primary"> {"Editar"}</Button>
                                            </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
};

export default PersonView;