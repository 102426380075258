import enStrings from "../../locales/en.json";
import esStrings from "../../locales/es.json";

export const REGIONS = {
  ES: "ES",
  EN: "EN"
};

export const LOCALE_STRINGS = {
  [REGIONS.EN]: enStrings,
  [REGIONS.ES]: esStrings
};

export const COUNTRY_CONSTANTS = {
  [REGIONS.EN]: {
    phoneCode: "+1"
  },
  [REGIONS.ES]: {
    phoneCode: "+56"
  }
};
