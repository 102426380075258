import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import { useLocale } from "../../../../context/Locale/index.js";

import InputForm from '../Form/InputForm.js'


const ModalOffcanvas = forwardRef((props, ref) => {

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [showModal, setShowModal] = useState(false);

    useImperativeHandle(ref, () => ({
        showModal() {
            setShowModal(true)
        }
    }));

    const closeModal = () =>{
        setShowModal(false)
    }

    const closeModalSave = () =>{
        setShowModal(false)
        if(props.onSave!== undefined){
            props.onSave();
        }
    }

    const closeModalCancel = () =>{
        setShowModal(false)
        if(props.onCancel !== undefined){
            props.onCancel();
        }
    }
    const nav = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        nav("#");
    }

    return (
        <>
            <Offcanvas show={showModal} onHide={setShowModal} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
                    <button type="button" className="btn-close"
                        onClick={() => closeModal()}
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <form onClick={(e) => handleSubmit(e)}>
                            <InputForm Strings={props.Strings} onChange={props.onChange}  TypeForm={props.TypeForm} Data={props.Data}></InputForm>
                            <div>
                                <button onClick={ () => closeModalSave()} className="btn btn-primary me-1">{strings.form.common.buttonAccept}</button>
                                <Link to={"#"} onClick={() => closeModalCancel()} className="btn btn-danger light ms-1">{strings.form.common.buttonCancel}</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </Offcanvas>
        </>
    );
});

export default ModalOffcanvas;