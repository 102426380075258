import React, { useState, useRef, useEffect } from 'react';
import MainPagetitle from '../../layouts/MainPagetitle';
import ModalOffcanvas from '../Common/Modal/ModalOffcanvas';
import ModalConfirm from '../Common/Modal/ModalConfirm';
import NavMenu from '../Common/NavMenu/NavMenu.js';
import { Navigate } from "react-router-dom";

import { formatIsoDateToString, formatIsoDateToDate } from '../../../tools/fomatter'

import { useLocale } from "../../../context/Locale";

import {
    getAllAction,
    storeAction,
    destroyAction,
    updateAction,
    openEditFormAction

} from '../../../store/actions/ModelActions'


import { useSelector, useDispatch } from "react-redux";
import Grid from '../Common/Grid/Grid';

import routers from '../../../setting/routes.json'

const Payment = () => {
    const pageName = "payments"
    const model = "payment"
    const capabilities = useSelector((store) => store.user.capabilities);
    const canView = capabilities.map(function (c) { return c.code }).includes('PAYMENT_LIST');
    const path = useSelector((store) => store.common.path);

    const newItem = capabilities.map(function (c) { return c.code }).includes("PAYMENT_CREATE");
    const editItem = capabilities.map(function (c) { return c.code }).includes("PAYMENT_EDIT");
    const viewItem = capabilities.map(function (c) { return c.code }).includes("PAYMENT_GET");
    const destroyItem = capabilities.map(function (c) { return c.code }).includes("PAYMENT_DESTROY");
    const exportData = capabilities.map(function (c) { return c.code }).includes("PAYMENT_EXPORT");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [typeForm, setTypeForm] = useState("");
    const [titleForm, setTitleForm] = useState("");
    const [toDestroy, setToDestroy] = useState({});
    const [toEdit, setToEdit] = useState(false);
    const [toNew, setToNew] = useState(false);

    const [showComfirmNew, setShowConfirmNew] = useState(false);
    const [showComfirmEdit, setShowConfirmEdit] = useState(false);
    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);
    const [step, setStep] = useState("init");

    const dispatch = useDispatch();
    const list = useSelector((store) => store.payment.list);


    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction(model, {}));
            setStep("loaded");
        }

    }, [dispatch, step]);


    const gridConf = [
        { label: strings.table.payment.header.legal_id, key: "company_legal_id", className: "", filter: true },
        { label: "Empresa", key: "company_name", className: "", filter: true },
        { label: strings.table.payment.header.amount, key: "amount", className: "" },
        { label: strings.table.payment.header.payment_date, key: "payment_date", className: "", "formatter": formatIsoDateToString },
        { label: strings.table.payment.header.comment, key: "comment", className: "", filter: true },
    ]

    const dataNewForm = useSelector((store) => store.payment.dataNewForm);
    const dataEditForm = useSelector((store) => store.payment.dataEditForm);

    const paymentElement = useRef();
  
    function onClickNewForm() {
        setToNew(true);
    }

    function onSaveFormConfirm() {
        if (typeForm === "FormNewPayment") {
            setShowConfirmNew(true);
        }
        if (typeForm === "FormEditPayment") {
            setShowConfirmEdit(true);
        }
    }

    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
    }

    function onDestroyForm() {
        dispatch(destroyAction(model, toDestroy));
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onSaveForm() {
        if (typeForm === "FormNewPayment") {
            dispatch(storeAction(model, dataNewForm));
        }

        if (typeForm === "FormEditPayment") {
            dispatch(updateAction(model, dataEditForm));
        }
        setShowConfirmNew(false);
        setShowConfirmEdit(false);
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onCancelForm() {
        setShowConfirmNew(false);
        setShowConfirmEdit(false);
        setShowConfirmDestroy(false);
        setToDestroy({});
    }

    function onClickEditForm(item) {
        setTypeForm("FormEditPayment");
        setTitleForm(strings.form.editPayment.title);
        paymentElement.current.showModal();
        let itemNew = {
            ...item,
            payment_date: formatIsoDateToDate(item.payment_date),
            start_date: formatIsoDateToDate(item.start_date),
            end_date: formatIsoDateToDate(item.end_date),
            created_date: formatIsoDateToDate(item.created_date)
        }
        dispatch(openEditFormAction(model, itemNew));
    }

    function onClickViewForm(item) {
        setTitleForm(strings.form.editPayment.title);
        let itemNew = { ...item }
        dispatch(openEditFormAction(model, itemNew));
        setToEdit(true);

    }


    if (path !== pageName && path !== "") {
        return (<></>);
    } else {
        return (
            <>
                {toEdit && (<Navigate to={routers.payment.view} replace={true} />)}
                {toNew && (<Navigate to={routers.payment.new} replace={true} />)}
                <MainPagetitle mainTitle={strings.page.payment.title} pageTitle={"Listado de Pagos"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {canView &&
                        <div className="row">
                            <div className="col-xl-12">
                                <Grid
                                    list={list}
                                    title={strings.page.payment.title}
                                    conf={gridConf}
                                    onClickNewItem={onClickNewForm}
                                    onClickDestroy={onDestroyFornConfirm}
                                    onClickView={onClickViewForm}
                                    onClickEdit={onClickEditForm}

                                    newItem={newItem}
                                    viewItem={viewItem}
                                    editItem={editItem}
                                    destroyItem={destroyItem}
                                    exportData={exportData}

                                    buttonAddLabel={"Registar Pago"}
                                    placeholderFilter={"Buscar Empresa"}

                                ></Grid>
                            </div>
                        </div>
                    }
                </div>
                <ModalOffcanvas
                    ref={paymentElement}
                    Title={titleForm}
                    TypeForm={typeForm}
                    onSave={onSaveFormConfirm}
                    onCancel={onCancelForm}
                />

                <ModalConfirm
                    show={showComfirmEdit}
                    title={"Confirmación"}
                    message={"¿Está seguro de editar el pago"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>
                <ModalConfirm
                    show={showComfirmDestroy}
                    title={"Confirmación"}
                    message={"¿Está seguro de eliminar el pago"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                >
                </ModalConfirm>
            </>
        );
    }




};

export default Payment;