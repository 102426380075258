import React, { Fragment, useState, useEffect } from "react";
import InboxSideBar from '../Common/InboxSideBar'
import { useSelector, useDispatch } from "react-redux";
import ModalConfirm from '../../Common/Modal/ModalConfirm';
import MainPagetitle from '../../../layouts/MainPagetitle'
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import { sendAction } from '../../../../store/actions/NotificationStoreActions'
import { destroyAction } from '../../../../store/actions/NotificationDestroyActions.js'
import { useNavigate } from "react-router-dom";

import {
  enableReloadAction
} from '../../../../store/actions/NotificationActions'

import { useLocale } from "../../../../context/Locale";
import routers from '../../../../setting/routes.json'

const Read = (props) => {
  const navigate = useNavigate();
  
  const pageName = "notification-read"

  const { stateLocale } = useLocale();
  const { strings } = stateLocale;


  const capabilities = useSelector((store) => store.user.capabilities);
  const HasCapability = useSelector((store) => store.user.HasCapability);
  const notifications = useSelector((store) => store.notification.list);
  const notification = useSelector((store) => store.notification.data);

  const path = useSelector((store) => store.common.path);

  const canView = HasCapability(capabilities, 'NOTIFICATION_GET');
  const canSend = HasCapability(capabilities, 'NOTIFICATION_SEND');
  const canEdit = HasCapability(capabilities, 'NOTIFICATION_EDIT');
  const canDestroy = HasCapability(capabilities, 'NOTIFICATION_DESTROY');
  


  const [showComfirmNew, setShowConfirmNew] = useState(false);
  const [showComfirmDestroy, setShowComfirmDestroy] = useState(false);





  const dispatch = useDispatch();



  const [step, setStep] = useState("init");

  useEffect(() => {

    if (step === "init") {
      setStep("loaded");
    }

  }, [dispatch, step, notifications]);


  function onSendConfirm() {
    setShowConfirmNew(true);
  }

  function onDestroyConfirm() {
    setShowComfirmDestroy(true);
  }

  function onEdit() {
    navigate(routers.notification.edit);
  }

  function onSend() {
    dispatch(sendAction(notification.id));
    setShowConfirmNew(false);

    dispatch(enableReloadAction());
  }

  function onCancelSend() {
    setShowConfirmNew(false);
  }

  function onSend() {
    dispatch(destroyAction(notification));
    setShowComfirmDestroy(false);

    dispatch(enableReloadAction());
  }

  function onCancelSend() {
    setShowComfirmDestroy(false);
  }

  if (path !== pageName) {
    return (<>1</>);
  } else {
    return (
      <Fragment>
        <MainPagetitle mainTitle={"Notificaciones"} pageTitle={"Ver Notificación"} parentTitle={strings.page.home.title} />
        <div className="row gx-0">
          <NavMenu />
          <div className="col-xl-12">
            <div className="card h-auto">
              <div className="card-body">
                <div className="row gx-0">
                  <div className="col-xl-2 col-xxl-3">
                    <InboxSideBar></InboxSideBar>
                  </div>

                  <div className="col-lg-10 col-xxl-9">
                    <div className="email-right-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="right-box-padding border-start p-0">

                            {(canView) &&
                              <div className="read-wapper dz-scroll" id="read-content">
                                <div className="read-content">
                                  <hr />
                                  <div className="media mb-2 mt-3">
                                    <div className="media-body">
                                      <h5 className="my-1 text-primary">{notification.subject || ""}</h5>
                                      <p className="read-content-email"> Para: {notification.to || ""}</p>
                                    </div>
                                  </div>
                                  <div className="read-content-body">
                                    {notification.content || ""}
                                  </div>
                                  <hr />
                                </div>
                                <div className="text-end">
                                  {canDestroy && 
                                     <button onClick={() => onDestroyConfirm()} className="btn btn-sm btn-danger " type="button">
                                     Eliminar
                                   </button>
                                  }

                                {canEdit &&
                                    <button onClick={() => onEdit()} className="btn btn-sm btn-secondary " type="button">
                                      Editar
                                    </button>
                                  }

                                  {canSend &&
                                    <button onClick={() => onSendConfirm()} className="btn btn-sm btn-primary " type="button">
                                      Enviar
                                    </button>
                                  }

                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>


              </div>
            </div>
          </div>
        </div>
        <ModalConfirm
          show={showComfirmNew}
          title={"Confirmación"}
          message={"¿Está seguro de enviar la notificación?"}
          cancelButtonText={"No"} confirmButtonText={"Sí"}
          onCloseAction={onCancelSend} onCancelAction={onCancelSend} onConfirmAction={onSend}
        >
        </ModalConfirm>
        <ModalConfirm
          show={showComfirmDestroy}
          title={"Eliminación"}
          message={"¿Está seguro de eliminar la notificación?"}
          cancelButtonText={"No"} confirmButtonText={"Sí"}
          onCloseAction={onCancelSend} onCancelAction={onCancelSend} onConfirmAction={onSend}
        >
        </ModalConfirm>
      </Fragment>
    );
  }




};

export default Read;
