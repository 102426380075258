
import { Logout, isLogged, Unmutex } from './CommonActions';
import { enableReloadAction, disableReloadAction} from './UserActions';
import { list, capabilities } from '../../services/UserService';


export const SET_TIMER_ACTION = '[user set timer action] set timer';
export const SET_GETALLINPROGRESS_ACTION = '[user set getall in progress action] set getall in progress';

export const GETALL_CONFIRMED_ACTION = '[user getall action] confirmed getall';
export const GETALL_FAILED_ACTION = '[user getall action] failed getall';

export const GETCAPABILITY_CONFIRMED_ACTION = '[user getcapability action] confirmed getcapability';
export const GETCAPABILITY_FAILED_ACTION = '[user getcapability action] failed getcapability';


export const SET_DATA_ACTION = '[user set data action] set data';

export function setTimerAction(data) {
    return (dispatch) => {
        let beforeTime = data;
        let nowTime = (new Date()).getTime();
        let milliseconds = nowTime - beforeTime;

        if (milliseconds > 5 * 1000) {
            dispatch(setTimerConfirmedAction(nowTime));
            dispatch(enableReloadAction());
        } else {
            dispatch(setTimerConfirmedAction(data));

        }
    };
}

export function setTimerConfirmedAction(data) {
    return {
        type: SET_TIMER_ACTION,
        payload: data,
    };
}

export function setDataAction(data) {
    return {
        type: SET_DATA_ACTION,
        payload: data,
    };
}


export function setGetAllInProgressAction() {
    return (dispatch) => {
        dispatch(setGetAllInProgressConfirmedAction());
    };
}


export function setGetAllInProgressConfirmedAction() {
    return {
        type: SET_GETALLINPROGRESS_ACTION,
        payload: true,
    };
}

export function getAllAction() {
    return (dispatch) => {

        list().then(response => {
            if (isLogged(response)) {
                dispatch(getAllConfirmedAction(response.data));
                dispatch(disableReloadAction());
            } else {
                dispatch(Logout());
            }

            dispatch(Unmutex());
        });
    };
}

export function getAllConfirmedAction(data) {
    return {
        type: GETALL_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getAllFailedAction(data) {
    return {
        type: GETALL_FAILED_ACTION,
        payload: data,
    };
}


export function getCapabilitiesAction() {
    return (dispatch) => {

        capabilities().then(response => {
            if (isLogged(response)) {
                dispatch( getCapabilitiesConfirmedAction(response.data));
            } else {
                dispatch(Logout());
            }
            dispatch(Unmutex());
        });
    };
}

export function  getCapabilitiesConfirmedAction(data) {
    return {
        type: GETCAPABILITY_CONFIRMED_ACTION,
        payload: data,
    };
}

export function  getCapabilitiesFailedAction(data) {
    return {
        type: GETCAPABILITY_FAILED_ACTION,
        payload: data,
    };
}
