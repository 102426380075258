import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";

import MainPagetitle from '../../layouts/MainPagetitle';
import ModalConfirm from '../Common/Modal/ModalConfirm';
import NavMenu from '../Common/NavMenu/NavMenu.js';
import { useLocale } from "../../../context/Locale";

import {
    getAllAction,
    enableReloadAction,
    destroyAction,
    openEditFormAction
} from '../../../store/actions/ModelActions'

import { useSelector, useDispatch } from "react-redux";
import Grid from '../Common/Grid/Grid';

import routers from '../../../setting/routes.json'
const Person = () => {
    const pageName = "people"
    const model = "person";

    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const path = useSelector((store) => store.common.path);
    const list = useSelector((store) => store.person.list);
    
    const canView = HasCapability(capabilities,'PAGE_PERSON_LIST');
    const newItem = HasCapability(capabilities,"PERSON_CREATE");
    const viewItem = HasCapability(capabilities,"PERSON_GET");
    const editItem = HasCapability(capabilities,"PERSON_EDIT");
    const destroyItem = HasCapability(capabilities,"PERSON_DESTROY");
    const exportData = HasCapability(capabilities,"PERSON_EXPORT");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [toDestroy, setToDestroy] = useState({});

    const [toView, setToView] = useState(false);
    const [toEdit, setToEdit] = useState(false);
    const [toNew, setToNew] = useState(false);
    const [step, setStep] = useState("init");

    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction(model, {}));
            setStep("loaded");
        }

    }, [dispatch, step]);


    const gridConf = [
        { label: strings.table.person.header.first_name, key: "first_name", className: "", filter: true },
        { label: strings.table.person.header.last_name, key: "last_name", className: "", filter: true },
        { label: strings.table.person.header.legal_id, key: "legal_id", className: "", filter: true },
        { label: strings.table.person.header.gender, key: "gender", className: "", filter: true },
        { label: strings.table.person.header.email, key: "email", className: "text-primary", filter: true },
        { label: strings.table.person.header.phone, key: "phone", filter: true },

    ]


    function onClickEditForm(item) {
        let itemNew = { ...item }
        dispatch(openEditFormAction(model, itemNew));
        setToEdit(true);
    }


    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
    }

    function onDestroyForm() {
        dispatch(destroyAction(model, toDestroy));
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onCancelForm() {
        setShowConfirmDestroy(false);
    }


    function onClickNewForm() {
        setToNew(true);
    }


    function onClickViewForm(item) {
        let itemNew = { ...item }
        dispatch(enableReloadAction(model));
        dispatch(openEditFormAction(model, itemNew));
        setToView(true);

    }

    if (path !== pageName && path !== "") {
        return (<></>);
    } else if (toView || toEdit || toNew) {
        return (<>
            {toView && (<Navigate to={routers.person.view} replace={true} />)}
            {toEdit && (<Navigate to={routers.person.edit} replace={true} />)}
            {toNew && (<Navigate to={routers.person.new} replace={true} />)}
        </>);
    } else {
        return (
            <>
                <MainPagetitle mainTitle={strings.page.person.title} pageTitle={"Listado de Personas"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {canView &&
                        <div className="row">
                            <div className="col-xl-12">
                                <Grid
                                    list={list}
                                    title={strings.page.person.title}
                                    conf={gridConf}
                                    // filterRecords={filterPerson}
                                    onClickNewItem={onClickNewForm}
                                    onClickDestroy={onDestroyFornConfirm}
                                    onClickView={onClickViewForm}
                                    onClickEdit={onClickEditForm}

                                    newItem={newItem}
                                    editItem={editItem}
                                    viewItem={viewItem}
                                    destroyItem={destroyItem}
                                    exportData={exportData}

                                ></Grid>
                            </div>
                        </div>
                    }
                </div>
                <ModalConfirm
                    show={showComfirmDestroy}
                    title={"Confirmación"}
                    message={"¿Está seguro de eliminar esta persona"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                >
                </ModalConfirm>
            </>
        );
    }





};

export default Person;