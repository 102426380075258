import React from 'react';
import { Link } from 'react-router-dom';

import { useLocale } from "../../../../context/Locale";

/**
 * 
 * @param {*} props 
 * currentPage
 * list
 * prePage
 * changeCPage(id)
 * nextPage
 * @returns 
 */
export const GridPaginator = (props) => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const currentPage = props ? props.currentPage ? props.currentPage : 1 : 1;
    const length = props ? Array.isArray(props.list) ? props.list.length : 0 : 0;
    const recordsPage = 10;
    const lastIndex = currentPage * recordsPage;

    const npage = Math.ceil(length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)

    function prePage() {
        if (props.prePage !== undefined) {
            props.prePage();
        }
    }
    function changeCPage(id) {
        if (props.changeCPage !== undefined) {
            props.changeCPage(id);
        }
    }

    function nextPage() {
        if (props.nextPage !== undefined) {
            props.nextPage();
        }
    }

    return (
        <>{(length > 0) &&
            <div className="d-sm-flex text-center justify-content-between align-items-center">

                <div className='dataTables_info'>
                    {strings.table.pagination.showing} {lastIndex - recordsPage + 1} {strings.table.pagination.to} {" "}
                    {length < lastIndex ? length : lastIndex}
                    {" "}{strings.table.pagination.of} {length} {strings.table.pagination.entries}
                </div>

                <div
                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                    id="example2_paginate"
                >
                    <Link className="paginate_button previous disabled" to="#" onClick={prePage} >
                        <i className="fa-solid fa-angle-left" />
                    </Link>
                    <span>
                        {number.map((n, i) => (
                            <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                onClick={() => changeCPage(n)} >
                                {n}
                            </Link>
                        ))}
                    </span>
                    <Link className="paginate_button next" to="#" onClick={nextPage} >
                        <i className="fa-solid fa-angle-right" />
                    </Link>
                </div>
            </div>
        }
        </>

    );
}

export default GridPaginator;