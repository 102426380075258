
import { Button, Modal} from "react-bootstrap";


const ModalConfirm = (props) => {
    

    const show = props ? ( props.show ? props.show: false) : false;
    const title = props ? ( props.title ? props.title: "") : "";

    function onCloseAction(){
        if(props.onCloseAction !== undefined){
            props.onCloseAction();
        }
    }
    function onConfirmAction() {
        if(props.onConfirmAction !== undefined){
            props.onConfirmAction();
        }
    }

    function onCancelAction(){
        if(props.onCancelAction !== undefined){
            props.onCancelAction();
        }
    }

    if( props === undefined  || props.show === undefined || props.show === false){
        <div></div>
    }else {

        return (
            <Modal className="fade" show={show} onHide={()=>onCloseAction()} centered>
			<Modal.Header>
				<Modal.Title>{title}</Modal.Title>
				<Button onClick={() => onCloseAction()} variant="" className="btn-close"></Button>
			</Modal.Header>
			<Modal.Body><p>{props.message}</p></Modal.Body>
			<Modal.Footer>
            <Button onClick={() => onConfirmAction()} variant="primary">{props.confirmButtonText || "confirm"}</Button>
			<Button	onClick={() => onCancelAction()} variant="danger light"> {props.cancelButtonText || "cancel"}</Button>
			</Modal.Footer>
		</Modal>
        )
    }
}
export default ModalConfirm;