import React from 'react';
import { useSelector } from "react-redux";
import NavMenu from '../Common/NavMenu/NavMenu.js';
import MainPagetitle from '../../layouts/MainPagetitle';
import { useLocale } from "../../../context/Locale";

import FormViewPayment from './Form/FormViewPayment.js'

const PaymentView = (props) => {

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const capabilities = useSelector((store) => store.user.capabilities);
    const canView = capabilities.map(function (c) { return c.code }).includes('PAYMENT_GET');

    if (props === undefined) {
        return (<></>)
    } else {
        return (
            <>
                <MainPagetitle mainTitle={"Pagos"} pageTitle={"Ver Pago"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {canView &&
                        <div className="row">
                            <div className="col-xl-12">
                                <FormViewPayment ></FormViewPayment>
                            </div>
                        </div>
                    }

                </div>
            </>

        );
    }
};

export default PaymentView;