
import { useSelector, useDispatch } from "react-redux";
import {
    updateCodeFormReportAction, getAllConfirmedAction,
    updateParamsFormReportAction
} from '../../../../store/actions/ReportListActions.js'

import { setDataAction } from '../../../../store/actions/ModelActions.js'

const FormReportSelector = (props) => {

    const dispatch = useDispatch();
    const reportCode = useSelector((store) => store.report.reportCode);

    const inscriptions = useSelector((store) => store.inscription.list);
    const inscription = useSelector((store) => store.inscription.data);

    const params = useSelector((store) => store.report.params);


    function onClickRefreshData() {
        if (props.onRefreshData) {
            props.onRefreshData();
        }
    }

    function onChange(value) {
        dispatch(updateCodeFormReportAction(value));
        dispatch(getAllConfirmedAction([]));

        if (Array.isArray(inscriptions) && inscriptions.length > 0) {
            if (inscription === undefined || inscription.id === undefined) {
                dispatch(setDataAction("inscription", inscriptions[0]));
                let paramsNew = { ...params };
                paramsNew["inscription_id"] = inscriptions[0].id;
                dispatch(updateParamsFormReportAction(paramsNew));
            } else {
                dispatch(setDataAction("inscription", inscription));
                let paramsNew = { ...params };
                paramsNew["inscription_id"] = inscription.id;
                dispatch(updateParamsFormReportAction(paramsNew));
            }
        }
    }

    if (props === undefined) {
        return (<div className="row"></div>)
    } else {
        return (
            <div className="basic-form">
                <form>
                    <div className='row'>
                        <div className="input-group">
                            <select defaultValue={reportCode}
                                className="form-control"
                                onChange={(e) => { onChange(e.target.value); e.preventDefault() }}>

                                <option value="fees">Todas las Cuotas</option>
                                <option value="feesByInscription">Cuotas Por Inscripción</option>
                                <option value="feesByInscriptionByDateRange">Cuotas de una Inscripción en un Rango de Fechas</option>
                                <option value="feesByDateRange">Cuotas por Rango de Fechas</option>
                                <option value="pendingFeesByDateRange">Cuotas Pendientes por Rango de Fechas</option>
                                <option value="defaulterFees">Cuotas con Morosidad mayor a 8 meses</option>
                                <option value="defaulterFeesVersusAmountBalance">Inscripciones Morosas y Equivalente en Dinero</option>
                                <option value="defaulterFeesVersusPaid">Cuotas Pagadas Versus Cuotas Morosas</option>
                            </select>

                            <button onClick={() => onClickRefreshData()} className="btn btn-sm btn-primary" type="button">Refrezcar datos</button>

                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default FormReportSelector;