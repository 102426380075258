
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useLocale } from "../../../../context/Locale";

import * as XLSX from 'xlsx'

import { GridFilter } from './GridFilter';
import { GridFilterSelect } from './GridFilterSelect';
import { GridPaginator } from './GridPaginator';
import { GridBody } from './GridBody';


import { GridHeader } from './GridHeader';



/**
 * 
 * @param {*} props 
 * title : Título de la grilla
 * list: Arreglo de registros
 * conf: Configuración de la grilla
 * filterRecords: función de filtrado
 * onClickNewForm: función de click en botón nuevo
 * onClickDestroy: función de clicl en botón eliminar de la grilla
 * onClickEdit: función de edición de la grilla
 * @returns 
 */
const Grid = (props) => {

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const [currentPage, setCurrentPage] = useState(1);
    const [orderBy, setOrderBy] = useState("id");
    const [orderByDirection, setOrderByDirection] = useState("asc");

    let list = props ? (props.list ? (Array.isArray(props.list) ? props.list : []) : []) : [];
    const title = props ? (props.title ? props.title : "") : "";
    const conf = props ? (props.conf ? props.conf : []) : [];
    const isEmpty = (list.length === 0);

    const [filters, setFilters] = useState({});

    const newItem = props ? (props.newItem ? props.newItem : false) : false;
    const editItem = props ? (props.editItem ? props.editItem : false) : false
    const viewItem = props ? (props.viewItem ? props.viewItem : false) : false
    const selectItem = props ? props.selectItem ? props.selectItem : false : false;
    const selectItemLabel = props ? props.selectItemLabel ? props.selectItemLabel : "Seleccionar" : "Seleccionar";

    const destroyItem = props ? (props.destroyItem ? props.destroyItem : false) : false
    const showFilter = props ? (props.filterRecords ? props.filterRecords : false) : false
    const exportData = props ? (props.exportData ? props.exportData : false) : false
    const hidePaginator = props ? (props.hidePaginator ? props.hidePaginator : false) : false

    const recordsPage = 10;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;

    if (orderBy === "id" && conf.length > 0) {
        setOrderBy(conf[0].key);
    }

    if (list.length > 0) {
        if (Number.isNaN(Number(list[0][orderBy]))) {
            if (orderByDirection === "asc") {
                list = list.sort(function (a, b) {
                    if (a[orderBy] > b[orderBy]) {
                        return 1;
                    } else if (a[orderBy] < b[orderBy]) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            } else {
                list = list.sort(function (a, b) {
                    if (a[orderBy] > b[orderBy]) {
                        return -1;
                    } else if (a[orderBy] < b[orderBy]) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
        } else {
            if (orderByDirection === "asc") {
                list = list.sort(function (a, b) {
                    if (Number(a[orderBy]) > Number(b[orderBy])) {
                        return 1;
                    } else if (Number(a[orderBy]) < Number(b[orderBy])) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            } else {
                list = list.sort(function (a, b) {
                    if (Number(a[orderBy]) > Number(b[orderBy])) {
                        return -1;
                    } else if (Number(a[orderBy]) < Number(b[orderBy])) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
        }



    }


    function onClickHeader(colName) {
        if (colName === orderBy) {
            setOrderByDirection((orderByDirection === "asc") ? "desc" : "asc");
        } else {
            setOrderByDirection("asc");
            setOrderBy(colName);
        }
    }

    function setFilter(id, value) {
        setCurrentPage(1);
        let newFilters = { ...filters }
        newFilters[id] = value;
        setFilters(newFilters);
    }

    const filterList = filterRecords();
    const records = filterList.slice(firstIndex, lastIndex);


    const npage = Math.ceil(filterList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)




    const csvlink = {
        headers: conf,
        data: filterList.map(function (item) {
            let itemNew = { ...item }
            conf.forEach(function (item2) {
                if (item2.formatter && item[item2.key] !== undefined) {
                    itemNew[item2.key] = item2.formatter(item[item2.key])
                }
            });
            return itemNew;
        }),
        filename: "datos.csv"
    }


    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }

    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }

    function filterRecords() {
        if (props.filterRecords !== undefined) {
            return props.filterRecords(filters["default"], list);
        } else {
            return list.filter(function (item) {
                let founds = [];
                let count = 0;

                let isEmpty = true;
                for (const [key, value] of Object.entries(filters)) {
                    if (value !== "") {
                        count++;
                        isEmpty = false;
                        let property = key;
                        let rowValue = String(item[property]);

                        if (typeof rowValue === "string") {
                            // if(rowValue.toUpperCase().includes(String(value).toUpperCase()) ? true : false){
                            //     founds.push(true);
                            // }
                            if (rowValue.toUpperCase().includes(String(value).toUpperCase())) {
                                founds.push(true);
                            }
                        }


                    }
                }

                return (isEmpty || founds.length >= count);
            });
        }
    }

    function onClickNewItem(data) {
        if (newItem) {
            props.onClickNewItem(data);
        }
    }

    function onClickDestroy(item) {
        if (destroyItem) {
            props.onClickDestroy(item);
        }
    }

    function onClickEdit(item) {
        if (editItem) {
            props.onClickEdit(item);
        }
    }

    function onClickView(item) {
        if (viewItem) {
            props.onClickView(item);
        }
    }

    function onClickSelect(item) {
        if (props.onClickSelect !== undefined) {
            props.onClickSelect(item);
        }
    }


    function onExportXLSX() {
        const rows = filterList.map(function (item) {
            let itemNew = { ...item }
            conf.forEach(function (item2) {
                if (item2.formatter && item[item2.key] !== undefined) {
                    itemNew[item2.key] = item2.formatter(item[item2.key])
                }
            });
            let newRow = {}
            conf.forEach(function (c) {
                newRow[c.label] = itemNew[c.key];
            });
            return newRow;
        });
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");
        XLSX.writeFile(workbook, "datos.xlsx", { compression: true });
    }

    if (props === undefined || !Array.isArray(props.list)) {
        return (<></>)
    } else {
        return (
            <div className="card">
                {(exportData || newItem) &&
                    <div className="card-header">
                        <h4 className="heading mb-0">{title}</h4>
                        <div>
                            {/* {(!isEmpty && exportData) &&
                                <CSVLink {...csvlink} className="btn btn-primary light btn-sm">
                                    <i className="fa-solid fa-file-excel" /> {" "} Exportar CSV
                                </CSVLink>
                            } */}
                            {(!isEmpty && exportData) &&
                                <Link onClick={() => onExportXLSX()} className="btn btn-primary light btn-sm">
                                    <i className="fa-solid fa-file-excel" /> {" "} Exportar XLSX
                                </Link>
                            }
                            {newItem && <Link to={"#"} className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas" onClick={() => onClickNewItem()}>{props.buttonAddLabel ? props.buttonAddLabel : strings.form.common.buttonAdd}</Link>}
                        </div>
                    </div>
                }
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                        <div className="tbl-caption d-flex justify-content-start text-wrap align-items-center">
                            {showFilter && (<GridFilter id="default" placeholder={props.placeholderFilter} filter={filters["default"]} setFilter={setFilter} />)}
                            {/* {conf.filter(c => c.filter && ( c.type === undefined) ).map((item, index) => (
                                              <GridFilter  key={index} id={item["key"]} filter={filters[item["key"]]} setFilter={setFilter} placeholder={item.label}/>
                                        ))} */}
                            {conf.filter(c => c.filter).map((item, index) => (
                                <GridFilterSelect list={list} key={index} id={item["key"]} filter={filters[item["key"]]} setFilter={setFilter} options={item["options"]} placeholder={item.label} />
                            ))}

                        </div>
                        <div id="user-tbl_wrapper" className="dataTables_wrapper no-footer">
                            {!hidePaginator &&
                                <GridPaginator
                                    list={filterList}
                                    conf={conf}
                                    currentPage={currentPage}
                                    prePage={prePage}
                                    changeCPage={changeCPage}
                                    nextPage={nextPage}

                                ></GridPaginator>
                            }
                            <table id="people-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                <GridHeader orderBy={orderBy} orderByDirection={orderByDirection} onClickHeader={onClickHeader} headers={conf}></GridHeader>
                                <GridBody
                                    conf={conf}
                                    records={records}
                                    onClickDestroy={onClickDestroy}
                                    onClickEdit={onClickEdit}
                                    onClickView={onClickView}
                                    onClickSelect={onClickSelect}

                                    destroyItem={destroyItem}
                                    editItem={editItem}
                                    viewItem={viewItem}
                                    selectItem={selectItem}
                                    selectItemLabel={selectItemLabel}

                                ></GridBody>

                            </table>
                            {!hidePaginator &&
                                <GridPaginator
                                    list={filterList}
                                    conf={conf}
                                    currentPage={currentPage}
                                    prePage={prePage}
                                    changeCPage={changeCPage}
                                    nextPage={nextPage}

                                ></GridPaginator>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Grid;