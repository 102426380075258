import React from 'react';

export const GridFilter = ( props ) =>{

	function onChange(id,value){
		if(props.setFilter !== undefined){
			props.setFilter(id,value)
		}
	}

	return(
		<input id={props.id} className="input-search  ms-1 form-control" placeholder={props.placeholder ? props.placeholder: 'Buscar' }
				value={props.filter || ''}  onChange={e => onChange(props.id,e.target.value)} style={{width: "200px"}}
            />
	)
} 

export default GridFilter;