import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import FormCompanyView from './Form/FormCompanyView'
import FormCompanyEdit from './Form/FormCompanyEdit'

const Company = (props) => {

    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);

    const [modeView, setModeView] = useState(true);
    const [modeEdit, setModeEdit] = useState(false);
    const [modeNew, setModeNew] = useState(false);

    const swapMode = (newMode) => {
        setModeView(newMode === "view");
        setModeEdit(newMode === "edit");
        setModeNew(newMode === "new");
    }

    const [data, setData] = useState(props ? (props.data ? props.data : {}) : {});

    const canView = HasCapability(capabilities, "COMPANY_GET");
    const canEdit = HasCapability(capabilities, "COMPANY_EDIT");
    const canCreate = HasCapability(capabilities, "COMPANY_CREATE");


    const onSave = (item) => {
        swapMode("view");
    }

    const onCancel = () => {
        swapMode("view");
    }

    if (canView && modeView) {
        return (<>

            {data.id &&
                <div className="row">
                    <div className="col-xl-12"> <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Empresa</h4>
                        </div>

                        <div className="card-body">
                            <div className="basic-form">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <FormCompanyView data={{ id: data.id }} />
                                </form>
                            </div>
                            {canEdit &&
                                <div className="col-xl-3 mb-3">
                                    <Button onClick={() => swapMode("edit")} className="btn-sm" variant="primary"> {"Editar"}</Button>
                                </div>
                            }
                        </div>

                    </div>
                    </div>
                </div>}

        </>);
    } if (canEdit && modeEdit) {
        return (<>

            {data.id &&
                <div className="row">
                    <div className="col-xl-12"> <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Empresa</h4>
                        </div>
                        <div className="card-body">
                            <div className="basic-form">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <FormCompanyEdit data={{ id: data.id }} onSave={onSave} onCancel={onCancel} />
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            }

        </>);
    } else {
        return (<></>);
    }

}
export default Company;