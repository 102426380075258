import {
    PAGINATE_CONFIRMED_ACTION,
    PAGINATE_FAILED_ACTION

} from '../actions/PaginateFeeActions';

const initialState = {
    "current_page": 0,
    "data": [],
    "first_page_url": "",
    "from": 1,
    "last_page": 41,
    "last_page_url": "",
    "links": [],
    "next_page_url": "",
    "path": "",
    "per_page": 15,
    "prev_page_url": null,
    "to": 15,
    "total": 0
};

export function PaginateFeeReducer(state = initialState, action) {
    
    if (action.type === PAGINATE_CONFIRMED_ACTION) {
        return {
            ...action.payload
        };
    }
    if (action.type === PAGINATE_FAILED_ACTION) {
        return {
            ...state
        };
    }

    return state;
}