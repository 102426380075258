import React, { useState, useRef, useEffect } from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import ModalOffcanvas from '../Common/Modal/ModalOffcanvas';
import ModalConfirm from '../Common/Modal/ModalConfirm';
import NavMenu from '../Common/NavMenu/NavMenu.js';

import { useLocale } from "../../../context/Locale";

import {
    storeAction,
    openNewFormAction,
} from '../../../store/actions/UserStoreActions'

import {
    destroyAction,
} from '../../../store/actions/UserDestroyActions'

import {
    updateAction,
    openEditFormAction
} from '../../../store/actions/UserEditActions'

import {
    getAllAction
} from '../../../store/actions/UserListActions.js'


import { useSelector, useDispatch } from "react-redux";
import Grid from '../Common/Grid/Grid';

const User = () => {
    const pageName = "users"
    const model = "user";
    const capabilities = useSelector((store) => store.user.capabilities);
    const canViewAdmin = capabilities.map(function (c) { return c.code }).includes('MENU_ADMIN');
    const canView = capabilities.map(function (c) { return c.code }).includes('USER_LIST');
    const path = useSelector((store) => store.common.path);

    const newItem = capabilities.map(function (c) { return c.code }).includes("USER_CREATE");
    const editItem = capabilities.map(function (c) { return c.code }).includes("USER_EDIT");
    const destroyItem = capabilities.map(function (c) { return c.code }).includes("USER_DESTROY");
    const exportData = capabilities.map(function (c) { return c.code }).includes("USER_EXPORT");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [typeForm, setTypeForm] = useState("");
    const [titleForm, setTitleForm] = useState("");
    const [showComfirmNew, setShowConfirmNew] = useState(false);
    const [showComfirmEdit, setShowConfirmEdit] = useState(false);
    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);

    const dispatch = useDispatch();
    const list = useSelector((store) => store.user.list);
    const [toDestroy, setToDestroy] = useState({});
    const [step, setStep] = useState("init");

    const gridConf = [
        { label: "Nombre", key: "name", className: "", filter: true },
        { label: "Email", key: "email", className: "text-primary", filter: true }
    ]


    const dataNewForm = useSelector((store) => store.user.dataNewForm);
    const dataEditForm = useSelector((store) => store.user.dataEditForm);
    const userElement = useRef();

    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction({}));
            setStep("loaded");
        }

    }, [dispatch, step]);


    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
    }

    function onDestroyForm() {
        dispatch(destroyAction(toDestroy));
        setShowConfirmDestroy(false);
        dispatch(getAllAction({}));
        setTimeout(()=>{dispatch(getAllAction({}));}, 500);
    }

    function onSaveFormConfirm() {
        if (typeForm === "FormNewUser") {
            setShowConfirmNew(true);
        }
        if (typeForm === "FormEditUser") {
            setShowConfirmEdit(true);
        }
    }

    function onSaveForm() {
        if (typeForm === "FormNewUser") {
            dispatch(storeAction(dataNewForm));
        }

        if (typeForm === "FormEditUser") {
            dispatch(updateAction(dataEditForm));
        }
        setShowConfirmNew(false);
        setShowConfirmEdit(false);
        setTimeout(()=>{dispatch(getAllAction({}));}, 500);
    }

    function onCancelForm() {
        setShowConfirmNew(false);
        setShowConfirmEdit(false);
        setShowConfirmDestroy(false);
    }


    function onClickNewForm() {
        setTypeForm("FormNewUser");
        setTitleForm(strings.form.addUser.title);

        dispatch(openNewFormAction(
            {
                "name": dataNewForm.name || "",
                "email": dataNewForm.email || "",
                "password": dataNewForm.password || ""
            }
        ));

        userElement.current.showModal();
    }


    function onClickEditForm(item) {
        setTypeForm("FormEditUser");
        setTitleForm(strings.form.editUser.title);
        userElement.current.showModal();
        let itemNew = { ...item }
        dispatch(openEditFormAction(itemNew));
    }

    if (path !== pageName && path !== "") {
        return (<></>);
    } else {
        return (
            <>
                <MainPagetitle mainTitle={strings.page.user.title} pageTitle={strings.page.user.title} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {(canViewAdmin && canView) &&
                        <div className="row">
                            <div className="col-xl-12">
                                <Grid
                                    list={list}
                                    title={strings.page.user.title}
                                    conf={gridConf}
                                    onClickNewItem={onClickNewForm}
                                    onClickDestroy={onDestroyFornConfirm}
                                    onClickEdit={onClickEditForm}

                                    newItem={newItem}
                                    editItem={editItem}
                                    destroyItem={destroyItem}
                                    exportData={exportData}

                                ></Grid>
                            </div>
                        </div>
                    }
                </div>
                <ModalOffcanvas
                    ref={userElement}
                    Title={titleForm}
                    TypeForm={typeForm}
                    onSave={onSaveFormConfirm}
                    onCancel={onCancelForm}
                />

                <ModalConfirm
                    show={showComfirmNew}
                    title={"Confirmación"}
                    message={"¿Está seguro de agregar el usuario"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>

                <ModalConfirm
                    show={showComfirmEdit}
                    title={"Confirmación"}
                    message={"¿Está seguro de editar el usuario"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>
                <ModalConfirm
                    show={showComfirmDestroy}
                    title={"Confirmación"}
                    message={"¿Está seguro de eliminar el pago"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                >
                </ModalConfirm>
            </>
        );

    }

};

export default User;