
import { 
    FILTER_CONFIRMED_ACTION,
    FILTER_FAILED_ACTION,
    RELOAD_ENABLE_ACTION, RELOAD_DISABLE_ACTION ,
    DESTROY_CONFIRMED_ACTION, DESTROY_FAILED_ACTION,
    OPEN_FORMEDIT_ACTION,
    UPDATE_DATAFORMEDIT_ACTION,
    UPDATE_CONFIRMED_ACTION,
    UPDATE_FAILED_ACTION,
    RECOVER_CONFIRMED_ACTION,
    RECOVER_FAILED_ACTION,
    OPEN_FORMNEW_ACTION,
    UPDATE_DATAFORMNEW_ACTION,
    STORE_CONFIRMED_ACTION,
    STORE_FAILED_ACTION,
    SET_TIMER_ACTION,
    GETALL_CONFIRMED_ACTION,
    GETALL_FAILED_ACTION,
    GETDELETED_CONFIRMED_ACTION,
    GETDELETED_FAILED_ACTION,
    SET_DATA_ACTION,
    SetModelAction

 } from '../actions/ModelActions';

const initialState = {
    updated: (new Date()).getTime(),
    dispathGetAll: true,
    list: [],
    deleted: [],
    errorMessage: '',
    dataNewForm: {},
    dataEditForm: {},
    data: {},
    reload: true,
    model: "role"
};

export function RoleReducer(state = initialState, action) {


    if (action.type === SetModelAction(state.model,GETALL_FAILED_ACTION)) {
        return {
            ...state,
            errorMessage: action.payload,
            list: [],
            reload: false,
        };
    }

    if (action.type === SetModelAction(state.model,FILTER_FAILED_ACTION)) {
        return {
            ...state,
            errorMessage: action.payload,
            list: [],
            reload: false,
        };
    }


    if (action.type === SetModelAction(state.model,GETDELETED_FAILED_ACTION)) {
        return {
            ...state,
            errorMessage: action.payload,
            deleted: [],
            reload: false,
        };
    }
    
    if (action.type === SetModelAction(state.model,UPDATE_FAILED_ACTION)) {
        return {
            ...state,
            dataEditForm: {},
            reload: true,
        };
    }

    if (action.type === SetModelAction(state.model,RECOVER_FAILED_ACTION)) {
        return {
            ...state,
            dataEditForm: {},
            reload: true,
        };
    }

    if (action.type === SetModelAction(state.model,STORE_FAILED_ACTION)) {
        return {
            ...state,
            dataNewForm: {},
            reload: true,
        };
    }

    if (action.type === SetModelAction(state.model,DESTROY_FAILED_ACTION)) {
        return {
            ...state,
            dataNewForm: {},
            reload: true,
        };
    }

    if (action.type === SetModelAction(state.model,GETALL_CONFIRMED_ACTION)) {
        return {
            ...state,
            list: action.payload,
            reload: false,
        };
    }

    if (action.type === SetModelAction(state.model,FILTER_CONFIRMED_ACTION)) {
        return {
            ...state,
            list: action.payload,
            reload: false,
        };
    }


    if (action.type === SetModelAction(state.model,GETDELETED_CONFIRMED_ACTION)) {
        return {
            ...state,
            deleted: action.payload,
            reload: false,
        };
    }

    if (action.type === SetModelAction(state.model,STORE_CONFIRMED_ACTION)) {
        return {
            ...state,
            dataNewForm: action.payload,
            reload: true,
        };
    }

    if (action.type === SetModelAction(state.model,UPDATE_CONFIRMED_ACTION)) {
        return {
            ...state,
            dataEditForm: action.payload,
            reload: true,
        };
    }
    if (action.type === SetModelAction(state.model,RECOVER_CONFIRMED_ACTION)) {
        return {
            ...state,
            dataEditForm: action.payload,
            reload: true,
        };
    }

    if (action.type === SetModelAction(state.model,DESTROY_CONFIRMED_ACTION)) {
        return {
            ...state,
            dataNewForm: {},
            reload: true,
        };
    }

    if (action.type === SetModelAction(state.model,UPDATE_DATAFORMEDIT_ACTION)) {
        return {
            ...state,
            errorMessage: '',
            dataEditForm: action.payload,
        };
    }

    if (action.type === SetModelAction(state.model,OPEN_FORMEDIT_ACTION)) {
        return {
            ...state,
            errorMessage: '',
            dataEditForm: action.payload,
        };
    }

    if (action.type === SetModelAction(state.model,OPEN_FORMNEW_ACTION)) {
        return {
            ...state,
            errorMessage: '',
            dataNewForm: action.payload,
        };
    }

    if (action.type === SetModelAction(state.model,UPDATE_DATAFORMNEW_ACTION)) {
        return {
            ...state,
            errorMessage: '',
            dataNewForm: action.payload,
        };
    }

    if (action.type === SetModelAction(state.model,RELOAD_ENABLE_ACTION)) {
        return {
            ...state,
            reload: true
        };
    }

    if (action.type === SetModelAction(state.model,RELOAD_DISABLE_ACTION)) {
        return {
            ...state,
            reload: false
        };
    }

    if (action.type === SetModelAction(state.model,SET_TIMER_ACTION)) {
        return {
            ...state,
            updated: action.payload.time
        };
    }

    if (action.type === SetModelAction(state.model,SET_DATA_ACTION)) {
        return {
            ...state,
            data: action.payload
        };
    }

    return state;
}