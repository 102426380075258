
import { useSelector, useDispatch } from "react-redux";
import { updateDataFormEditAction } from '../../../../store/actions/UserEditActions.js'

import InputUserName from '../Input/InputUserName.js'
import InputUserEmail from '../Input/InputUserEmail.js'

const FormEditUser = (props) => {

    const dispatch = useDispatch();

    //Datos para editar una ubscripción
    const dataForm = useSelector((store) => store.user.dataEditForm);

    // Manejador de cambios en los datos de la subscripción
    function onChange(id, value) {
        let dataFormNew = { ...dataForm };
        dataFormNew[id] = value;
        dispatch(updateDataFormEditAction(dataFormNew));
    }

    if (props === undefined) {
        return (<div className="row"></div>)
    } else {
        let secureValues = {
            "name": dataForm.name || "",
            "email": dataForm.email || "",
            "password": dataForm.password || ""
        }
        return (
            <div className="row">
                <InputUserName value={secureValues.name} onChange={onChange}  ></InputUserName>
                <InputUserEmail disabled={true}  value={secureValues.email}  ></InputUserEmail>
            </div>
        )
    }
}
export default FormEditUser;