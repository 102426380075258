import React from 'react';

import Select2 from '../../Base/Select2'

export const GridFilterSelect = (props) => {

    function onChange(id, value) {
        if (props.setFilter !== undefined) {
            props.setFilter(id, value)
        }
    }

    const list = props ? (props.list ? props.list : []) : [];
    const options = props ? (props.options ? props.options : []) : [];

    const id = props ? props.id || "" : "";


    if(options.length === 0){
        list.forEach(function (element) {
            if (element[id] !== null) {
                let found = options.find(o => o.value === element[id]);
                if (found === undefined) {
                    options.push({
                        "value": element[id],
                        "label": element[id]
                    });
                }
            }
    
        });
    }

    

    const optionsList = [{
        "value": "",
        "label": props.placeholder || "Todos"
    }].concat(options);
    
    if(options.length > 0){
        return (
            <Select2 {...props} id={props.id} className="col-xl-2 mb-2" options={optionsList} onChange={onChange}  ></Select2>
        )
    }else {
        return (<></>);
    }
    
}

export default GridFilterSelect;