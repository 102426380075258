

import { Logout, isLogged } from '../actions/CommonActions';
import { enableReloadAction} from './NotificationActions';

import { store, send, sendAll } from '../../services/NotificationService';

export const STORE_CONFIRMED_ACTION = '[notification store action] confirmed store';
export const STORE_FAILED_ACTION = '[notification store action] failed store';

export const SEND_CONFIRMED_ACTION = '[notification send action] confirmed send';
export const SEND_FAILED_ACTION = '[notification send action] failed send';

export const SEND_ALL_CONFIRMED_ACTION = '[notification sendAll action] confirmed sendAll';
export const SEND_ALL_FAILED_ACTION = '[notification sendAll action] failed sendAll';


export const OPEN_FORMNEW_ACTION = '[notification open form new action] open form new'
export const UPDATE_DATAFORMNEW_ACTION = '[notification update data form new action] update data form new'


export function openNewFormAction(data) {
    return  (dispatch) => {        
        dispatch(openNewFormConfirmedAction(data));	
    };
}

export function openNewFormConfirmedAction(data) {
    return {
        type: OPEN_FORMNEW_ACTION,
        payload: data,
    };
}


export function storeAction(data) {
    return  (dispatch) => {
        
        store(data).then(response => {
            if (isLogged(response)) {
                dispatch(storeConfirmedAction(response.data.response || {}));	
                dispatch(enableReloadAction());
            } else {
                dispatch(Logout());
            }
        });
    };
}


export function updateDataFormNewFormAction(data) {
    return {
        type: UPDATE_DATAFORMNEW_ACTION,
        payload: data,
    };
}


export function storeConfirmedAction(data) {
    return {
        type: STORE_CONFIRMED_ACTION,
        payload: data,
    };
}


export function storeFailedAction(data) {
    return {
        type: STORE_FAILED_ACTION,
        payload: data,
    };
}



export function sendAction(data) {
    return  (dispatch) => {
        
        send(data).then(response => {
            if (isLogged(response)) {
                dispatch(sendConfirmedAction(response.data));	
                dispatch(enableReloadAction());
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function sendConfirmedAction(data) {
    return {
        type: SEND_CONFIRMED_ACTION,
        payload: data,
    };
}


export function sendFailedAction(data) {
    return {
        type: SEND_FAILED_ACTION,
        payload: data,
    };
}


export function sendAllAction() {
    return  (dispatch) => {
        
        sendAll({}).then(response => {
            if (isLogged(response)) {
                dispatch(sendAllConfirmedAction(response.data));	
                dispatch(enableReloadAction());
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function sendAllConfirmedAction(data) {
    return {
        type: SEND_ALL_CONFIRMED_ACTION,
        payload: data,
    };
}


export function sendAllFailedAction(data) {
    return {
        type: SEND_ALL_FAILED_ACTION,
        payload: data,
    };
}
