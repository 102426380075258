import http from './httpService';
import {formatError, ProcessMessages} from './MessageService'

export async function fees() {
    return http.get('/api/report/fees').then(response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err => {
        formatError(err);
        return({ data: []});
    });
}


export async function feesByInscription(inscription_id) {
    return http.post('/api/report/feesByInscription', {
        inscription_id: inscription_id
    }).then(response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err => {
        formatError(err);
        return({ data: []});
    });
}

export async function feesByDateRange(fromDate, toDate) {
    return http.post('/api/report/feesByDateRange', {
        fromDate: fromDate,
        toDate: toDate
    }).then(response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err => {
        formatError(err);
        return({ data: []});
    });
}


export async function pendingFeesByDateRange(fromDate, toDate) {
    return http.post('/api/report/pendingFeesByDateRange', {
        fromDate: fromDate,
        toDate: toDate
    }).then(response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err => {
        formatError(err);
        return({ data: []});
    });
}



export async function feesByInscriptionByDateRange(fromDate, toDate, inscription_id) {
    return http.post('/api/report/feesByInscriptionByDateRange', {
        inscription_id: inscription_id,
        fromDate: fromDate,
        toDate: toDate
    }).then(response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err => {
        formatError(err);
        return({ data: []});
    });
}


export async function defaulterFeesVersusAmountBalance() {
    return http.post('/api/report/defaulterFeesVersusAmountBalance', {}).then(response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err => {
        formatError(err);
        return({ data: []});
    });
}


export async function defaulterFees() {
    return http.post('/api/report/defaulterFees', {}).then(response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err => {
        formatError(err);
        return({ data: []});
    });
}

export async function defaulterFeesVersusPaid() {
    return http.post('/api/report/defaulterFeesVersusPaid', {}).then(response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err => {
        formatError(err);
        return({ data: []});
    });
}
