
import { useSelector, useDispatch } from "react-redux";
import { updateDataFormEditAction } from '../../../../store/actions/ModelActions.js'

import InputRoleCode from '../Input/InputRoleCode.js'
import InputRoleDescription from '../Input/InputRoleDescription.js'

const FormEditRole = (props) => {
    const model= "role";
    const dispatch = useDispatch();

    //Datos para editar una ubscripción
    const dataForm = useSelector((store) => store.role.dataEditForm);

    // Manejador de cambios en los datos de la subscripción
    function onChange(id, value) {
        let dataFormNew = { ...dataForm };
        dataFormNew[id] = value;
        dispatch(updateDataFormEditAction(model,dataFormNew));
    }

    if (props === undefined) {
        return (<div className="row"></div>)
    } else {
        let secureValues = {
            "code": dataForm.code || "",
            "description": dataForm.description || ""
        }
        return (
            <div className="row">
                <InputRoleCode disabled={true}  value={secureValues.code}  ></InputRoleCode>
                <InputRoleDescription value={secureValues.description} onChange={onChange}  ></InputRoleDescription>
            </div>
        )
    }
}
export default FormEditRole;