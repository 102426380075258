
import FormInput from '../../Form/FormInput.js'
import { useLocale } from "../../../../context/Locale/index.js";

const InputPaymentAmount = (props) => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    return (
        <FormInput { ...props}  textlabel={strings.form.addPayment.labelAmount} htmlFor="amount" type="number" id="amount" min="0"   ></FormInput>
    )
}
export default InputPaymentAmount;