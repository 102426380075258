import React, { useState, useEffect } from 'react';
import MainPagetitle from '../../layouts/MainPagetitle';
import ModalConfirm from '../Common/Modal/ModalConfirm';
import NavMenu from '../Common/NavMenu/NavMenu.js';
import Select2 from '../Base/Select2.js'
import { useLocale } from "../../../context/Locale";

import {
    getAllAction,
    destroyAction
} from '../../../store/actions/ModelActions'


import { useSelector, useDispatch } from "react-redux";
import Grid from '../Common/Grid/Grid';

import { Navigate } from "react-router-dom";
import routers from '../../../setting/routes.json'

const RoleCapability = () => {
    const pageName = "rolecapabilities"
    const model = "rolecapability";

    const [value, setValue] = useState({
        role_code: "",
        description: ""
    });


    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const list = useSelector((store) => store.rolecapability.list);
    const roles = useSelector((store) => store.role.list);
    const path = useSelector((store) => store.common.path);

    const listFiltered = list.filter(i=> (value.role_code === ""  ||  i.role_code === value.role_code));


    const canViewAdmin = HasCapability(capabilities,'MENU_ADMIN');
    const canView = HasCapability(capabilities,'PAGE_ROLECAPABILITY_LIST');

  

    const newItem = HasCapability(capabilities,"ROLECAPABILITY_CREATE");
    const destroyItem = HasCapability(capabilities,"ROLECAPABILITY_DESTROY");
    const exportData = HasCapability(capabilities,"ROLECAPABILITY_EXPORT");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [toDestroy, setToDestroy] = useState({});

    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);
    const [step, setStep] = useState("init");
    const [toNew, setToNew] = useState(false);
   

    const dispatch = useDispatch();

    const gridConf = [
        // { label: strings.table.rolecapability.header.role_code, key: "role_code", className: "", filter: true },
        { label: strings.table.rolecapability.header.capability_code, key: "capability_code", className: "", filter: true },
        { label: "Grupo", key: "capability_group", className: "", filter: true },
        { label: strings.table.rolecapability.header.role_description, key: "role_description", className: "", filter: true },
        { label: "Acción", key: "capability_action", className: "", filter: true },
        { label: strings.table.rolecapability.header.capability_description, key: "capability_description", className: "", filter: true }
    ]


    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction(model, {}));
            dispatch(getAllAction("role", {}));
            setStep("loaded");
        }

    }, [dispatch, step]);


    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;
        setValue(newValue);
    }


    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
    }

    function onDestroyForm() {
        dispatch(destroyAction(model, { ...toDestroy, id: toDestroy.role_code + "/" + toDestroy.capability_code }));
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onCancelForm() {
        setShowConfirmDestroy(false);
        setToDestroy({});
    }

    function onClickNewForm() {
        setToNew(true);
    }


    if (path !== pageName && path !== "") {
        return (<></>);
    } if (toNew) {
        return (<>{toNew && (<Navigate to={routers.rolecapability.new} replace={true} />)}</>);
    }
    else {

        const roleList = [{ "value": "", "label": "Todos" }].concat(roles.map(function (item) {
            return {
                "value": item.code,
                "label": item.description,
            };
        }));

        const roleValue = roleList.find(i => String(i.code) === String(value.role_code));

        return (
            <>
                <MainPagetitle mainTitle={strings.page.rolecapability.title} pageTitle={strings.page.rolecapability.title} parentTitle={strings.page.home.title} />

                <div className="container-fluid">
                    <NavMenu />
                    {(canViewAdmin && canView) &&
                        <>
                              <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="mb-3 row">
                                                    <label className="col-sm-2 col-form-label">Rol</label>
                                                    <div className="col-sm-8">
                                                        <Select2
                                                            id={"role_code"}
                                                            onChange={onChange}
                                                            defaultValue={roleValue}
                                                            options={roleList}
                                                        ></Select2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <Grid
                                        list={listFiltered}
                                        title={"Capacidades de " + ( (value.description !== "") ? value.description ? "rol " + value.description : "todos los roles" : "todos los roles")}
                                        conf={gridConf}
                                        // filterRecords={filterRoleCapability}
                                        onClickNewItem={onClickNewForm}
                                        onClickDestroy={onDestroyFornConfirm}

                                        newItem={newItem}
                                        destroyItem={destroyItem}
                                        exportData={exportData}

                                    // onClickEdit={onClickEditForm}

                                    ></Grid>
                                </div>
                            </div>
                        </>
                    }
                    <ModalConfirm
                        show={showComfirmDestroy}
                        title={"Confirmación"}
                        message={"¿Está seguro de eliminar esta capacidad de este rol"}
                        cancelButtonText={"No"} confirmButtonText={"Sí"}
                        onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                    >
                    </ModalConfirm>
                </div>




            </>
        );
    }




};

export default RoleCapability;