
import http from './httpService';
import {formatError, ProcessMessages} from './MessageService'

export async function filter(params) { 
    return http.get('/api/address/filter',{ params: params}).then( response => {
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: []});
    });
}

export async function store(data) { 

    let request = {
        'company_id': data.company_id,
        'street': data.street,
        'number': data.number,
        'postCode': data.postCode,
        'commune_id': data.commune_id,
        'comment': ''
    }    

    return http.post('/api/address/store', request).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}

export async function update(data) { 
    let request = {
        'id': data.id,
        'company_id': data.company_id,
        'street': data.street,
        'number': data.number,
        'postCode': data.postCode,
        'commune_id': data.commune_id,
        'comment': ''
    }   

    return http.post('/api/address/' + data.id + '/update',request).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}

export async function destroy(data) { 
    let request = {
        'code': data.code
    }

    return http.delete('/api/address/' + data.id + '/destroy',request).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}
