export function  GetAutoSendNotificationName(code){
    if( code === "ACTIVO") {
        return "Activo"
    } else if( code === "INACTIVO") {
        return "No activo"
    }  else {
        return "No indicado";
    }
}

export const AutoSendNotificationOptions = [
    { "value": "ACTIVO", "label": "Activo" },
    { "value": "INACTIVO", "label": "No activo" },
]