

import { Logout, isLogged } from '../actions/CommonActions';
import { enableReloadAction} from './UserActions';

import { store } from '../../services/UserService';


export const STORE_CONFIRMED_ACTION = '[user store action] confirmed store';
export const STORE_FAILED_ACTION = '[user store action] failed store';
export const OPEN_FORMNEW_ACTION = '[user open form new action] open form new'
export const UPDATE_DATAFORMNEW_ACTION = '[user update data form new action] update data form new'


export function openNewFormAction(data) {
    return  (dispatch) => {        
        dispatch(openNewFormConfirmedAction(data));	
    };
}

export function openNewFormConfirmedAction(data) {
    return {
        type: OPEN_FORMNEW_ACTION,
        payload: data,
    };
}


export function storeAction(data) {
    return  (dispatch) => {
        
        store(data).then(response => {
            if (isLogged(response)) {
                dispatch(storeConfirmedAction(response.data.response || {}));	
                dispatch(enableReloadAction());
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function updateDataFormNewFormAction(data) {
    return  (dispatch) => {        
        dispatch(updateDataFormNewConfirmedAction(data));	
    };
}

export function updateDataFormNewConfirmedAction(data) {
    return {
        type: UPDATE_DATAFORMNEW_ACTION,
        payload: data,
    };
}


export function storeConfirmedAction(data) {
    return {
        type: STORE_CONFIRMED_ACTION,
        payload: data,
    };
}


export function storeFailedAction(data) {
    return {
        type: STORE_FAILED_ACTION,
        payload: data,
    };
}
