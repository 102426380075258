import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import MainPagetitle from '../../../layouts/MainPagetitle';

import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'

import { useLocale } from "../../../../context/Locale";

const CapabilityView = () => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const model = "capability";
    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const data = useSelector((store) => store.capability.dataEditForm);
    const canView = HasCapability(capabilities,'CAPABILITY_GET');
    const [toEdit, setToEdit] = useState(false);
    const [value, setValue] = useState({
        id: data.id,
        code: data.code,
        description: data.description,
        group: data.group,
        action: data.action
    });

    const getValue = (id, defaultValue) => {
        return value[id] ? value[id] : defaultValue;
    }

    if (toEdit) {
        return (<>  {toEdit && (<Navigate to={routers.capability.edit} replace={true} />)}</>)
    } else {
        return (
            <>
                <MainPagetitle mainTitle={"Capacidad"} pageTitle={"Editar Capacidad"} parentTitle={strings.page.home.title} />
                {canView &&
                    <div className="container-fluid">
                        <NavMenu />
                        <div className="row">
                            <div className="col-xl-12"> <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Capacidad</h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="row">
                                            <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"code"} className="form-label">Código<span className="text-danger">*</span></label>
                                                    <input id={"code"} disabled={true} type="text" value={getValue("code", "")} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"group"} className="form-label">Grupo<span className="text-danger">*</span></label>
                                                    <input id={"group"} disabled={true} type="text" value={getValue("group", "")} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"action"} className="form-label">Acción<span className="text-danger">*</span></label>
                                                    <input id={"action"} disabled={true} type="text" value={getValue("action", "")} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"description"} className="form-label">Descripción<span className="text-danger">*</span></label>
                                                    <input id={"description"} disabled={true} type="text" value={getValue("description", "")} className="form-control" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-3 mb-3">
                                    <Button className="btn-sm" onClick={() => setToEdit(true)} variant="primary"> {"Edit"}</Button>
                                </div>
                            </div>

                        </div>
                    </div>
                }

            </>
        )

    }

}

export default CapabilityView;