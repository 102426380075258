import React, { useState} from 'react';

import { Link } from 'react-router-dom';
import { useLocale } from "../../../../../context/Locale/index.js";
import { useSelector, useDispatch } from "react-redux";

import { 
    disableReloadAction as disableReloaRegiondAction,
    filterAction as filterRegionAction,

} from '../../../../../store/actions/RegionFilterActions';

import { 
    disableReloadAction as disableReloadProvinceAction,
    enableReloadAction as enableReloadProvinceAction,
    filterAction as filterProvinceAction

} from '../../../../../store/actions/ProvinceFilterActions';

import { 
    disableReloadAction as disableReloadCommuneAction,
    enableReloadAction as enableReloadCommuneAction,
    filterAction as filterCommuneAction

} from '../../../../../store/actions/CommuneFilterActions';

import {storeAction } from '../../../../../store/actions/AddressFilterActions';

import FormInput from '../../../Form/FormInput.js'
import FormSelect2 from '../../../Form/FormSelect2.js'

import ModalConfirm from '../../../Common/Modal/ModalConfirm.js';

const FormNewAddress = (props) => {

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [showComfirm, setShowConfirm] = useState(false);
    
    const [dataForm, setDataForm] = useState({});
    const [regionSelected, setRegionSelected] = useState({});
    const [provinceSelected, setProvinceSelected] = useState({});
    const [communeSelected, setCommuneSelected] = useState({});

    const capabilities = useSelector((store) => store.user.capabilities);
    const newItem = capabilities.map(function (c) { return c.code }).includes("ADDRESS_CREATE");

    const regions = useSelector((store) => store.region.list);
    const reloadRegions = useSelector((store) => store.region.reload);
    
    const provinces = useSelector((store) => store.province.list);
    const reloadProvinces = useSelector((store) => store.province.reload);

    const communes = useSelector((store) => store.commune.list);
    const reloadCommunes = useSelector((store) => store.commune.reload);

    const companyId = props? (props.companyId? props.companyId: "") : "";


    const dispatch = useDispatch();


    function onChange(id, value) {
        let dataNewFormNew = { ...dataForm };
        dataNewFormNew[id] = value;
        if (id === "region_id") {
            setCommuneSelected({});
            setProvinceSelected({});
            setRegionSelected(regions.find(r => String(r.id) === String(value)) || {});
            
            dispatch(enableReloadProvinceAction());
        }

        if (id === "province_id") {
            setCommuneSelected({});
            setProvinceSelected(provinces.find(p => String(p.id) === String(value)) || {});
            dispatch(enableReloadCommuneAction());
        }

        if (id === "commune_id") {
            setCommuneSelected(communes.find(c => String(c.id) === String(value)) || {});
        }

        setDataForm(dataNewFormNew);
    }


    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onCancelForm() {
        setShowConfirm(false);
        if (props.onCancel !== undefined) {
            props.onCancel();
        }
    }

    function onSaveForm() {

        let dataNewFormNew = { ...dataForm };
        dataNewFormNew["company_id"] = companyId;

        dispatch(storeAction(dataNewFormNew));
        setShowConfirm(false);
        if (props.onSave !== undefined) {
            props.onSave();
        }
    }


    if (companyId === "" || ( !newItem && Array.isArray(regions))) {
        return (<></>);
    } else {

        


        if(reloadCommunes && provinceSelected.id ){
            dispatch(disableReloadCommuneAction());
            dispatch(filterCommuneAction({ "province_id": provinceSelected.id}));
        }


        if(reloadProvinces && regionSelected.id){
            dispatch(disableReloadProvinceAction());
            dispatch(filterProvinceAction({ "region_id": regionSelected.id}));
        }



        if(reloadRegions){
            dispatch(disableReloaRegiondAction());
            dispatch(filterRegionAction({}));
        }

      

        let regionsList = regions.map(function (item) {
            return {
                "value": String(item.id),
                "label": String(item.name),
            };
        });

        let provincesList = provinces.map(function (item) {
            return {
                "value": String(item.id),
                "label": String(item.name),
            };
        });

        let communesList = communes.map(function (item) {
            return {
                "value": String(item.id),
                "label": String(item.name),
            };
        });

        const regionValue = regionsList.find(i=> regionSelected.id ?  String(i.value) === String(regionSelected.id): false);
        const provinceValue = provincesList.find(i=> provinceSelected.id ?  String(i.value) === String(provinceSelected.id): false);
        const communeValue = communesList.find(i=> communeSelected.id ?  String(i.value) === String(communeSelected.id): false);
        

        return (
            <>
                <div className="row">
                    { (regionValue !== undefined)  && <FormSelect2 defaultValue={regionValue} options={regionsList}  onChange={onChange} textlabel="Región" id="region_id" placeholder="Región"  ></FormSelect2>}
                    { (regionValue === undefined)  &&  <FormSelect2 options={regionsList}  onChange={onChange} textlabel="Región" id="region_id" placeholder="Región"  ></FormSelect2>}

                    { (provinceValue !== undefined) && <FormSelect2 defaultValue={provinceValue}  options={provincesList} onChange={onChange} textlabel="Provincia" id="province_id" placeholder="Provincia"  ></FormSelect2>}
                    { (provinceValue === undefined) && <FormSelect2 options={provincesList} onChange={onChange} textlabel="Provincia" id="province_id" placeholder="Provincia"  ></FormSelect2>}
                
                    { (communeValue !== undefined) &&  <FormSelect2 defaultValue={communeValue}  options={communesList}   onChange={onChange} textlabel="Comuna" id="commune_id" placeholder="Comuna"  ></FormSelect2>}
                    { (communeValue === undefined) &&  <FormSelect2 options={communesList}   onChange={onChange} textlabel="Comuna" id="commune_id" placeholder="Comuna"  ></FormSelect2> }

                    <FormInput  onChange={onChange}  textlabel="Calle" htmlFor="street" type="text" id="street" placeholder="Calle"   ></FormInput>
                    <FormInput  onChange={onChange}  textlabel="Número" htmlFor="number" type="text" id="number" placeholder="Número"   ></FormInput>
                    <FormInput  onChange={onChange}  textlabel="Código Postal" htmlFor="postCode" type="text" id="postCode" placeholder="Código Postal"   ></FormInput>
                </div>
                <div>
                    <button onClick={() => onSaveFormConfirm()} className="btn btn-sm btn-primary me-1">{strings.form.common.buttonAccept}</button>
                    <Link to={"#"} onClick={() => onCancelForm()} className="btn btn-sm btn-danger light ms-1">{strings.form.common.buttonCancel}</Link>
                </div>
                <ModalConfirm
                    show={showComfirm}
                    title={"Confirmación"}
                    message={"¿Está seguro de crear la dirección"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>
            </>
        );
    }


}

export default FormNewAddress;