
export const RELOAD_ENABLE_ACTION = '[notification reload action] enable reload';
export const RELOAD_DISABLE_ACTION = '[notification reload action] disable reload';



export function enableReloadAction() {
    return {
        type: RELOAD_ENABLE_ACTION,
        payload: true,
    };
}

export function disableReloadAction() {
    return {
        type: RELOAD_DISABLE_ACTION,
        payload: false,
    };

}