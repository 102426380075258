import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateAction, getAllAction } from '../../../../store/actions/ModelActions.js'
import { Button } from "react-bootstrap";
import DateInput from '../../Base/DateInput.js'
import { formatIsoDateToDate } from '../../../../tools/fomatter.js'

import ModalConfirm from '../../Common/Modal/ModalConfirm';
import MainPagetitle from '../../../layouts/MainPagetitle';
import { useLocale } from "../../../../context/Locale";
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'


const InscriptionEdit = (props) => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const dataForm = useSelector((store) => store.inscription.dataEditForm);
    const [showComfirm, setShowConfirm] = useState(false);

    const dispatch = useDispatch();
    const model = "inscription";

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
    const [toList, setToList] = useState(false);

    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }


    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;
        setValue(newValue);
    }

    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {

        setToList(true);

        const request = {
            "id": value.id,
            "legal_id": value.legal_id,
            "company_legal_id": value.company_legal_id,
            "initial_fee": value.initial_fee,
            "zone": value.zone,
            "amount": value.amount,
            "amount_fees": value.amount_fees,
            "payment_day": value.payment_day,
            "start_date": value.start_date,
            "comment": value.comment,
            "company_name": value.company_name,
            "fee_template_id": value.fee_template_id
        };

        dispatch(updateAction(model, request));
        dispatch(getAllAction(model, {}));
        
        setShowConfirm(false);
        if (props.onSave !== undefined) {
            props.onSave();
        }

    }

    function onCancelForm() {
        setShowConfirm(false);
        if (props.onCancel !== undefined) {
            props.onCancel();
        }
    }

    useEffect(() => {

        if (step === "init") {
            setValue({
                "id": dataForm.id || 0,
                "legal_id": dataForm.legal_id || "",
                "company_legal_id": dataForm.company_legal_id || "",
                "zone": dataForm.zone || "",
                "initial_fee": dataForm.initial_fee || 0,
                "amount": dataForm.amount || 1,
                "amount_fees": dataForm.amount_fees || 1,
                "payment_day": dataForm.payment_day || 1,
                "start_date": formatIsoDateToDate(dataForm.start_date) || new Date(),
                "comment": dataForm.comment || "",
                "company_name": dataForm.company_name || "",
                "fee_template_id": dataForm.fee_template_id || 0,
                "company_branch_number": dataForm.company_branch_number
            });
        }

        if (step !== "edit") {
            setStep("edit");
        }

    }, [dispatch, step, dataForm]);

    if (props === undefined || toList || value.id === undefined) {
        return (<> {toList && (<Navigate to={routers.inscription.admin} replace={true} />)}</>)
    } else {


        const fieldsInscription = [
            { "name": "zone", "label": "Zona", "type": "text", "required": false, disabled: false },
            { "name": "initial_fee", "label": "Cuota Inicial", "type": "text", "required": false, disabled: false },
            { "name": "amount", "label": "Monto Mensual", "type": "text", "required": false , disabled: true},
            { "name": "amount_fees", "label": "Cantidad de Cuotas", "type": "text", "required": false , disabled: true},
            { "name": "payment_day", "label": "Día de Pago", "type": "text", "required": false , disabled: true},
            { "name": "start_date", "label": "Fecha de inicio", "type": "date", "required": false , disabled: true},
            { "name": "comment", "label": "Comentario", "type": "text", "required": false }
        ]

        const fieldsCompany = [
            { "name": "company_legal_id", "label": "Rut Empresa", "type": "text", "required": false, disabled: true },
            { "name": "company_name", "label": "Empresa", "type": "text", "required": false, disabled: true },
            { "name": "company_branch_number", "label": "Número de Locales", "type": "number", "required": false,disabled: true  }
        ]

        return (
            <>
                <MainPagetitle mainTitle={"Inscripción"} pageTitle={"Editar Inscripción"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">

                    <NavMenu />
                    <div className="row">
                        <div className="col-xl-12">
                        <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Empresa</h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="row">
                                                {fieldsCompany.map((item, index) => (

                                                    <div key={index} className="col-xl-3 mb-3">

                                                        {item.type !== "date" &&

                                                            <label htmlFor={item.name} className="form-label">{item.label} {item.required && <span className="text-danger">*</span>}</label>

                                                        }

                                                        {item.type === "date" &&
                                                            <div className="row" >
                                                                <label htmlFor={item.name} className="form-label">{item.label} {item.required && <span className="text-danger">*</span>}</label>
                                                            </div>
                                                        }



                                                        {item.type === "text" &&
                                                            <input disabled={item.disabled} onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={item.name} type="text" value={getValue(item.name, "")} className="form-control" />
                                                        }

                                                        {item.type === "number" &&
                                                            <input disabled={item.disabled} onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={item.name} type="number" value={getValue(item.name, 0)} className="form-control" />
                                                        }

                                                        {item.type === "date" &&
                                                            <div className="row" >
                                                                <DateInput disabled={item.disabled} onChange={onChange} id={item.name} selected={getValue(item.name, new Date())}  ></DateInput>
                                                            </div>
                                                        }
                                                    </div>

                                                ))}

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Inscripción</h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="row">
                                                {fieldsInscription.map((item, index) => (

                                                    <div key={index} className="col-xl-3 mb-3">

                                                        {item.type !== "date" &&

                                                            <label htmlFor={item.name} className="form-label">{item.label} {item.required && <span className="text-danger">*</span>}</label>

                                                        }

                                                        {item.type === "date" &&
                                                            <div className="row" >
                                                                <label htmlFor={item.name} className="form-label">{item.label} {item.required && <span className="text-danger">*</span>}</label>
                                                            </div>
                                                        }



                                                        {item.type === "text" &&
                                                            <input disabled={item.disabled} onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={item.name} type="text" value={getValue(item.name, "")} className="form-control" />
                                                        }

                                                        {item.type === "number" &&
                                                            <input disabled={item.disabled} onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={item.name} type="number" value={getValue(item.name, 0)} className="form-control" />
                                                        }

                                                        { (item.type === "date") &&
                                                            <div className="row" >
                                                                <DateInput disabled={item.disabled} onChange={onChange} id={item.name} selected={getValue(item.name, new Date())}  ></DateInput>
                                                            </div>
                                                        }
                                                    </div>

                                                ))}

                                            </div>
                                            <div className="mb-3">
                                                <Button onClick={() => onSaveFormConfirm()} className="btn-sm" variant="primary"> {"Enviar"}</Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>



                    <ModalConfirm
                        show={showComfirm}
                        title={"Confirmación"}
                        message={"¿Está seguro de editar la inscripción"}
                        cancelButtonText={"No"} confirmButtonText={"Sí"}
                        onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                    >
                    </ModalConfirm>
                </div>


            </>
        )
    }
}
export default InscriptionEdit;