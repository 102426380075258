export function GetSizeName(code) {
    if (code === "micro") {
        return "Micro"
    } else if (code === "mediana") {
        return "Mediana"
    } else if (code === "grande") {
        return "Grande"
    } else { return "No indicado"; }
}


export const SizeOptions = [
    { "label": "Micro", "value": "micro" },
    { "label": "Mediana", "value": "mediana" },
    { "label": "Grande", "value": "grande" }
]