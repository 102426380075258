import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { storeAction } from '../../../../store/actions/ModelActions'

import Select2 from '../../Base/Select2.js'
import ModalConfirm from '../../Common/Modal/ModalConfirm';
import MainPagetitle from '../../../layouts/MainPagetitle.js';
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import { useLocale } from "../../../../context/Locale/index.js";
import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'

const PersonNew = (props) => {


    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const model = "person";
    const capabilities = useSelector((store) => store.user.capabilities);

    const [showComfirm, setShowConfirm] = useState(false);

    const dispatch = useDispatch();

    const dataForm = useSelector((store) => store.person.dataNewForm);

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
    const [toList, setToList] = useState(false);

    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }

    useEffect(() => {

        if (step === "init") {
            setValue({
                "first_name": "",
                "last_name": "",
                "legal_id": "",
                "gender": "",
                "email": "",
                "phone": ""
            })
        }

        if (step !== "edit") {
            setStep("edit");
        }

    }, [step]);

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;
        setValue(newValue);
    }

    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {

        setToList(true);

        const request = {
            "first_name": value.first_name,
            "last_name": value.last_name,
            "legal_id": value.legal_id,
            "gender": value.gender,
            "email": value.email,
            "phone": value.phone
        }

        dispatch(storeAction(model, request));
        setShowConfirm(false);

    }

    function onCancelForm() {
        setShowConfirm(false);

    }

    if (props === undefined || toList) {
        return (<> {toList && (<Navigate to={routers.person.admin} replace={true} />)}</>)
    } else if (!capabilities.map(function (c) { return c.code }).includes('PERSON_CREATE')) {
        return (<></>);
    } else {
        let values = {
            "first_name": dataForm.first_name || "",
            "last_name": dataForm.last_name || "",
            "legal_id": dataForm.legal_id || "",
            "gender": dataForm.gender || "",
            "email": dataForm.email || "",
            "phone": dataForm.phone || ""
        }

        const GenderList = [
            { "value": "", "label": "Seleccione..." },
            { "value": strings.table.person.gender.male, "label": strings.table.person.gender.male },
            { "value": strings.table.person.gender.female, "label": strings.table.person.gender.female },
            { "value": strings.table.person.gender.other, "label": strings.table.person.gender.other }
        ]

        const fields = [
            {
                "label": "Datos", "data": [
                    { "name": "first_name", "label": strings.form.addPerson.labelFirstName, "type": "text", "required": true, disabled: false },
                    { "name": "last_name", "label": strings.form.addPerson.labelLastName, "type": "text", "required": false, disabled: false },
                    { "name": "legal_id", "label": strings.form.addPerson.labelLegalId, "type": "text", "required": false, disabled: false },
                    { "name": "gender", "label": strings.form.addPerson.labelGender, "type": "select", options: GenderList, defaultValue: GenderList.find(c => String(c.value) === String(values.gender || "") || {}), "required": true, disabled: false },
                    { "name": "email", "label": strings.form.addPerson.labelEmail, "type": "text", "required": false, disabled: false },
                    { "name": "phone", "label": strings.form.addPerson.labelMobilePhone, "type": "text", "required": false, disabled: false }

                ]
            }]


        return (
            <>
                <MainPagetitle mainTitle={"Persona"} pageTitle={"Nueva Persona"} parentTitle={strings.page.home.title} /> 
                <div className="container-fluid">
                    <NavMenu />
                    <div className="row">
                        {
                            fields.map((group, index1) => (
                                <div key={index1} className="card">
                                    {group.label !== "" &&
                                        <div className="card-header">
                                            <h4 className="card-title">{group.label}</h4>
                                        </div>
                                    }
                                    <div className="card-body">
                                        <div className="basic-form">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <div className="row">
                                                    {group.data.map((item, index) => (

                                                        <div key={index} className="col-xl-6 mb-3">
                                                            <label htmlFor={item.name} className="form-label">{item.label} {item.required && <span className="text-danger">*</span>}</label>
                                                            {item.type === "text" &&
                                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={item.name} type="text" value={getValue(item.name,"")} className="form-control" />
                                                            }
                                                            {item.type === "select" &&
                                                                <Select2 onChange={onChange} defaultValue={item.defaultValue} options={item.options} id={item.name} ></Select2>
                                                            }
                                                        </div>

                                                    ))}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>


                            ))
                        }
                        < div >
                            <Button onClick={() => onSaveFormConfirm()} className="btn-sm" variant="primary"> {"Enviar"}</Button>
                        </div >
                    </div>
                </div>


                <ModalConfirm
                    show={showComfirm}
                    title={"Confirmación"}
                    message={"¿Está seguro de agregar la persona"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>
            </>

        )
    }
}
export default PersonNew;