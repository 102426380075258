import React, { useState, useEffect } from 'react';
import { useLocale } from "../../../../../context/Locale";
import ModalConfirm from '../../../Common/Modal/ModalConfirm';
import { storeAction, filterAction } from '../../../../../store/actions/ModelActions.js'
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup } from "react-bootstrap";
import DateInput from '../../../Base/DateInput.js'

import {formatIsoDateToDate,addMonthsToDate} from '../../../../../tools/fomatter.js'

const FormExtensionNew = (props) => {

    const dispatch = useDispatch();
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const model = "extension";

    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const fees = useSelector((store) => store.fee.list);

    const lastFee = fees.reduce(function(accumulator, currentValue) { return (accumulator.fee_payment_date > currentValue.fee_payment_date) ? accumulator: currentValue; });

    const canCreate = HasCapability(capabilities, "EXTENSION_CREATE");

    const [data, setData] = useState(props ? (props.data ? props.data : {}) : {});

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
    const [showComfirm, setShowConfirm] = useState(false);

    useEffect(() => {

        if (step === "init") {
            dispatch(filterAction("fee",{"inscription_id": data.id }));

            const new_start_date = addMonthsToDate(formatIsoDateToDate(lastFee.fee_payment_date),1);
            setValue({
                "inscription_id": data.id,
                "amount": data.amount,
                "amount_fees": data.amount_fees,
                "payment_day": data.payment_day,
                "start_date": new_start_date
            });
            setStep("edit");
        }

    }, [step, data]);




    const getValue = (id, defaultValue) => {
        return value[id] ? value[id] : defaultValue
    }

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;;
        setValue(newValue);
    }

    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {

        const request = {
            "inscription_id": value.inscription_id,
            "amount": value.amount,
            "amount_fees": value.amount_fees,
            "payment_day": value.payment_day,
            "start_date": value.start_date
        }

        dispatch(storeAction(model, request));
        setShowConfirm(false);

        if (props.onSave !== undefined) {
            props.onSave(request);
        }

    }

    function onCancelForm() {
        setShowConfirm(false);
        if (props.onCancel !== undefined) {
            props.onCancel();
        }
    }


    if (data.id === undefined || !canCreate) {
        return (<></>);
    } else {

        return (<>
            <div className="row">
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"amount"} className="form-label">{"Monto Mensual"}<span className="text-danger">*</span></label>
                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"amount"} type="number" value={getValue("amount", 1)} min={1} className="form-control" />
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"amount_fees"} className="form-label">Cantidad de Cuotas<span className="text-danger">*</span></label>
                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"amount_fees"} type="number" value={getValue("amount_fees", 1)} min={1} max={36} className="form-control" />
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"payment_day"} className="form-label">Día de Pago<span className="text-danger">*</span></label>
                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"payment_day"} type="number" value={getValue("payment_day", 1)} min={1} max={31} className="form-control" />
                </div>
                <div className="col-xl-3 mb-3">
                    <div className="row" ><label htmlFor={"start_date"} className="form-label">Fecha de inicio<span className="text-danger">*</span></label></div>
                    <div className="row" ><DateInput onChange={onChange} id={"start_date"} selected={getValue("start_date", new Date())} ></DateInput></div>
                </div>

            </div>
            
            <div className="row">
                <div className="col-xl-3 mb-3">
                    <ButtonGroup>
                        <Button onClick={() => onSaveFormConfirm()} className="btn-sm" variant="primary"> {"Guardar"}</Button>
                        <Button onClick={() => onCancelForm()} className="btn-sm" variant="secondary"> {"Cancelar"}</Button>
                    </ButtonGroup>
                </div>
            </div>
            
            <ModalConfirm
                show={showComfirm}
                title={"Confirmación"}
                message={"¿Está seguro de guardar los cambios realizados?"}
                cancelButtonText={"No"} confirmButtonText={"Sí"}
                onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
            >
            </ModalConfirm>
        </>);
    }


}

export default FormExtensionNew;