
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Button, Dropdown, ButtonGroup, } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'

const NavMenu = () => {
    const path = useSelector((store) => store.common.path);

    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    

    const newInscription = HasCapability(capabilities,"BTN_INSCRIPTION_NAVMENU");
    const [toNewInscription, setToNewInscription] = useState(false);

    const newPayment = HasCapability(capabilities,"BTN_PAYMENT_CREATE");
    const [toNewPayment, setToNewPayment] = useState(false);

    const listPayment = HasCapability(capabilities,"BTN_PAYMENT_LIST");
    const [toListPayment, setToListPayment] = useState(false);

    const listPerson = HasCapability(capabilities,"BTN_PERSON_LIST");
    const [toListPerson, setToListPerson] = useState(false);

    const listCompany = HasCapability(capabilities,"BTN_COMPANY_NAVMENU");
    const [toListCompany, setToListCompany] = useState(false);

    const listFee = HasCapability(capabilities,"BTN_FEE_LIST");
    const [toListFee, setToListFee] = useState(false);


    const listReport = HasCapability(capabilities,"BTN_REPORT_LIST");
    const [toListReport, setToListReport] = useState(false);

    const listFeeTemplate = HasCapability(capabilities,"BTN_FEETEMPLATE_LIST");
    const [toListFeeTemplate, setToListFeeTemplate] = useState(false);


    const listNotification = HasCapability(capabilities,"BTN_NOTIFICATION_LIST");
    const [toListNotification, setToListNotification] = useState(false);

    const listInscription = HasCapability(capabilities,"BTN_INSCRIPTION_NAVMENU");
    const [toListInscription, setToListInscription] = useState(false);

    const viewDashboard = HasCapability(capabilities,"BTN_DASHBOARD_VIEW");
    const [toViewDashboard, setToViewDashboard] = useState(false);


    if (newInscription || newPayment || listPayment || listPerson || listCompany || listNotification || listFee || listReport || listFeeTemplate) {
        return (
            <>
                {toViewDashboard && (<Navigate to={routers.dashboard.admin} replace={true} />)}
                {toNewInscription && (<Navigate to={routers.inscription.new} replace={true} />)}
                {toNewPayment && (<Navigate to={routers.payment.new} replace={true} />)}
                {toListPayment && (<Navigate to={routers.payment.admin} replace={true} />)}
                {toListPerson && (<Navigate to={routers.person.admin} replace={true} />)}
                {toListCompany && (<Navigate to={routers.company.admin} replace={true} />)}
                {toListNotification && (<Navigate to={routers.notification.inbox} replace={true} />)}
                {listNotification && (<Navigate to={routers.notification.admin} replace={true} />)}
                {toListFee && (<Navigate to={routers.fee.admin} replace={true} />)}
                {toListInscription && (<Navigate to={routers.inscription.admin} replace={true} />)}
                {toListReport && (<Navigate to={routers.report.admin} replace={true} />)}
                {toListFeeTemplate && (<Navigate to={routers.feetemplate.admin} replace={true} />)}

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <ButtonGroup>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="btn-sm "> Menu ... </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {listInscription &&
                                                <Dropdown.Item onClick={() => setToListInscription(true)} > <span><i className="fa fa-chart-column" /> Inscripciones</span></Dropdown.Item>
                                            }
                                            {listPayment &&
                                                <Dropdown.Item onClick={() => setToListPayment(true)} > <span><i className="fa-solid fa-cash-register" /> Pagos</span></Dropdown.Item>
                                            }
                                            {listFee &&
                                                <Dropdown.Item onClick={() => setToListFee(true)} > <span><i className="fa-solid fa-comment-dollar" /> Cuotas</span></Dropdown.Item>
                                            }
                                            {listFeeTemplate &&
                                                <Dropdown.Item onClick={() => setToListFeeTemplate(true)} > <span><i className="fa-solid fa-comments-dollar" /> Planes de Cuotas</span></Dropdown.Item>
                                            }
                                            {listPerson &&
                                                <Dropdown.Item onClick={() => setToListPerson(true)} > <span><i className="fa-solid fa-person" /> Personas</span></Dropdown.Item>
                                            }
                                            {listCompany &&
                                                <Dropdown.Item onClick={() => setToListCompany(true)} > <span><i className="fa-regular fa-building" /> Empresas</span></Dropdown.Item>
                                            }
                                            {listNotification &&
                                                <Dropdown.Item onClick={() => setToListNotification(true)} > <span><i className="fa-regular fa-envelope" /> Notificaciones</span></Dropdown.Item>
                                            }
                                            {listReport &&
                                                <Dropdown.Item onClick={() => setToListReport(true)} > <span><i className="fa-regular fa-file" /> Reportes</span></Dropdown.Item>
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </ButtonGroup>
                                {viewDashboard &&
                                    <Button onClick={() => setToViewDashboard(true)} variant={routers.dashboard.admin === "/" + path ? "secondary btn-sm" : "outline-light btn-sm"}>
                                        <span><i className="fa fa-house" /> Inicio</span>
                                    </Button>}
                                {newInscription &&
                                    <Button onClick={() => setToNewInscription(true)} variant={routers.inscription.new === "/" + path ? "secondary btn-sm" : "outline-light btn-sm"}>
                                        <span><i className="fa fa-chart-column" /> Nueva Inscripción</span>
                                    </Button>}
                                {newPayment &&
                                    <Button onClick={() => setToNewPayment(true)} className="btn-sm" variant={routers.payment.new === "/" + path ? "secondary" : "outline-light"}>
                                        <span><i className="fa-solid fa-cash-register" /> Registrar Pago</span></Button>}
                                {listCompany &&
                                    <Button onClick={() => setToListCompany(true)} className="btn-sm" variant={routers.company.admin === "/" + path ? "secondary" : "outline-light"}>
                                        <span><i className="fa-regular fa-building" /> Empresas</span></Button>}
                                {listPerson &&
                                    <Button onClick={() => setToListPerson(true)} className="btn-sm" variant={routers.person.admin === "/" + path ? "secondary" : "outline-light"}>
                                        <span><i className="fa-solid fa-person" /> Personas</span></Button>}

                            </div>
                        </div>
                    </div>
                </div>

            </>);
    } else {
        return (<></>);
    }


}

export default NavMenu;