
import Select2 from '../Base/Select2.js'
import Label from '../Base/Label.js'

const FormSelect2 = (props) => {
    

    if (props === undefined) {
        return (
            <div className="col-xl-6 mb-3" ></div>
        )
    } else {
        return (
            <div className="col-xl-6 mb-3" >
                <Label text={props.textlabel}></Label>
                <Select2 { ...props}  ></Select2>
            </div>
        )
    }


}
export default FormSelect2;