import React from "react";
import { COUNTRY_CONSTANTS, LOCALE_STRINGS, REGIONS } from "./constants";

const initialState = {
  strings: LOCALE_STRINGS[REGIONS.ES],
  constants: COUNTRY_CONSTANTS[REGIONS.ES]
};

const LocaleContext = React.createContext();

const reducer = (stateLocale, action) => {
  switch (action.type) {
    case "CHANGE_LOCALE": {
      return {
        ...stateLocale,
        strings: LOCALE_STRINGS[action.payload.region],
        constants: COUNTRY_CONSTANTS[action.payload.region]
      };
    }
    case "RESET_LOCALE": {
      return {
        ...stateLocale,
        strings: LOCALE_STRINGS[REGIONS.ES],
        constants: COUNTRY_CONSTANTS[REGIONS.ES]
      };
    }
    default:
      return stateLocale;
  }
};

export const LocaleProvider = ({ children }) => {
  const [stateLocale, dispatch] = React.useReducer(reducer, initialState);

  return (
    <LocaleContext.Provider value={{ stateLocale, dispatch }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => React.useContext(LocaleContext);
