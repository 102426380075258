import {  user } from '../../services/UserService';

export const SET_PATH_ACTION = '[set path action] set path';
export const SET_USER_ACTION = '[set user action] set user';

export const LOGOUT_ACTION = '[auth Logout action] logout action';
export const LOGOUT2_ACTION = '[auth Logout2 action] logout2 action';


export const MUTEX_CALL_ACTION = '[mutex call action] mutex call';
export const UMUTEX_CALL_ACTION = '[unmutex call action] unmutex call';

export function isLogged(response) {
    if (response.message && response.message === "Request failed with status code 401") {
        return false;
    }
    return true;
}


export function Mutex(id) {
    return {
        type: MUTEX_CALL_ACTION,
        payload: id,
    };
}

export function Unmutex(id) {
    return {
        type: UMUTEX_CALL_ACTION,
        payload: id,
    };
}



export function Logout() {
    localStorage.removeItem('userDetails');

    return {
        type: LOGOUT_ACTION,
    };
}

export function Logout2() {
    localStorage.removeItem('userDetails');

    return {
        type: LOGOUT2_ACTION,
    };
}

//User
export function getUserAction() {
    return  (dispatch) => {
        
        user().then(response => {
            if (isLogged(response)) {
                dispatch(setUser(response.data));	
            } else {
                dispatch(Logout());
            }
            dispatch(Unmutex());
        });
    };
}

export function setUser(user){
    return {
        type: SET_USER_ACTION,
        payload: user,
    };
}


//Path
export function setPath(path){
    return {
        type: SET_PATH_ACTION,
        payload: path,
    };
}

