import React, { useState, useEffect } from 'react';
import MainPagetitle from '../../../layouts/MainPagetitle';
import ModalConfirm from '../../Common/Modal/ModalConfirm';
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import Select2 from '../../Base/Select2.js'

import { useLocale } from "../../../../context/Locale";

import {
    storeAction,
    getAllAction
} from '../../../../store/actions/ModelActions'


import { useSelector, useDispatch } from "react-redux";
import Grid from '../../Common/Grid/Grid';
import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'


const RoleCapabilityNew = () => {
    const pageName = "rolecapabilities-new"
    const model = "rolecapability";
    const path = useSelector((store) => store.common.path);

    const capabilities = useSelector((store) => store.user.capabilities);
    const roles = useSelector((store) => store.role.list);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const canViewAdmin = HasCapability(capabilities, 'MENU_ADMIN');
    const canView = HasCapability(capabilities, 'ROLECAPABILITY_CREATE');
    const selectItem = HasCapability(capabilities, 'ROLECAPABILITY_CREATE');
    const selectItemLabel = "Agregar";

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [showComfirmNew, setShowComfirmNew] = useState(false);

    const [step, setStep] = useState("init");

    const [value, setValue] = useState({
        role_code: "",
        capability_code: ""
    });

    const [toList, setToList] = useState(false);

    const dispatch = useDispatch();


    const list = useSelector((store) => store.capability.list);

    const roleCapbilitieslist = useSelector((store) => store.rolecapability.list);
    const roleCapbilitiesCodesList = (value.role_code != "") ? roleCapbilitieslist.filter(r => (value.role_code === r.role_code)).map(function (c) { return c.capability_code }) : roleCapbilitieslist.map(function (c) { return c.capability_code });

    const listFiltered = (value.role_code != "") ? list.filter(c => (!roleCapbilitiesCodesList.includes(c.code))) : [];

    const gridConf = [
        { label: strings.table.capability.header.code, key: "code", className: "", filter: true },
        { label: "Grupo", key: "group", className: "", filter: true },
        { label: "Acción", key: "action", className: "", filter: true },
        { label: strings.table.capability.header.description, key: "description", className: "", filter: true }
    ]

    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction("capability", {}));
            dispatch(getAllAction("role", {}));
            dispatch(getAllAction("rolecapability", {}));

            setStep("loaded");
        }

    }, [dispatch, step]);

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;
        setValue(newValue);
    }


    function onClickSelectItem(item) {
        setValue({ ...value, capability_code: item.code });
        setShowComfirmNew(true);
    }

    function onClickNewForm() {
        const request = {
            role_code: value.role_code,
            capability_code: value.capability_code
        }
        dispatch(storeAction(model, request));
        dispatch(getAllAction("rolecapability", {}));
        setShowComfirmNew(false);
    }

    function onCancelForm() {
        setShowComfirmNew(false);
    }


    if (path !== pageName && path !== "") {
        return (<></>);
    } if (toList) {
        return (<>
            {toList && (<Navigate to={routers.rolecapability.admin} replace={true} />)}
        </>);
    } else {
        const roleList = roles.map(function (item) {
            return {
                "value": item.code,
                "label": item.description,
            };
        });

        const roleValue = roleList.find(i => String(i.code) === String(value.role_code));

        return (
            <>
                <MainPagetitle mainTitle={"Capacidades de Rol"} pageTitle={"Agregar una Capacidad a un Rol"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {(canViewAdmin && canView) &&
                        <>


                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="mb-3 row">
                                                    <label className="col-sm-2 col-form-label">Rol</label>
                                                    <div className="col-sm-8">
                                                        <Select2
                                                            id={"role_code"}
                                                            onChange={onChange}
                                                            defaultValue={roleValue}
                                                            options={roleList}
                                                        ></Select2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-xl-12">
                                    <Grid
                                        list={listFiltered}
                                        title={strings.page.capability.title}
                                        conf={gridConf}
                                        onClickSelect={onClickSelectItem}

                                        selectItem={selectItem}
                                        selectItemLabel={selectItemLabel}

                                    ></Grid>
                                </div>
                            </div>
                        </>

                    }
                </div>
                <ModalConfirm
                    show={showComfirmNew}
                    title={"Confirmación"}
                    message={"¿Está seguro de agregar la capacidad al rol seleccionado"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onClickNewForm}
                >
                </ModalConfirm>
            </>
        );
    }




};

export default RoleCapabilityNew;