
import React, { useState, useEffect } from 'react';
import { Logout, isLogged, Unmutex} from '../actions/CommonActions';
import {disableReloadAction} from './ReportActions'

import { fees,feesByDateRange, feesByInscription,
     pendingFeesByDateRange, defaulterFees,
     feesByInscriptionByDateRange, defaulterFeesVersusAmountBalance,
     defaulterFeesVersusPaid } from '../../services/ReportService';

export const SET_FEESINPROGRESS_ACTION = '[report set fees in progress action] set fees in progress';
export const FEES_CONFIRMED_ACTION = '[report fees action] confirmed fees';
export const FEES_FAILED_ACTION = '[report fees action] failed fees';
export const UPDATE_PARAMSREPORT_ACTION = '[report update params report action] update params report'
export const UPDATE_CODEREPORT_ACTION = '[report update code report action] update code report'


export function updateParamsFormReportAction(data) {
    return {
        type: UPDATE_PARAMSREPORT_ACTION,
        payload: data,
    };
}

export function updateCodeFormReportAction(data) {
    return {
        type: UPDATE_CODEREPORT_ACTION,
        payload: data,
    };
}


export function setGetAllInProgressAction() {
    return {
        type: SET_FEESINPROGRESS_ACTION,
        payload: true,
    };
}


export function feesByInscriptionAction(data){
    return  (dispatch) => {
        feesByInscription(data.params.inscription_id).then(response => {
            if (isLogged(response)) {
                dispatch(getAllConfirmedAction(response.data));	
                dispatch(disableReloadAction());
            } else {
                dispatch(Logout());
            }
            dispatch(Unmutex());
        });
    };
}


export function feesByDateRangeAction(data){
    return  (dispatch) => {
        feesByDateRange(data.params.fromDate,data.params.toDate).then(response => {
            if (isLogged(response)) {
                dispatch(getAllConfirmedAction(response.data));	
                dispatch(disableReloadAction());
            } else {
                dispatch(Logout());
            }
            dispatch(Unmutex());
        });
    };
}

export function pendingFeesByDateRangeAction(data){
    return  (dispatch) => {
        pendingFeesByDateRange(data.params.fromDate,data.params.toDate).then(response => {
            if (isLogged(response)) {
                dispatch(getAllConfirmedAction(response.data));	
                dispatch(disableReloadAction());
            } else {
                dispatch(Logout());
            }
            dispatch(Unmutex());
        });
    };
}

export function defaulterFeesAction(data){
    return  (dispatch) => {
        defaulterFees().then(response => {
            if (isLogged(response)) {
                dispatch(getAllConfirmedAction(response.data));	
                dispatch(disableReloadAction());
            } else {
                dispatch(Logout());
            }

            dispatch(Unmutex());
        });
    };
}




export function feesByInscriptionByDateRangeAction(data){
    return  (dispatch) => {
        feesByInscriptionByDateRange(data.params.fromDate,data.params.toDate,data.params.inscription_id).then(response => {
            if (isLogged(response)) {
                dispatch(getAllConfirmedAction(response.data));	
                dispatch(disableReloadAction());
            } else {
                dispatch(Logout());
            }
            dispatch(Unmutex());
        });
    };
}

export function defaulterFeesVersusAmountBalanceAction(data){
    return  (dispatch) => {
        defaulterFeesVersusAmountBalance().then(response => {
            if (isLogged(response)) {
                dispatch(getAllConfirmedAction(response.data));	
                dispatch(disableReloadAction());
            } else {
                dispatch(Logout());
            }
            dispatch(Unmutex());
        });
    };
}

export function defaulterFeesVersusPaidAction(data){
    return  (dispatch) => {
        defaulterFeesVersusPaid().then(response => {
            if (isLogged(response)) {
                dispatch(getAllConfirmedAction(response.data));	
                dispatch(disableReloadAction());
            } else {
                dispatch(Logout());
            }

            dispatch(Unmutex());
        });
    };
}



export function getAllAction(data) {
    return  (dispatch) => {
        if( data.reportCode === "fees"){
            fees().then(response => {
                if (isLogged(response)) {
                    dispatch(getAllConfirmedAction(response.data));	
                    dispatch(disableReloadAction());
                } else {
                    dispatch(Logout());
                }
            });
        } else{
            dispatch(getAllConfirmedAction([]));	
            dispatch(disableReloadAction());
        }

        dispatch(Unmutex());
        
    };
}

export function getAllConfirmedAction(data) {
    return {
        type: FEES_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getAllFailedAction(data) {
    return {
        type: FEES_FAILED_ACTION,
        payload: data,
    };
}
