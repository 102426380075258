import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import http from './httpService';

export function signUp(name, email, password) {
    //http call
    const postData = {
        name,
        email,
        password,
        returnSecureToken: true,
    };
    return http.post(
       `/api/register`,
        postData,
    ).catch(err =>{
        formatError(err);
        return({ data: {}});
    });;
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return http.get('/sanctum/csrf-cookie').then(response => {
        // Login...
        return http.post(
           `/api/login`,
            postData,
        );
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });;


}

export function formatError(errorResponse) {
    switch (errorResponse.code) {
        case 'EMAIL_EXISTS':
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}


export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }

    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (tokenDetailsString) {
        return true;
    } else {
        return false;
    }
}

export function getToken() {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (tokenDetailsString) {
        let item = JSON.parse(tokenDetailsString);
        if(item.idToken !== undefined){
            return item.idToken;
        }
    }
    return "";
}