import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Outlet } from "react-router-dom";

import routers from '../setting/routes.json'

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";


import Dashboard from "./components/Dashboard/Dashboard";
import Person from "./components/Person/Person";
import PersonView from "./components/Person/View/PersonView";
import PersonNew from "./components/Person/New/PersonNew";
import PersonEdit from "./components/Person/Edit/PersonEdit";
import Company from "./components/Company/Company";
import CompanyView from "./components/Company/View/CompanyView";
import CompanyNew from "./components/Company/New/CompanyNew";
import CompanyEdit from "./components/Company/Edit/CompanyEdit";
import Inscription from "./components/Inscription/Inscription";
import InscriptionView from "./components/Inscription/View/InscriptionView"
import InscriptionNew from "./components/Inscription/New/InscriptionNew"
import InscriptionEdit from "./components/Inscription/Edit/InscriptionEdit"
import Payment from "./components/Payment/Payment";
import PaymentView from "./components/Payment/PaymentView";
import PaymentNew from "./components/Payment/New/PaymentNew";
import Report from "./components/Report/Report";
import User from "./components/User/User";
import Role from "./components/Role/Role";
import Fee from "./components/Fee/Fee";
import RoleCapability from "./components/RoleCapability/RoleCapability";
import RoleCapabilityNew from "./components/RoleCapability/New/RoleCapabilityNew";
import Capability from "./components/Capability/Capability";
import CapabilityNew from "./components/Capability/New/CapabilityNew";
import CapabilityEdit from "./components/Capability/Edit/CapabilityEdit";
import CapabilityView from "./components/Capability/View/CapabilityView";

import UserRole from "./components/UserRole/UserRole";
import FeeTemplate from "./components/FeeTemplate/FeeTemplate";

import Compose from "./components/Notification/Compose/Compose";
import Inbox from "./components/Notification/Inbox/Inbox";
import Read from "./components/Notification/Read/Read";
import Edit from "./components/Notification/Edit/Edit";

import NotificationTemplate from "./components/NotificationTemplate/NotificationTemplate";

import Loader from "./components/Loader/Loader";
import Setting from "./components/Setting/Setting";


import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

import {
  setPath,
} from '../store/actions/CommonActions'

const allroutes = [

  { url: routers.dashboard.admin, component: <Dashboard/> },
  { url: routers.person.admin, component: <Person /> },
  { url: routers.person.view, component: <PersonView /> },
  { url: routers.person.new, component: <PersonNew /> },
  { url: routers.person.edit, component: <PersonEdit /> },
  
  { url: routers.company.admin, component: <Company /> },
  { url: routers.company.view, component: <CompanyView /> },
  { url: routers.company.new, component: <CompanyNew /> },
  { url: routers.company.edit, component: <CompanyEdit /> },
  { url: routers.inscription.admin, component: <Inscription /> },
  { url: routers.inscription.view, component: <InscriptionView /> },
  { url: routers.inscription.new, component: <InscriptionNew /> },
  { url: routers.inscription.edit, component: <InscriptionEdit /> },
  { url: routers.payment.admin, component: <Payment /> },
  { url: routers.payment.view, component: <PaymentView /> },
  { url: routers.payment.new, component: <PaymentNew /> },
  
  { url: routers.report.admin, component: <Report /> },
  { url: routers.user.admin, component: <User /> },
  { url: routers.role.admin, component: <Role /> },
  { url: routers.fee.admin, component: <Fee /> },
  { url: routers.rolecapability.admin, component: <RoleCapability/>},
  { url: routers.rolecapability.new, component: <RoleCapabilityNew/>},
  { url: routers.capability.admin, component: <Capability/>},
  { url: routers.capability.new, component: <CapabilityNew/>},
  { url: routers.capability.view, component: <CapabilityView/>},
  { url: routers.capability.edit, component: <CapabilityEdit/>},
  { url: routers.notification.inbox, component: <Inbox /> },
  { url: routers.notification.compose, component: <Compose /> },
  { url: routers.notification.read, component: <Read /> },
  { url: routers.notification.edit, component: <Edit /> },
  { url: routers.notificationtemplate.admin, component: <NotificationTemplate /> },

  
  { url: routers.feetemplate.admin, component: <FeeTemplate /> },
  { url: routers.userrole.admin, component: <UserRole/>},
  { url: routers.setting.admin, component: <Setting/>}

];

const Markup = () => {
    const dispatch = useDispatch();
	

  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname
    path = path.split('/')
    path = path[path.length - 1]

    if (url.indexOf(path) <= 0) {
      return <Error404 />
    }
  }


  useEffect(() => {
       
    let path = window.location.pathname
    path = path.split('/');
    path = path[path.length - 1];
    dispatch(setPath(path));


  });
  
  return (
    <>
      <Routes>
        <Route path='/page-lock-screen' element={<LockScreen />} />
        <Route path='/page-error-400' element={<Error400 />} />
        <Route path='/page-error-403' element={<Error403 />} />
        <Route path='/page-error-404' element={<Error404 />} />
        <Route path='/page-error-500' element={<Error500 />} />
        <Route path='/page-error-503' element={<Error503 />} />
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
            
          ))}
          <Route path='*' element={<Dashboard />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ScrollToTop />
      <Loader></Loader>
    </>
  );
};


function MainLayout() {
  const sideMenu = useSelector(state => state.sideMenu);
  return (
    <div id="main-wrapper" className={`show ${sideMenu ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
};



export default Markup;
