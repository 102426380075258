const { parseISO, addMonths } = require("date-fns");

export function formatDateToString(dateInput){
 if(typeof dateInput !== "object") return "";
 return dateInput.toLocaleDateString('es-CL')
}

export function formatIsoDateToString(isoDate){
    return parseISO(isoDate).toLocaleDateString('es-CL');
}

export function formatIsoDateToDate(isoDate){
    const year = Number(isoDate.substring(0,4));
    const month = Number(isoDate.substring(5,7)) - 1 ;
    const day = Number(isoDate.substring(8,10));
    const response =new Date(year, month, day)
    return response;
}

export function addMonthsToDate(dateIn, months){
    const response = addMonths(dateIn,months);
    return response;
}

export function formatStatusStringToString(statusInput){

    if(statusInput === "paid") return "Pagado";
    if(statusInput === "pending") return "Pendiente";
    return statusInput;
}