import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import MainPagetitle from '../../../layouts/MainPagetitle'
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import { useLocale } from "../../../../context/Locale";
import InboxSideBar from '../Common/InboxSideBar'
import Select2 from '../../Base/Select2'

import ModalConfirm from '../../Common/Modal/ModalConfirm';

import {
	storeAction,
	updateDataFormNewFormAction
} from '../../../../store/actions/NotificationStoreActions'

import {
    getAllAction
} from '../../../../store/actions/ModelActions'


const Compose = () => {

	const { stateLocale } = useLocale();
	const { strings } = stateLocale;

	const capabilities = useSelector((store) => store.user.capabilities);
	const HasCapability = useSelector((store) => store.user.HasCapability);

	const canEdit = HasCapability(capabilities, 'NOTIFICATION_EDIT');

	const [showComfirmNew, setShowConfirmNew] = useState(false);
	const dataNewForm = useSelector((store) => store.notification.dataNewForm);

	const dispatch = useDispatch();

	const person = useSelector((store) => store.person.data);
	const persons = useSelector((store) => store.person.list);


	useEffect(() => {
		
		dispatch(getAllAction("person", {}));

	}, [dispatch]);


	function onChange(id, value) {
		let dataFormNew = { ...dataNewForm };
		dataFormNew[id] = value;
		dispatch(updateDataFormNewFormAction(dataFormNew));
	}


	function onSaveFormConfirm() {
		setShowConfirmNew(true);
	}

	function onSaveForm() {
		if (dataNewForm["to"] !== undefined && dataNewForm["to"] !== "") {
			dispatch(storeAction(dataNewForm));
			dispatch(updateDataFormNewFormAction({}));
		}

		setShowConfirmNew(false);
	}

	function onCancelForm() {
		setShowConfirmNew(false);
	}

	let personsList = persons.filter(p => p.email !== null && String(p.email).trim() !== "").map(function (item) {
		return {
			"value": item.email,
			"label": item.first_name + " " + item.last_name + " <" + item.email + "> ",
		};
	});

	const personValue = personsList.find(i => i.value === person.id);

	if (!capabilities.map(function (c) { return c.code }).includes('NOTIFICATION_CREATE')) {
		return (<></>);
	} else {
		return (
			<Fragment>
				<MainPagetitle mainTitle={"Notificaciones"} pageTitle={"Crear Notificación"} parentTitle={strings.page.home.title} />
				<div className="container-fluid p-0">
					<NavMenu />
					<div className="row">
						<div className="col-lg-12">
							<div className="card mb-0 h-auto border-0">
								<div className="card-body  py-0">
									<div className="row gx-0">
										<div className="col-xl-2 col-xxl-3 col-lg-3">
											<InboxSideBar></InboxSideBar>
										</div>
										<div className="col-xl-10 col-xxl-9 col-lg-9 ">
											<div className="row">
												<div className="col-lg-12">
													<div className="card">
														<div className="card-header">
															<h4 className="card-title">Crear Notificación</h4>
														</div>
														<div className="card-body">
															<div className="basic-form">
																<form onSubmit={(e) => e.preventDefault()}>
																	<div className="mb-3 row">
																		<label className="col-sm-2 col-form-label">Para:</label>
																		<div className="col-sm-10">
																			<Select2 id="to" placeholder={"Seleccione..."} defaultValue={personValue} options={personsList} onChange={onChange}  ></Select2>
																		</div>
																	</div>
																	<div className="mb-3 row">
																		<label className="col-sm-2 col-form-label">Asunto:</label>
																		<div className="col-sm-10">
																			<input value={dataNewForm.subject} onChange={(e) => { onChange("subject", e.target.value); e.preventDefault() }} type="text" className="form-control bg-transparent" placeholder="Escriba el asunto..." />
																		</div>
																	</div>
																	<div className="mb-3 row">
																		<label className="col-sm-2 col-form-label">Contenido:</label>
																		<div className="col-sm-10">
																			<textarea onChange={(e) => { onChange("content", e.target.value); e.preventDefault() }} id="email-compose-editor" className="textarea_editor form-control bg-transparent" rows="5"
																				placeholder="Escriba el texto ..."
																			>
																				{dataNewForm.content}
																			</textarea>
																		</div>
																	</div>
																</form>
																<div className="text-left mt-4 mb-5">
																	<button onClick={() => onSaveFormConfirm()}
																		className="btn btn-sm btn-primary btn-sl-sm me-2"
																		type="button"
																	>
																		<span className="me-2">
																			<i className="fa fa-paper-plane"></i>
																		</span>
																		Agregar
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<ModalConfirm
					show={showComfirmNew}
					title={"Confirmación"}
					message={"¿Está seguro de agregar la notificación?"}
					cancelButtonText={"No"} confirmButtonText={"Sí"}
					onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
				>
				</ModalConfirm>
			</Fragment>
		);
	}

};

export default Compose;
