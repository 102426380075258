import React, { useState, useRef, useEffect } from 'react';
import MainPagetitle from '../../layouts/MainPagetitle';
import ModalOffcanvas from '../Common/Modal/ModalOffcanvas';
import ModalConfirm from '../Common/Modal/ModalConfirm';
import NavMenu from '../Common/NavMenu/NavMenu.js';

import { useLocale } from "../../../context/Locale";

import {
    getAllAction,
    storeAction,
    openNewFormAction,
    destroyAction,
    updateAction,
    openEditFormAction
} from '../../../store/actions/ModelActions'


import { useSelector, useDispatch } from "react-redux";
import Grid from '../Common/Grid/Grid';
const Role = () => {
    const pageName = "roles"
    const model = "role";
    const capabilities = useSelector((store) => store.user.capabilities);
    const canView = capabilities.map(function (c) { return c.code }).includes('PAGE_ROLE_LIST');
    const path = useSelector((store) => store.common.path);
    const newItem = capabilities.map(function (c) { return c.code }).includes("BTN_ROLE_CREATE");
    const editItem = capabilities.map(function (c) { return c.code }).includes("BTN_ROLE_EDIT");
    const destroyItem = capabilities.map(function (c) { return c.code }).includes("BTN_ROLE_DESTROY");
    const exportData = capabilities.map(function (c) { return c.code }).includes("BTN_ROLE_EXPORT");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [typeForm, setTypeForm] = useState("");
    const [titleForm, setTitleForm] = useState("");
    const [toDestroy, setToDestroy] = useState({});

    const [showComfirmNew, setShowConfirmNew] = useState(false);
    const [showComfirmEdit, setShowConfirmEdit] = useState(false);
    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);

    const dispatch = useDispatch();
    const list = useSelector((store) => store.role.list);

    const gridConf = [
        { label: strings.table.role.header.code, key: "code", className: "", filter: true },
        { label: strings.table.role.header.description, key: "description", className: "", filter: true }
    ]
    const dataNewForm = useSelector((store) => store.role.dataNewForm);
    const dataEditForm = useSelector((store) => store.role.dataEditForm);

    const roleElement = useRef();
    const [step, setStep] = useState("init");

    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction(model, {}));
            setStep("loaded");
        }

    }, [dispatch,step]);


    function onSaveFormConfirm() {
        if (typeForm === "FormNewRole") {
            setShowConfirmNew(true);
        }
        if (typeForm === "FormEditRole") {
            setShowConfirmEdit(true);
        }
    }

    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
        
    }

    function onDestroyForm() {
        dispatch(destroyAction(model, { ...toDestroy, id: toDestroy.code }));
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onSaveForm() {
        if (typeForm === "FormNewRole") {
            dispatch(storeAction(model, dataNewForm));
        }

        if (typeForm === "FormEditRole") {
            dispatch(updateAction(model, dataEditForm));
        }
        setShowConfirmNew(false);
        setShowConfirmEdit(false);
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onCancelForm() {
        setShowConfirmNew(false);
        setShowConfirmEdit(false);
        setShowConfirmDestroy(false);
        setToDestroy({});
    }

    function onClickNewForm() {
        setTypeForm("FormNewRole");
        setTitleForm(strings.form.addRole.title);

        dispatch(openNewFormAction(model,
            {
                "code": dataNewForm.code || "",
                "description": dataNewForm.description || 0
            }
        ));

        roleElement.current.showModal();
    }

    function onClickEditForm(item) {
        setTypeForm("FormEditRole");
        setTitleForm(strings.form.editRole.title);
        roleElement.current.showModal();
        let itemNew = {
            ...item
        }
        dispatch(openEditFormAction(model, itemNew));
    }


    if (path !== pageName && path !== "") {
        return (<></>);
    } else {
        return (
            <>
                <MainPagetitle mainTitle={strings.page.role.title} pageTitle={strings.page.role.title} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {canView &&
                        <div className="row">
                            <div className="col-xl-12">
                                <Grid
                                    list={list}
                                    title={strings.page.role.title}
                                    conf={gridConf}
                                    onClickNewItem={onClickNewForm}
                                    onClickDestroy={onDestroyFornConfirm}
                                    onClickEdit={onClickEditForm}

                                    newItem={newItem}
                                    editItem={editItem}
                                    destroyItem={destroyItem}
                                    exportData={exportData}

                                ></Grid>
                            </div>
                        </div>
                    }
                </div>
                <ModalOffcanvas
                    ref={roleElement}
                    Title={titleForm}
                    TypeForm={typeForm}
                    onSave={onSaveFormConfirm}
                    onCancel={onCancelForm}
                />
                <ModalConfirm
                    show={showComfirmNew}
                    title={"Confirmación"}
                    message={"¿Está seguro de agregar el rol"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>

                <ModalConfirm
                    show={showComfirmEdit}
                    title={"Confirmación"}
                    message={"¿Está seguro de editar el rol "}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>
                <ModalConfirm
                    show={showComfirmDestroy}
                    title={"Confirmación"}
                    message={"¿Está seguro de eliminar el rol"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                >
                </ModalConfirm>
            </>
        );
    }




};

export default Role;