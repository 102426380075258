import Select from "react-select";

const Select2 = (props) => {

    let defaultValue = props.defaultValue? ({...props.defaultValue}): undefined;
    let value = props.value? props.value: undefined
    if(defaultValue === undefined && value!== undefined &&  Array.isArray(props.options)){
        defaultValue = props.options.find(o => String(o.value) === String(value));
    }

    function onChange(value) {
        if(props.onChange !== undefined){
            props.onChange(props.id,value);
        }
    }

    if (props === undefined || props.options === undefined) {
        return (
            <Select className="default-select form-control"/>
        )
    } else if(defaultValue !== undefined) {
        return (
             <Select { ...props}  defaultValue={defaultValue} onChange={(option) => {onChange(option.value)}} style={{
                lineHeight: "40px",
                color: "#7e7e7e",
                paddingLeft: " 15px",
              }} />
        )
    }else {
        return (<Select { ...props} onChange={(option) => {onChange(option.value)}} style={{
            lineHeight: "40px",
            color: "#7e7e7e",
            paddingLeft: " 15px",
          }} />)
    }

}
export default Select2;