import React, { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { SelectionState, IntegratedSelection } from '@devexpress/dx-react-grid';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableSelection,
  Table
} from '@devexpress/dx-react-grid-bootstrap4';

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
//https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/data-formatting/
import MainPagetitle from '../../../layouts/MainPagetitle'
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import InboxSideBar from '../Common/InboxSideBar'

import { getAllAction, setDataAction } from '../../../../store/actions/NotificationListActions'
import { sendAllAction, sendAction } from '../../../../store/actions/NotificationStoreActions.js'
import { useLocale } from "../../../../context/Locale";
import routers from '../../../../setting/routes.json'

import ModalConfirm from '../../Common/Modal/ModalConfirm';



const Inbox = () => {
  const navigate = useNavigate();
  const { stateLocale } = useLocale();
  const { strings } = stateLocale;

  const capabilities = useSelector((store) => store.user.capabilities);
  const HasCapability = useSelector((store) => store.user.HasCapability);
  const canView = HasCapability(capabilities, 'NOTIFICATION_LIST');
  const canEdit = HasCapability(capabilities, 'NOTIFICATION_EDIT');
  const path = useSelector((store) => store.common.path);

  const [showComfirmSendAll, setShowConfirmSendAll] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(true);

  const dispatch = useDispatch();
  const list = useSelector((store) => store.notification.list);
  const [step, setStep] = useState("init");

  const newItem = list.length > 0 && HasCapability(capabilities, 'NOTIFICATION_SEND_ALL');;

  const [columns] = useState([
    { name: 'to', title: 'Para' },
    { name: 'subject', title: 'Asunto' },
    { name: 'id', title: 'Acción' }
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'id' },
  ]);

  const [currencyColumns] = useState(['id']);

  const [selection, setSelection] = useState([]);

  const CurrencyFormatter = ({ value }) => (
    <div>
      {canEdit &&
        <Button onClick={() => onEdit(value)} className="me-2 btn-sm" variant="secondary light">Editar</Button>
      }
      <Button onClick={() => onSelect(value)} className="me-2 btn-sm" variant="primary light">Ver</Button>
    </div>

  );

  const CurrencyTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={CurrencyFormatter}
      {...props}
    />
  );

  useEffect(() => {

    if (step === "loaded") {
      setSendDisabled(selection.length == 0);
    }

    if (step === "init") {
      dispatch(getAllAction({}));
      dispatch(setDataAction({}));
      setStep("loaded");
    }

  }, [dispatch, step, selection]);

  const onSelect = (value) => {
    console.log(value);
    let notificaction = list.find(n => n.id == value)
    dispatch(setDataAction({ ...notificaction }));
    if (step !== "init") {
      navigate(routers.notification.read);
    }

  }

  const onEdit = (value) => {
    console.log(value);
    let notificaction = list.find(n => n.id == value)
    dispatch(setDataAction({ ...notificaction }));
    if (step !== "init") {
      navigate(routers.notification.edit);
    }

  }


  const onClickSendAll = () => {
    setShowConfirmSendAll(true);
  }

  const onSendAll = () => {
    //dispatch(sendAllAction());
    setShowConfirmSendAll(false);
    let IDs = [];

    selection.forEach(function (index) {
      IDs.push(list[index].id)
    });

    dispatch(sendAction(IDs.join(",")));
    setSelection([]);

    setTimeout(() => { dispatch(getAllAction({})); }, 1000);
  }

  const onCancelSendAll = () => {
    setShowConfirmSendAll(false);
  }
  if (path !== "notification-inbox" && path !== "") {
    return (<></>);
  } else {
    return (
      <Fragment>
        <MainPagetitle mainTitle={"Notificaciones"} pageTitle={"Listado de Notificaciones"} parentTitle={strings.page.home.title} />
        <div className="container-fluid ">
          <NavMenu />
          {canView &&
            <div className="row">
              <div className="col-lg-12">
                <div className="card mb-0 h-auto">
                  <div className="card-body py-0 pe-0">
                    <div className="row gx-0">
                      <div className="col-xl-2 col-xxl-3 col-lg-3">
                        <InboxSideBar></InboxSideBar>
                      </div>
                      {(step === "loaded") &&
                        <div className="col-xl-10 col-xxl-9 col-lg-9">
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="card">
                                {(newItem === true && sendDisabled === true) &&
                                  <div className="card-header">
                                    <div>
                                      <Button disabled="disabled" to={"#"} className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas" onClick={() => onClickSendAll()}>Enviar</Button>
                                    </div>
                                  </div>
                                }
                                {(newItem === true && sendDisabled === false) &&
                                  <div className="card-header">
                                    <div>
                                      <Button to={"#"} className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas" onClick={() => onClickSendAll()}>Enviar</Button>
                                    </div>
                                  </div>
                                }
                                <div className="card-body p-0">
                                  <Grid
                                    rows={list}
                                    columns={columns}
                                  >
                                    <CurrencyTypeProvider
                                      for={currencyColumns}
                                    />
                                    <SelectionState
                                      selection={selection}
                                      onSelectionChange={setSelection}
                                    />
                                    <IntegratedSelection />
                                    <VirtualTable
                                      columnExtensions={tableColumnExtensions}
                                    />
                                    <TableHeaderRow />
                                    <TableSelection showSelectAll />
                                  </Grid>

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

            </div>

          }
        </div>
        <ModalConfirm
          show={showComfirmSendAll}
          title={"Confirmación"}
          message={"¿Está seguro(a) de enviar las notificaciones seleccionadas"}
          cancelButtonText={"No"} confirmButtonText={"Sí"}
          onCloseAction={onCancelSendAll} onCancelAction={onCancelSendAll} onConfirmAction={onSendAll}
        >
        </ModalConfirm>
      </Fragment>
    );
  }

};
export default Inbox;