import { SVGICON } from "../../constant/theme";
import routers from '../../../setting/routes.json'

export const MenuList = [
    //Content
    {
        title: 'Cuotas de Socios',
        classsChange: 'menu-title',
        show: false,
        cap: "MENU_INSCRIPTION"
    },
    //Panel
    {
        title: 'Inicio',
        faIcon: <i class="fa fa-house"></i>,
        to: routers.dashboard.admin,
        show: false,
        cap: 'MENU_DASHBOARD'
    },
    //Inscripciones
    {
        title: 'Inscripciones',
        faIcon: <i class="fa fa-chart-column" />,
        to: routers.inscription.admin,
        show: false,
        cap: 'MENU_INSCRIPTION'
    },
    //Regitsrar Pago
    {
        title: 'Registrar Pago',
        faIcon: <i className="fa-solid fa-cash-register" />,
        to: routers.payment.new,
        show: false,
        cap: 'MENU_PAYMENT_CREATE'
    },
    //Pagos
    {
        title: 'Pagos',
        faIcon: <i className="fa-solid fa-cash-register" />,
        to: routers.payment.admin,
        show: false,
        cap: 'MENU_PAYMENT'
    },
    //Cuotas
    {
        title: 'Cuotas',
        faIcon: <i className="fa-solid fa-comment-dollar" />,
        to: routers.fee.admin,
        show: false,
        cap: 'MENU_FEE'
    },
    // Company      
    {
        title: 'Empresas',
        faIcon: <i className="fa-regular fa-building" />,
        to: routers.company.admin,
        show: false,
        cap: 'MENU_COMPANY'
    },
    // Planes de Cuotas
    {
        title: 'Plantillas de Planes de Cuotas',
        faIcon: <i className="fa-solid fa-comments-dollar" />,
        to: routers.feetemplate.admin,
        cap: 'MENU_FEETEMPLATE'
    },
    //Person
    {
        title: 'Personas',
        faIcon: <i className="fa-solid fa-person" />,
        to: routers.person.admin,
        show: false,
        cap: 'MENU_PERSON'
    },
    // Notification
    {
        title: 'Notificationes',
        faIcon: <i className="fa-solid fa-envelope" />,
        to: routers.notification.inbox,
        show: false,
        cap: "MENU_NOTIFICATION"
    },
    // Notification Template
    {
        title: 'Plantillas de Notificationes',
        faIcon: <i className="fa-solid fa-envelope" />,
        to: routers.notificationtemplate.admin,
        show: false,
        cap: "MENU_NOTIFICATIONSTEMPLATE"
    },
    // Report      
    {
        title: 'Reportes',
        faIcon: <i class="fa-regular fa-file" />,
        to: routers.report.admin,
        show: false,
        cap: 'MENU_REPORT'
    },
    //Admin
    {
        title: 'Admimistración',
        classsChange: 'mm-collapse',
        faIcon: <i className="fa-solid fa-sliders" />,
        cap: 'MENU_ADMIN',
        content: [
            {
                title: 'Usuarios',
                to: routers.user.admin,
                cap: 'USER_LIST'
            },
            {
                title: 'Roles',
                to: routers.role.admin,
                cap: 'ROLE_LIST'
            },
            {
                title: 'Usuario y Roles',
                to: routers.userrole.admin,
                cap: 'USERROLE_LIST'
            },
            {
                title: 'Roles y Capacidades',
                to: routers.rolecapability.admin,
                cap: 'ROLECAPABILITY_LIST'
            },
            {
                title: 'Capacidades',
                to: routers.capability.admin,
                cap: 'CAPABILITY_LIST'
            },
            {
                title: 'Configuraciones',
                to: routers.setting.admin,
                cap: 'SETTING_LIST'
            }

        ]
    },


]