
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import MainPagetitle from '../../../layouts/MainPagetitle';
import ModalConfirm from '../../Common/Modal/ModalConfirm';
import { Navigate } from "react-router-dom";

import NavMenu from '../../Common/NavMenu/NavMenu.js';

import {
    storeAction,
    getAllAction
} from '../../../../store/actions/ModelActions.js'

import Select2 from '../../Base/Select2.js'
import Select from '../../Base/Select.js'
import DateInput from '../../Base/DateInput.js'
import { formatIsoDateToDate } from '../../../../tools/fomatter.js'
import routers from '../../../../setting/routes.json'
import { formatIsoDateToString } from '../../../../tools/fomatter.js'

const PaymentNew = () => {
    const dispatch = useDispatch();

    const pageName = "payment-new"
    const model = "payment"


    const path = useSelector((store) => store.common.path);
    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const inscriptions = useSelector((store) => store.inscription.list);
    
    const fees = useSelector((store) => store.fee.list);


    const canView = HasCapability(capabilities, 'PAGE_PAYMENT_CREATE');
    const canEditAmount  = HasCapability(capabilities, 'PAYMENT_AMOUNT_EDIT');

    const [showComfirmNew, setShowConfirmNew] = useState(false);
    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
    const [toPayment, setToPayment] = useState(false);
    const [toSave, setToSave] = useState(false);

    const inscriptionsFiltered = inscriptions.filter(function(item){
        const counts = fees.filter(f => f.fee_balance > 0 && f.inscription_id === item.id).length;
        return counts > 0;
    });


    const buildOptionsInscriptions = (list) => {
        return list.map((item) => { return { "label": item.company_name + " ( Rut " + item.company_legal_id + ")", "value": item.id } })
    }

    const buildOptionsFees = (list) => {

        if (Array.isArray(list)) {

            if (list.length > 0) {
                const listOrdered = [...list].sort((a, b) => a.fee_sequence - b.fee_sequence);
                return listOrdered.map((item) => { return { "label": formatIsoDateToString(item.fee_payment_date) + " ( pendiente $ " + item.fee_balance + ")", "value": item.fee_id } });
            } else {
                return [];
            }


        } else {
            return [];
        }

    }


    const getValue = (id, defaultValue) => { return value ? (value[id] ? value[id] : defaultValue) : defaultValue }

    const getOptionValue = (list, value) => {
        return list.find(i => String(i.value) === String(value))
    }


    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction("inscription", {}));
            dispatch(getAllAction("fee", {}));

            setValue({
                "amount": 0,
                "fee_balance": 0,
                "payment_date": new Date(),
                "comment": "",
                "inscription_id": 0,
                fee_id_selected: 0,
            });
            setToSave(false);

        }

        if (step !== "edit") {
            setStep("edit");
        }

    }, [dispatch, step]);

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;

        if (id === "inscription_id_selected") {

            if (data !== "Seleccione") {
                const itemNew = inscriptions.find(c => String(c.id) === String(data));
                if (itemNew !== undefined) {
                    newValue = {
                        ...newValue,
                        inscription_id: itemNew.id,
                        amount: 0,
                        fee_balance: 0,
                        payment_date: new Date(),
                        comment: ""
                    };
                }
                setToSave(false);
            } else {
                newValue = {
                    ...newValue,
                    inscription_id: 0,
                    amount: 0,
                    fee_balance: 0,
                    payment_date: new Date(),
                    fee_id_selected: 0,
                    comment: ""
                };

                setToSave(false);
            }


        } else if (id === "fee_id_selected") {
            const itemNew = fees.find(f => String(f.fee_id) === String(data));

            if (itemNew !== undefined) {
                setToSave(true);
                newValue = {
                    ...newValue,
                    fee_id: itemNew.fee_id,
                    amount: itemNew.fee_amount,
                    fee_balance: itemNew.fee_balance,
                    payment_date: formatIsoDateToDate(itemNew.fee_payment_date)
                };
            } else {
                newValue = {
                    ...newValue,
                    amount: 0,
                    fee_balance: 0,
                    payment_date: new Date(),
                    fee_id_selected: 0,
                    comment: ""
                };

                setToSave(false);

            }
        }
        setValue(newValue);

    }

    function onSaveFormConfirm() {
        setShowConfirmNew(true);
    }

    function onSaveForm() {
        let request = {
            "amount": value.fee_balance,
            "payment_date": value.payment_date,
            "comment": value.comment,
            "inscription_id": value.inscription_id,
            "fee_id": value.fee_id
        }
        dispatch(storeAction(model, request));
        setShowConfirmNew(false);
        //setToPayment(true);
        dispatch(getAllAction("fee", {}));

        setValue({
            "amount": 0,
            "fee_balance": 0,
            "payment_date": new Date(),
            "comment": "",
            "inscription_id": 0,
        });
        setToSave(false);
    }

    function onCancelForm() {
        setShowConfirmNew(false);
    }

    if (path !== pageName && path !== "") {
        return (<></>);
    } else if (!canView) {
        return (<></>);
    } else {
        const itemList = buildOptionsFees(fees.filter(i => (value.inscription_id === undefined && value.inscription_id !== 0) ? false : (i.fee_balance > 0 && String(i.inscription_id) === String(value.inscription_id))));
        const inscriptionId = getValue("fee_id_selected", 0);

        return (
            <>
                {toPayment && (<Navigate to={routers.payment.admin} replace={true} />)}
                <MainPagetitle mainTitle={"Pagos"} pageTitle={"Registar un Pago"} parentTitle={"Inicio"} />
                {canView &&
                    <div className="container-fluid">
                        <NavMenu />
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Datos</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="basic-form">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <div className="row">
                                                    <div className="col-xl-3 mb-3">
                                                        <label htmlFor={"inscription_id_selected"} className="form-label">Inscripción</label>

                                             


                                                        <Select2
                                                            id={"inscription_id_selected"}
                                                            onChange={onChange}
                                                            defaultValue={buildOptionsInscriptions(inscriptionsFiltered).find(i => String(i.code) === getValue("inscription_id_selected", 0))}
                                                            options={buildOptionsInscriptions(inscriptionsFiltered)}
                                                        ></Select2>
                                                    </div>
                                                    <div className="col-xl-3 mb-3">
                                                        <label htmlFor={"fee_id_selected"} className="form-label">Cuota Pendiente</label>


                                                        <Select
                                                            value={getValue("fee_id_selected", 0)}
                                                            onChange={onChange} data={itemList.map(
                                                                function (item) {
                                                                    return { value: item.value, text: item.label }
                                                                }
                                                            )} text={"Seleccione"} id={"fee_id_selected"} ></Select>
                                                    </div>
                                                    {(inscriptionId === 0) &&
                                                        <div className="col-xl-3 mb-3">
                                                            <label htmlFor={"fee_balance"} className="form-label">Monto Pendiente<span className="text-danger">*</span></label>
                                                            <input disabled={true} type="text" value={""} className="form-control" />
                                                        </div>
                                                    }
                                                    {(inscriptionId !== 0) &&
                                                        <div className="col-xl-3 mb-3">
                                                            <label htmlFor={"fee_balance"} className="form-label">Monto<span className="text-danger">*</span></label>
                                                            {!canEditAmount && 
                                                            <input disabled={true} id={"fee_balance"} type="number" value={getValue("fee_balance", 1)} min={1} className="form-control" />
                                                            }
                                                            {canEditAmount && 
                                                            <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }}  id={"fee_balance"} type="number" value={getValue("fee_balance", 1)} min={1} className="form-control" />
                                                            }
                                                        </div>
                                                    }
                                                    {(inscriptionId === 0) &&
                                                        <div className="col-xl-3 mb-3">
                                                            <div className="row" ><label htmlFor={"payment_date"} className="form-label">Fecha<span className="text-danger">*</span></label></div>
                                                            <input disabled={true} type="text" value={""} className="form-control" />
                                                        </div>
                                                    }
                                                    {(inscriptionId !== 0) &&
                                                        <div className="col-xl-3 mb-3">
                                                            <div className="row" ><label htmlFor={"payment_date"} className="form-label">Fecha<span className="text-danger">*</span></label></div>
                                                            <div className="row" ><DateInput onChange={onChange} id={"payment_date"} selected={getValue("payment_date", new Date())} ></DateInput></div>
                                                        </div>
                                                    }
                                                    <div className="col-xl-3 mb-3">
                                                        <label htmlFor={"comment"} className="form-label">Comentario<span className="text-danger">*</span></label>
                                                        <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"comment"} type="text" value={getValue("comment", "")} className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-xl-3 mb-3">
                                                        {toSave &&
                                                            <Button onClick={() => onSaveFormConfirm()} className="btn-sm" variant="primary"> {"Enviar"}</Button>
                                                        }
                                                        {!toSave &&
                                                            <Button disabled={true} className="btn-sm" variant="primary"> {"Enviar"}</Button>
                                                        }
                                                    </div>
                                                </div>


                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <ModalConfirm
                            show={showComfirmNew}
                            title={"Confirmación"}
                            message={"¿Está seguro de registrar el pago"}
                            cancelButtonText={"No"} confirmButtonText={"Sí"}
                            onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                        >
                        </ModalConfirm>
                    </div>
                }

            </>);
    }
}

export default PaymentNew;