
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import MainPagetitle from '../../../layouts/MainPagetitle';
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import { useLocale } from "../../../../context/Locale";
import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'
import { formatIsoDateToString } from '../../../../tools/fomatter.js'
import Extension from '../../Common/Extension/Extension'

const InscriptionView = (props) => {
    const data = useSelector((store) => store.inscription.dataEditForm);
    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);

    const canViewExtensions = HasCapability(capabilities, "EXTENSION_LIST");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const editItem = HasCapability(capabilities, "INSCRIPTION_EDIT");

    const [toEditInscription, setToEditInscription] = useState(false);


    const values = {
        "id": data.id || "",
        "company_name": data.company_name || "",
        "company_legal_id": data.company_legal_id || "",
        "company_representative_legal_id": data.company_representative_legal_id || "",
        "zone": data.zone || "",
        "initial_fee": data.initial_fee || 0,
        "amount": data.amount || 1,
        "amount_fees": data.amount_fees || 1,
        "start_date": formatIsoDateToString(data.start_date || new Date()),
        "payment_day": data.payment_day || 1,
        "comment": data.comment || "",
        "company_branch_number": data.company_branch_number
    }


    const fieldsInscription = [
        { "name": "zone", "label": "Zona", "type": "text", "required": false },
        { "name": "initial_fee", "label": "Cuota Inicial", "type": "text", "required": false },
        { "name": "amount", "label": "Monto Mensual", "type": "text", "required": false },
        { "name": "amount_fees", "label": "Cantidad de Cuotas", "type": "text", "required": false },
        { "name": "payment_day", "label": "Día de Pago", "type": "text", "required": false },
        { "name": "start_date", "label": "Fecha de inicio", "type": "text", "required": false },
        { "name": "comment", "label": "Comentario", "type": "text", "required": false }
    ]

    const fieldsCompany = [
        { "name": "company_legal_id", "label": "Rut Empresa", "type": "text", "required": false, disabled: true },
        { "name": "company_name", "label": "Empresa", "type": "text", "required": false ,disabled: true },
        { "name": "company_branch_number", "label": "Número de Locales", "type": "number", "required": false,disabled: true  }
    ]



    if (props === undefined || toEditInscription || values.id === undefined) {
        return (<> {toEditInscription && (<Navigate to={routers.inscription.edit} replace={true} />)}</>)
    } else if (!HasCapability(capabilities, 'INSCRIPTION_GET')) {
        return (<></>);
    } else {

        return (
            <>
                <MainPagetitle mainTitle={"Inscripción"} pageTitle={"Ver Inscripción"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">

                    <NavMenu />
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Empresa</h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="row">
                                                {fieldsCompany.map((item, index) => (

                                                    <div key={index} className="col-xl-3 mb-3">
                                                        <label htmlFor={item.name} className="form-label">{item.label} {item.required && <span className="text-danger">*</span>}</label>
                                                        <input disabled={true} id={item.name} type="text" value={values[item.name]} className="form-control" />
                                                    </div>

                                                ))}

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">

                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Inscripción</h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="row">
                                                {fieldsInscription.map((item, index) => (

                                                    <div key={index} className="col-xl-3 mb-3">
                                                        <label htmlFor={item.name} className="form-label">{item.label} {item.required && <span className="text-danger">*</span>}</label>
                                                        <input disabled={true} id={item.name} type="text" value={values[item.name]} className="form-control" />
                                                    </div>

                                                ))}

                                            </div>
                                            {editItem &&
                                                <div className="mb-3">
                                                    <button onClick={() => setToEditInscription(true)} className="btn btn-sm btn-primary me-1">{strings.form.common.buttonEdit}</button>
                                                </div>
                                            }

                                        </form>
                                    </div>
                                </div>

                            </div>



                        </div>

                    </div>
                    {canViewExtensions &&
                        <Extension data={data} ></Extension>
                    }


                </div>

            </>
        )
    }

};

export default InscriptionView;
