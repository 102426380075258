import React from 'react';
import { FaAngleDown,FaAngleUp } from "react-icons/fa6";

export const GridHeader = (props) => {

    const orderBy = props ? (props.orderBy ? props.orderBy : "") : "";
    const orderByDirection = props ? (props.orderByDirection ? props.orderByDirection : "") : "";

    function onClickHeader(colName){
        if(props.onClickHeader !== undefined){
            props.onClickHeader(colName);
        }
    }

    if (props === undefined || props.headers === undefined) {
        return (<thead><tr></tr></thead>)
    } else {
        return (
            <thead>
                <tr>
                    {props.headers.map((item, index) => (
                        
                        <th onClick={() => onClickHeader(item.key)} key={index}> {item.label} {(orderBy === item.key) ? ( orderByDirection ==="asc"?  <FaAngleUp/>: <FaAngleDown /> ) : <><FaAngleDown /><FaAngleUp/></>   } </th>

                    ))}
                    <th></th>
                </tr>
            </thead>
        )
    }
}

export default GridHeader;