
import FormInput from '../../Form/FormInput.js'
import { useLocale } from "../../../../context/Locale/index.js";

const InputUserName = (props) => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    return (
        <FormInput { ...props}   textlabel={strings.form.addUser.labelName} htmlFor="name" type="text" id="name" placeholder=""   ></FormInput>
    )
}
export default InputUserName;