const Select = (props) => {

    function onChange(value) {
        props.onChange(props.id,value);
    }

    if (props === undefined || props.data === undefined) {
        return (
            <select className="default-select form-control"></select>
        )
    } else {

        return (
            <select { ...props}  onChange={(e) => {onChange(e.target.value); e.preventDefault()}} className="default-select form-control">
                <option key="" data-display="Select">{props.text}</option>
                {props.data.map((item, index) => (
                    <option key={index} data-index={index} selected={props.value ? (props.value === item.value) : false} value={item.value}>{item.text}</option>
                ))}
            </select>
        )
    }

}
export default Select;