import React, { useState, useRef,useEffect } from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import ModalConfirm from '../Common/Modal/ModalConfirm';
import NavMenu from '../Common/NavMenu/NavMenu.js';

import ModalEditFeeTemplate from './Modal/ModalEditFeeTemplate'
import ModalNewFeeTemplate from './Modal/ModalNewFeeTemplate'
import { useLocale } from "../../../context/Locale";

import { 
    openNewFormAction,
    destroyAction,
    openEditFormAction,
    getAllAction
} from '../../../store/actions/ModelActions'

import { useSelector, useDispatch } from "react-redux";
import Grid from '../Common/Grid/Grid';



const FeeTemplate = () => {
    const pageName = "feetemplates"
    const model="feetemplate";

    const capabilities = useSelector((store) => store.user.capabilities);
    const canView = capabilities.map(function (c) { return c.code }).includes('PAGE_FEETEMPLATE_LIST');
    const path = useSelector((store) => store.common.path);

    const newItem = capabilities.map(function (c) { return c.code }).includes("BTN_FEETEMPLATE_CREATE");
    const editItem = capabilities.map(function (c) { return c.code }).includes("BTN_FEETEMPLATE_EDIT");
    const destroyItem = capabilities.map(function (c) { return c.code }).includes("BTN_FEETEMPLATE_DESTROY");
    const exportData = capabilities.map(function (c) { return c.code }).includes("BTN_FEETEMPLATE_EXPORT");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [titleForm, setTitleForm] = useState("");
    const [toDestroy, setToDestroy] = useState({});

    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);
    const [step, setStep] = useState("init");

    const dispatch = useDispatch();
    const list = useSelector((store) => store.feetemplate.list);

    const gridConf = [
        { label: strings.table.feetemplate.header.name, key: "name", className: "", filter: true },
        { label: strings.table.feetemplate.header.amount, key: "amount", className: "", filter: true },
        { label: "Cuota Inicial", key: "initial_fee", className: "", filter: true },
        { label: strings.table.feetemplate.header.amount_fees, key: "amount_fees", className: "", filter: true },
        { label: strings.table.feetemplate.header.payment_day, key: "payment_day", className: "", filter: true },
        { label: strings.table.feetemplate.header.comment, key: "comment", filter: true },

    ]

    const feetemplateEditElement = useRef();
    const feetemplateNewElement = useRef();

 
    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction(model, {}));
            setStep("loaded");
        }

    }, [dispatch,step]);


    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
    }

    function onDestroyForm() {
        dispatch(destroyAction(model,toDestroy));
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onSaveForm() {
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onCancelForm() {
        setShowConfirmDestroy(false);
    }


    function onClickNewForm() {
        setTitleForm(strings.form.addFeeTemplate.title);

        dispatch(openNewFormAction(model,{}));

        feetemplateNewElement.current.showModal();
    }


    function onClickEditForm(item) {
        setTitleForm(strings.form.editFeeTemplate.title);
        feetemplateEditElement.current.showModal();
        let itemNew = { ...item }
        dispatch(openEditFormAction(model,itemNew));

    }

    if (path !== pageName && path !== "") {
        return (<></>);
    } else {
        return (
            <>
                <MainPagetitle mainTitle={strings.page.feetemplate.title} pageTitle={strings.page.feetemplate.title} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu/>
                    {canView && 
                    <div className="row">
                        <div className="col-xl-12">
                            <Grid
                                list={list}
                                title={strings.page.feetemplate.title}
                                conf={gridConf}
                                // filterRecords={filterFeeTemplate}
                                onClickNewItem={onClickNewForm}
                                buttonAddLabel={"+ Nueva Plantilla de Plan de Cuota"}
                                onClickDestroy={onDestroyFornConfirm}
                                onClickEdit={onClickEditForm}

                                newItem={newItem}
                                editItem={editItem}
                                destroyItem={destroyItem}
                                exportData={exportData}

                            ></Grid>
                        </div>
                    </div>
                    }
                </div>

                <ModalEditFeeTemplate
                    ref={feetemplateEditElement}
                    Title={titleForm}
                    onSave={onSaveForm}
                />
                <ModalNewFeeTemplate
                    ref={feetemplateNewElement}
                    Title={titleForm}
                    onSave={onSaveForm}
                />
                <ModalConfirm
                    show={showComfirmDestroy}
                    title={"Confirmación"}
                    message={"¿Está seguro de eliminar este plan de cuotas"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                >
                </ModalConfirm>
            </>
        );
    }





};

export default FeeTemplate;