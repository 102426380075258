import {
    RELOAD_ENABLE_ACTION, RELOAD_DISABLE_ACTION,
    RELOADCAPABILITY_ENABLE_ACTION, RELOADCAPABILITY_DISABLE_ACTION
} from '../actions/UserActions';

import {
    SET_TIMER_ACTION,
    SET_GETALLINPROGRESS_ACTION,
    GETALL_CONFIRMED_ACTION,
    GETALL_FAILED_ACTION,
    GETCAPABILITY_CONFIRMED_ACTION,
    GETCAPABILITY_FAILED_ACTION,
    SET_DATA_ACTION

} from '../actions/UserListActions';

import {
    OPEN_FORMNEW_ACTION,
    UPDATE_DATAFORMNEW_ACTION,
    STORE_CONFIRMED_ACTION,
    STORE_FAILED_ACTION

} from '../actions/UserStoreActions';


import {
    OPEN_FORMEDIT_ACTION,
    UPDATE_DATAFORMEDIT_ACTION,
    UPDATE_CONFIRMED_ACTION,
    UPDATE_FAILED_ACTION

} from '../actions/UserEditActions';


import {
    DESTROY_CONFIRMED_ACTION,
    DESTROY_FAILED_ACTION

} from '../actions/UserDestroyActions';


const initialState = {
    updated: (new Date()).getTime(),
    dispathGetAll: true,
    dispathGetAllInprogress: false,

    list: [],
    capabilities: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    dataNewForm: {},
    dataEditForm: {},
    data: {},
    reload: true,
    reloadCapabilities: true,
    HasCapability : (actions,code) => {
        return actions.map(function (c) { return c.code }).includes(code);
    }
};

export function UserReducer(state = initialState, action) {

    if (action.type === STORE_FAILED_ACTION) {
        return {
            ...state,
            dataNewForm: {},
            reload: true,
        };
    }

    if (action.type === UPDATE_FAILED_ACTION) {
        return {
            ...state,
            dataEditForm: {},
            reload: true,
        };
    }


    if (action.type === DESTROY_FAILED_ACTION) {
        return {
            ...state,
            dataNewForm: {},
            reload: true,
        };
    }

    if (action.type === GETALL_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: action.payload,
            dispathGetAllInprogress: false,
            successMessage: '',
            showLoading: false,
            dispathGetAll: false,
        };
    }

    if (action.type === GETCAPABILITY_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: action.payload,
            reloadCapabilities: false
        };
    }

    if (action.type === GETALL_CONFIRMED_ACTION) {
        return {
            ...state,
            list: action.payload,
            dispathGetAllInprogress: false,
            errorMessage: '',
            successMessage: 'GetAll Successfully Completed',
            showLoading: false,
            reload: false,
        };
    }


    if (action.type === GETCAPABILITY_CONFIRMED_ACTION) {
        return {
            ...state,
            capabilities: action.payload,
            reloadCapabilities: false
        };
    }


    if (action.type === STORE_CONFIRMED_ACTION) {
        return {
            ...state,
            dataNewForm: action.payload,
            reload: true,
        };
    }

    
    

    if (action.type === DESTROY_CONFIRMED_ACTION) {
        return {
            ...state,
            dataNewForm: {},
            reload: true,
        };
    }



    if (action.type === OPEN_FORMEDIT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            dataEditForm: action.payload,
        };
    }

    if (action.type === UPDATE_CONFIRMED_ACTION) {
        return {
            ...state,
            dataEditForm: action.payload,
            reload: true,
        };
    }
    

    if (action.type === UPDATE_DATAFORMEDIT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            dataEditForm: action.payload,
        };
    }

    if (action.type === RELOAD_ENABLE_ACTION) {
        return {
            ...state,
            reload: true
        };
    }

    if (action.type === RELOAD_DISABLE_ACTION) {
        return {
            ...state,
            reload: false
        };
    }

    if (action.type === RELOADCAPABILITY_ENABLE_ACTION) {
        return {
            ...state,
            reloadCapabilities: true
        };
    }

    if (action.type === RELOADCAPABILITY_DISABLE_ACTION) {
        return {
            ...state,
            reloadCapabilities: false
        };
    }

    if (action.type === SET_TIMER_ACTION) {
        return {
            ...state,
            updated: action.payload
        };
    }

    if (action.type === SET_DATA_ACTION) {
        return {
            ...state,
            data: action.payload
        };
    }


    if (action.type === SET_GETALLINPROGRESS_ACTION) {
        return {
            ...state,
            dispathGetAllInprogress: action.payload
        };
    }

    if (action.type === OPEN_FORMNEW_ACTION) {
        return {
            ...state,
            errorMessage: '',
            dataNewForm: action.payload,
        };
    }

    if (action.type === UPDATE_DATAFORMNEW_ACTION) {
        return {
            ...state,
            errorMessage: '',
            dataNewForm: action.payload,
        };
    }



    return state;
}