import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";

import MainPagetitle from '../../layouts/MainPagetitle';
import ModalConfirm from '../Common/Modal/ModalConfirm';
import NavMenu from '../Common/NavMenu/NavMenu.js';

import { useLocale } from "../../../context/Locale";
import { useSelector, useDispatch } from "react-redux";

import {
    destroyAction,
    openEditFormAction,
    enableReloadAction,
    getAllAction,
    setDataAction
} from '../../../store/actions/ModelActions'


import Grid from '../Common/Grid/Grid';
import routers from '../../../setting/routes.json'

const Company = () => {
    const dispatch = useDispatch();

    const pageName = "companies"
    const model = "company";

    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);


    const canView = HasCapability(capabilities,'PAGE_COMPANY_LIST');
    const newItem = HasCapability(capabilities,"BTN_COMPANY_CREATE");
    const viewItem = HasCapability(capabilities,"BTN_COMPANY_GET");
    const editItem = false;//HasCapability(capabilities,"BTN_COMPANY_EDIT");
    const destroyItem = HasCapability(capabilities,"BTN_COMPANY_DESTROY");
    const exportData = HasCapability(capabilities,"BTN_COMPANY_EXPORT");

    const path = useSelector((store) => store.common.path);
    const list = useSelector((store) => store.company.list);

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [toDestroy, setToDestroy] = useState({});
    const [toEdit, setToEdit] = useState(false);
    const [toView, setToView] = useState(false);
    const [toNew, setToNew] = useState(false);
    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);
    const [step, setStep] = useState("init");

    
    
        
    const gridConf = [
        { label: strings.table.company.header.legal_id, key: "legal_id", className: "", filter: true },
        { label: strings.table.company.header.name, key: "name", className: "", filter: true },
        { label: strings.table.company.header.representative_legal_id, key: "person_legal_id", className: "", filter: true },
        { label: strings.table.company.header.category, key: "category", className: "", filter: true },
        { label: strings.table.company.header.size, key: "size", className: "", filter: true },
        { label: strings.table.company.header.sales_level, key: "sales_level", className: "", filter: true },
        { label: "Número de Locales", key: "branch_number", className: "", filter: true },
        
    ]

    useEffect(() => {

        if (step === "init") {
           dispatch(getAllAction(model,{}));
           setStep("loaded");
        }

    }, [dispatch,step]);

    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
    }

    function onDestroyForm() {
        dispatch(destroyAction(model,toDestroy));
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }


    function onCancelForm() {
        setShowConfirmDestroy(false);
    }

    function onClickEditForm(item) {
        let itemNew = { ...item }
        dispatch(enableReloadAction("address"));
        dispatch(openEditFormAction(model,itemNew));
        setToEdit(true);
    }

    function onClickNewForm() {
        setToNew(true);
    }

    function onClickViewForm(item) {
        dispatch(enableReloadAction("address"));
        dispatch(setDataAction(model,{ ...item }));
        setToView(true);

    }

    if (path !== pageName && path !== "") {
        return (<></>);
    } else {

        return (
            <>
                {toView && ( <Navigate to={routers.company.view} replace={true} />)}
                {toEdit && ( <Navigate to={routers.company.edit} replace={true} />)}
                {toNew && ( <Navigate to={routers.company.new} replace={true} />)}
                <MainPagetitle mainTitle={strings.page.company.title} pageTitle={"Listado de Empresas"} parentTitle={strings.page.home.title} />

                <div className="container-fluid">
                    <NavMenu/>
                    { canView && 
                    <div className="row">
                        <div className="col-xl-12">
                            <Grid
                                list={list}
                                title={"Listado de Empresas"}
                                conf={gridConf}
                                
                                onClickNewItem={onClickNewForm}
                                onClickDestroy={onDestroyFornConfirm}
                                onClickView={onClickViewForm}
                                onClickEdit={onClickEditForm}

                                newItem={newItem}
                                editItem={editItem}
                                viewItem={viewItem}
                                destroyItem={destroyItem}
                                exportData={exportData}

                            ></Grid>
                        </div>
                    </div>
                    }
                </div>
                <ModalConfirm
                    show={showComfirmDestroy}
                    title={"Confirmación"}
                    message={"¿Está seguro de eliminar esta empresa"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                >
                </ModalConfirm>
            </>
        );

    }
};

export default Company;