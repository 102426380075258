import React, { useState, useEffect } from 'react';
import { useLocale } from "../../../../../context/Locale";
import { GenderOptions } from '../../../../../tools/gender.js'
import ModalConfirm from '../../../Common/Modal/ModalConfirm';
import { updateAction , getAction} from '../../../../../store/actions/ModelActions.js'
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup } from "react-bootstrap";
import Select from '../../../Base/Select.js'

const FormRepresentativeEdit = (props) => {

    const dispatch = useDispatch();
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const model = "person";

    const data = useSelector((store) => store.person.data);

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
    const [showComfirm, setShowConfirm] = useState(false);

    useEffect(() => {

        if (step === "init") {
            if(props.data !== undefined && props.data.id !== undefined){
                dispatch(getAction(model,{
                    id : props.data.id
                }));
            }
            setStep("edit");

        }else{
            setValue({...data});
        }

    }, [step, data]);


    const getValue = (id, defaultValue) => { 
        return value[id] ? value[id] : defaultValue 
    }

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;;
        setValue(newValue);
    }

    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {

        const request = {
            "id": value.id,
            "first_name": value.first_name,
            "last_name": value.last_name,
            "legal_id": value.legal_id,
            "gender": value.gender,
            "email": value.email,
            "phone": value.phone
        }

        dispatch(updateAction(model, request));
        setShowConfirm(false);

        if (props.onSave !== undefined) {
            props.onSave(request);
        }

    }

    function onCancelForm() {
        setShowConfirm(false);
        if (props.onCancel !== undefined) {
            props.onCancel();
        }
    }


    if (data.id === undefined) {
        return (<></>);
    } else {
        return (<>
            <div className="row">
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"legal_id"} className="form-label">{strings.form.addCompany.labelRepresentative}<span className="text-danger">*</span></label>
                    <input value={getValue("legal_id", "")} disabled={true} htmlFor="legal_id" type="text" id={"legal_id"} placeholder="" className="form-control"   ></input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"first_name"} className="form-label">Nombres<span className="text-danger">*</span></label>
                    <input value={getValue("first_name", "")} onChange={(e) => { onChange("first_name", e.target.value); e.preventDefault() }} htmlFor="first_name" type="text" id={"first_name"} placeholder="" className="form-control"  ></input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"last_name"} className="form-label">Apellidos<span className="text-danger">*</span></label>
                    <input value={getValue("last_name", "")} onChange={(e) => { onChange("last_name", e.target.value); e.preventDefault() }} htmlFor="last_name" type="text" id={"last_name"} placeholder="" className="form-control"   ></input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"gender"} className="form-label">Género<span className="text-danger">*</span></label>
                    <Select
                        value={getValue("gender", "")}
                        onChange={onChange} data={GenderOptions.map(
                            function (item) {
                                return { value: item.value, text: item.label }
                            }
                        )} text={"Seleccione"} id={"gender"} ></Select>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"email"} className="form-label">Correo<span className="text-danger">*</span></label>
                    <input value={getValue("email", "")} onChange={(e) => { onChange("email", e.target.value); e.preventDefault() }} htmlFor="email" type="text" id={"email"} placeholder="" className="form-control"   ></input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"phone"} className="form-label">Teléfono<span className="text-danger">*</span></label>
                    <input value={getValue("phone", "")} onChange={(e) => { onChange("phone", e.target.value); e.preventDefault() }} htmlFor="phone" type="text" id={"phone"} placeholder="" className="form-control"   ></input>
                </div>

            </div>
            <div className="row">
                <div className="col-xl-3 mb-3">
                    <ButtonGroup>
                        <Button onClick={() => onSaveFormConfirm()} className="btn-sm" variant="primary"> {"Guardar"}</Button>
                        <Button onClick={() => onCancelForm()} className="btn-sm" variant="secondary"> {"Cancelar"}</Button>
                    </ButtonGroup>
                </div>
            </div>
            <ModalConfirm
                show={showComfirm}
                title={"Confirmación"}
                message={"¿Está seguro de guardar los cambios realizados?"}
                cancelButtonText={"No"} confirmButtonText={"Sí"}
                onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
            >
            </ModalConfirm>
        </>);
    }


}

export default FormRepresentativeEdit;