import React, { useState, useEffect } from 'react';
import MainPagetitle from '../../layouts/MainPagetitle';
import NavMenu from '../Common/NavMenu/NavMenu.js';
import { formatIsoDateToString, formatStatusStringToString } from '../../../tools/fomatter'
import { useLocale } from "../../../context/Locale";
import { getAllAction } from '../../../store/actions/ModelActions'
import { useSelector, useDispatch } from "react-redux";
import Grid from '../Common/Grid/Grid';


const Fee = () => {

    const pageName = "fees"
    const model = "fee";
    const capabilities = useSelector((store) => store.user.capabilities);
    const canView = capabilities.map(function (c) { return c.code }).includes('FEE_LIST');
    const path = useSelector((store) => store.common.path);

    const exportData = capabilities.map(function (c) { return c.code }).includes("FEE_EXPORT");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const [step, setStep] = useState("init");


    const dispatch = useDispatch();
    const list = useSelector((store) => store.fee.list);

    const gridConf = [
        { label: strings.table.fee.header.company_legal_id, key: "company_legal_id", filter: true },
        { label: strings.table.fee.header.company_name, key: "company_name", filter: true },
        { label: strings.table.fee.header.inscription_payment_day, key: "inscription_payment_day", filter: false },
        { label: strings.table.fee.header.fee_amount, key: "fee_amount", filter: false },
        { label: strings.table.fee.header.fee_sequence, key: "fee_sequence", filter: false },
        { label: strings.table.fee.header.fee_amount_paid, key: "fee_amount_paid" },
        { label: strings.table.fee.header.fee_payment_date, key: "fee_payment_date", "formatter": formatIsoDateToString },
        { label: strings.table.fee.header.fee_balance, key: "fee_balance" },
        { label: strings.table.fee.header.fee_status, key: "fee_status", "formatter": formatStatusStringToString, filter: true, options: [{ "value": "pending", "label": "Pendiente" }, { "value": "paid", "label": "Pagado" }] },
        { label: strings.table.fee.header.comment, key: "comment", filter: false },
    ]

    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction(model, {}));
            setStep("loaded");
        }

    }, [dispatch,step]);

    if (path !== pageName && path !== "") {
        return (<></>);
    } else {
        return (
            <>
                <MainPagetitle mainTitle={strings.page.fee.title} pageTitle={"Listado de Cuotas"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {canView &&
                        <div className="row">
                            <div className="col-xl-12">
                                <Grid
                                    list={list}
                                    title={"Listado de Cuotas"}
                                    conf={gridConf}

                                    exportData={exportData}

                                ></Grid>
                            </div>
                        </div>
                    }
                </div>

            </>
        );
    }




};

export default Fee;