import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocale } from "../../../../context/Locale";

import MainPagetitle from '../../../layouts/MainPagetitle.js';
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import Address from '../../Common/Address/Address.js';

import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'

import {
    filterAction
} from '../../../../store/actions/ModelActions.js'


import Representative from '../../Common/Representative/Representative.js'
import Company from '../../Common/Company/Company.js'

const CompanyView = (props) => {


    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const dispatch = useDispatch();

    const data = useSelector((store) => store.company.data);
    const capabilities = useSelector((store) => store.user.capabilities);

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
    const [toEdit, setToEdit] = useState(false);

    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }

    useEffect(() => {

        if (step === "init") {
            if (data.legal_id !== undefined && data.legal_id !== "") {
                dispatch(filterAction("address", { "company_id": data.id || 0 }));
            }

            setStep("edit");

        }else{
            setValue(data);
        }

    }, [dispatch, step, data]);

    const onChangeAddress = () => {
        if (getValue("id", "") !== "") {
            
            setTimeout(()=>{dispatch(filterAction("address", { "company_id": getValue("id", 0) }));}, 500);
        }
    }

    const onSaveRepresentative = (item) => {

    }

    if (props === undefined || toEdit) {
        return (<> {toEdit && (<Navigate to={routers.company.edit} replace={true} />)}</>)
    } else if (!capabilities.map(function (c) { return c.code }).includes('COMPANY_EDIT')) {
        return (<></>);
    } else {


        return (
            <>
                <MainPagetitle mainTitle={"Empresa"} pageTitle={"Ver Empresa"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {value.id &&
                        <Company data={value} ></Company>
                    }
                    {value.person_id &&
                        <Representative onSave={onSaveRepresentative} data={{ id: value.person_id }} ></Representative>
                    }
                    <div className="row">
                        <div className="col-xl-12"> <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Direcciones</h4>
                            </div>
                            <div className="card-body">
                                <div className="basic-form">
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">

                                            {value.legal_id &&
                                                <Address
                                                    companyId={getValue("id", "")}
                                                    onChange={onChangeAddress}
                                                ></Address>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>


                </div>

            </>
        )
    }
}

export default CompanyView;