import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";
import { storeAction, getAllAction } from '../../../../store/actions/ModelActions.js'
import Select2 from '../../Base/Select2'
import { useLocale } from "../../../../context/Locale/index.js";
import ModalConfirm from '../../Common/Modal/ModalConfirm';
import MainPagetitle from '../../../layouts/MainPagetitle.js';
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'
import { Button } from "react-bootstrap";


const CompanyNew = (props) => {

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const model = "company";
    const capabilities = useSelector((store) => store.user.capabilities);
    const people = useSelector((store) => store.person.list);
    
    const [showComfirm, setShowConfirm] = useState(false);
    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
    const [toList, setToList] = useState(false);

    const dispatch = useDispatch();
    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }

    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction("person",{}));
            setValue({
                "name": "",
                "legal_id": "",
                "representative_legal_id":"",
                "category": "",
                "size": "",
                "sales_level": ""
            });
        }

        if (step !== "edit") {
            setStep("edit");
        }

    }, [dispatch, step]);


    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;;
        if (id === "representative_legal_id") {
            let itemNew = people.find(c => String(c.id) === String(data));
            if (itemNew !== undefined) {
                newValue["representative_legal_id"] = itemNew.legal_id;
            }

        }

        setValue(newValue);
    }

    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {
        setToList(true);
        const request = {
            "name": value.name,
            "legal_id": value.legal_id,
            "person_legal_id": value.representative_legal_id,
            "category": value.category,
            "size": value.size,
            "sales_level": value.sales_level
        };

        dispatch(storeAction(model, request));
        setShowConfirm(false);

    }

    function onCancelForm() {
        setShowConfirm(false);
    }


    const sizeList = [
        { "value": "", "label": "Seleccione..." },
        { "label": "Micro", "value": "micro" },
        { "label": "Mediana", "value": "mediana" },
        { "label": "Grande", "value": "grande" }
    ].map(function (item) {
        return {
            "value": item.value,
            "label": item.label,
        };
    });

    const saleLevelList = [
        { "value": "", "label": "Seleccione..." },
        { "label": "Alta", "value": "alta" },
        { "label": "Media", "value": "media" },
        { "label": "Baja", "value": "baja" }
    ].map(function (item) {
        return {
            "value": item.value,
            "label": item.label,
        };
    });

    const peopleList = people.map(function (item) {
        return {
            "value": item.legal_id,
            "label": item.legal_id + " " + item.first_name + " " + item.last_name,
        };
    });



   if (props === undefined || toList) {
        return (<> {toList && (<Navigate to={routers.company.admin} replace={true} />)}</>)
    } else if (!capabilities.map(function (c) { return c.code }).includes('COMPANY_CREATE')) {
        return (<></>);
    } else{

        const peopleValue = peopleList.find(i => String(i.value) === getValue("representative_legal_id",""));
        const sizeValue = sizeList.find(i => String(i.value) === getValue("size",""));
        const saleLevelValue = saleLevelList.find(i => String(i.value) === getValue("sales_level",""));


        const fields = [
            {
                "label": "Datos", "data": [                  
                    { "name": "name", "label": strings.form.addCompany.labelName, "type": "text", "required": false, disabled: false },
                    { "name": "legal_id", "label": strings.form.addCompany.labelLegalId, "type": "text", "required": false, disabled: false },
                    { "name": "representative_legal_id", "label": strings.form.addCompany.labelRepresentative, "type": "select", options: [{ "value": "", "label": "Seleccione..." }].concat(peopleList), defaultValue: peopleValue, "required": true, disabled: false },
                    { "name": "category", "label": strings.form.addCompany.labelCategory, "type": "text", "required": false, disabled: false },
                    { "name": "size", "label": "Tamaño", "type": "select", options: sizeList, defaultValue: sizeValue, "required": true, disabled: false },
                    { "name": "sales_level", "label": strings.form.addCompany.labelSalesLevel, "type": "select", options: saleLevelList, defaultValue: saleLevelValue, "required": true, disabled: false },

                ]
            }]


        return (

            <>
                <MainPagetitle mainTitle={"Empresa"} pageTitle={"Nueva Empresa"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    <div className="row">
                        {fields.map((group, index1) => (
                            <div key={index1} className="card">
                                {group.label !== "" &&
                                    <div className="card-header">
                                        <h4 className="card-title">{group.label}</h4>
                                    </div>
                                }
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="row">
                                                {group.data.map((item, index) => (

                                                    <div key={index} className="col-xl-6 mb-3">
                                                        <label htmlFor={item.name} className="form-label">{item.label} {item.required && <span className="text-danger">*</span>}</label>
                                                        {item.type === "text" &&
                                                            <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={item.name} type="text" value={getValue(item.name,"")} className="form-control" />
                                                        }
                                                        {item.type === "number" &&
                                                            <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={item.name} type="number" value={getValue(item.name,"")} className="form-control" />
                                                        }
                                                        {item.type === "select" &&
                                                            <Select2 onChange={onChange} defaultValue={item.defaultValue} options={item.options} id={item.name} ></Select2>
                                                        }
                                                    </div>

                                                ))}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        ))}
                        <div>
                            <Button onClick={() => onSaveFormConfirm()} className="btn-sm" variant="primary"> {"Enviar"}</Button>
                        </div>
                        <ModalConfirm
                            show={showComfirm}
                            title={"Confirmación"}
                            message={"¿Está seguro de crear la empresa"}
                            cancelButtonText={"No"} confirmButtonText={"Sí"}
                            onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                        >
                        </ModalConfirm>
                    </div>
                </div>
            </>
        )
    }
}


export default CompanyNew;