import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useLocale } from "../../../../../context/Locale/index.js";
import { useSelector, useDispatch } from "react-redux";

import {
    disableReloadAction as disableReloaRegiondAction,
    filterAction as filterRegionAction

} from '../../../../../store/actions/RegionFilterActions';

import {
    disableReloadAction as disableReloadProvinceAction,
    enableReloadAction as enableReloadProvinceAction,
    filterAction as filterProvinceAction

} from '../../../../../store/actions/ProvinceFilterActions';

import {
    disableReloadAction as disableReloadCommuneAction,
    enableReloadAction as enableReloadCommuneAction,
    filterAction as filterCommuneAction

} from '../../../../../store/actions/CommuneFilterActions';

import { updateAction } from '../../../../../store/actions/AddressFilterActions';

import FormInput from '../../../Form/FormInput.js'
import FormSelect2 from '../../../Form/FormSelect2.js'

import ModalConfirm from '../../../Common/Modal/ModalConfirm.js';

const FormEditAddress = (props) => {

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [showComfirm, setShowConfirm] = useState(false);

    const capabilities = useSelector((store) => store.user.capabilities);
    const newItem = capabilities.map(function (c) { return c.code }).includes("ADDRESS_CREATE");

    const regions = useSelector((store) => store.region.list);
    const reloadRegions = useSelector((store) => store.region.reload);

    const provinces = useSelector((store) => store.province.list);
    const reloadProvinces = useSelector((store) => store.province.reload);

    const communes = useSelector((store) => store.commune.list);
    const reloadCommunes = useSelector((store) => store.commune.reload);

    const [dataForm, setDataForm] = useState(props.data || {});

    const companyId = dataForm ? (dataForm["id"] ? dataForm["id"] : "") : "";
    const id = dataForm ? (dataForm["id"] ? dataForm["id"] : 0) : 0;


    const dispatch = useDispatch();

    function onChange(id, value) {
        let dataEditFormNew = { ...dataForm };
        dataEditFormNew[id] = value;
        if (id === "region_id") {
            dispatch(enableReloadProvinceAction());
        }

        if (id === "province_id") {
            dispatch(enableReloadCommuneAction());
        }

        if (id === "commune_id") {
        }

        setDataForm(dataEditFormNew);
    }

    function onSaveFormConfirm() {
        setShowConfirm(true);
    }


    function onCancelForm() {
        setShowConfirm(false);
        if (props.onCancel !== undefined) {
            props.onCancel();
        }
    }

    function onSaveForm() {

        let dataEditFormNew = { ...dataForm };
        dataEditFormNew["company_id"] = companyId;
        dataEditFormNew["id"] = id;

        dispatch(updateAction(dataEditFormNew));
        setShowConfirm(false);
        if (props.onSave !== undefined) {
            props.onSave();
        }
    }


    if (companyId === "" || id === 0 || (!newItem && Array.isArray(regions))) {
        return (<></>);
    } else {

        if (reloadCommunes && dataForm["province_id"]) {
            dispatch(disableReloadCommuneAction());
            dispatch(filterCommuneAction({ "province_id": dataForm["province_id"] }));
        }


        if (reloadProvinces && dataForm["region_id"]) {
            dispatch(disableReloadProvinceAction());
            dispatch(filterProvinceAction({ "region_id": dataForm["region_id"] }));
        }

        if (reloadRegions) {
            dispatch(disableReloaRegiondAction());
            dispatch(filterRegionAction({}));
        }



        let regionsList = regions.map(function (item) {
            return {
                "value": String(item.id),
                "label": String(item.name),
            };
        });

        let provincesList = provinces.map(function (item) {
            return {
                "value": String(item.id),
                "label": String(item.name),
            };
        });

        let communesList = communes.map(function (item) {
            return {
                "value": String(item.id),
                "label": String(item.name),
            };
        });

        const regionValue = regionsList.find(i => dataForm["region_id"] ? String(i.value) === String(dataForm["region_id"]) : false);
        const provinceValue = provincesList.find(i => dataForm["province_id"] ? String(i.value) === String(dataForm["province_id"]) : false);
        const communeValue = communesList.find(i => dataForm["commune_id"] ? String(i.value) === String(dataForm["commune_id"]) : false);

        let secureValues = {
            "street": (dataForm.street || ""),
            "number": (dataForm.number || ""),
            "postCode": (dataForm.postCode || "")
        }

        return (
            <>
                <div className="row">
                    {(regionValue !== undefined) && <FormSelect2 defaultValue={regionValue} options={regionsList} onChange={onChange} textlabel="Región" id="region_id" placeholder="Región"  ></FormSelect2>}
                    {(regionValue === undefined) && <FormSelect2 options={regionsList} onChange={onChange} textlabel="Región" id="region_id" placeholder="Región"  ></FormSelect2>}

                    {(provinceValue !== undefined) && <FormSelect2 defaultValue={provinceValue} options={provincesList} onChange={onChange} textlabel="Provincia" id="province_id" placeholder="Provincia"  ></FormSelect2>}
                    {(provinceValue === undefined) && <FormSelect2 options={provincesList} onChange={onChange} textlabel="Provincia" id="province_id" placeholder="Provincia"  ></FormSelect2>}

                    {(communeValue !== undefined) && <FormSelect2 defaultValue={communeValue} options={communesList} onChange={onChange} textlabel="Comuna" id="commune_id" placeholder="Comuna"  ></FormSelect2>}
                    {(communeValue === undefined) && <FormSelect2 options={communesList} onChange={onChange} textlabel="Comuna" id="commune_id" placeholder="Comuna"  ></FormSelect2>}

                    <FormInput value={secureValues.street} onChange={onChange} textlabel="Calle" htmlFor="street" type="text" id="street" placeholder="Calle"   ></FormInput>
                    <FormInput value={secureValues.number} onChange={onChange} textlabel="Número" htmlFor="number" type="text" id="number" placeholder="Número"   ></FormInput>
                    <FormInput value={secureValues.postCode} onChange={onChange} textlabel="Código Postal" htmlFor="postCode" type="text" id="postCode" placeholder="Código Postal"   ></FormInput>

                </div>
                <div>
                    <button onClick={() => onSaveFormConfirm()} className="btn btn-sm btn-primary me-1">{strings.form.common.buttonSubmit}</button>
                    <Link to={"#"} onClick={() => onCancelForm()} className="btn btn-sm btn-danger light ms-1">{strings.form.common.buttonCancel}</Link>
                </div>
                <ModalConfirm
                    show={showComfirm}
                    title={"Confirmación"}
                    message={"¿Está seguro de editar la dirección"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                >
                </ModalConfirm>
            </>
        );
    }


}

export default FormEditAddress;