
import FormInput from '../../Form/FormInput.js'
import { useLocale } from "../../../../context/Locale/index.js";

const InputPaymentInscriptionIdReadOnly = (props) => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    return (
        <FormInput { ...props}  textlabel={strings.form.addPayment.labelInscriptionId} htmlFor="company_legal_id" type="text" id="company_legal_id"  ></FormInput>
    )
}
export default InputPaymentInscriptionIdReadOnly;