import React, { useState, useEffect } from 'react';
import MainPagetitle from '../../layouts/MainPagetitle';
import NavMenu from '../Common/NavMenu/NavMenu.js';

import { useLocale } from "../../../context/Locale";

import { getAllAction } from '../../../store/actions/ModelActions'

import { useSelector, useDispatch } from "react-redux";


import FeesPaidCard from './Card/FeesPaidCard'
import PaymentsReceivedCard from './Card/PaymentsReceivedCard'
import FeesPendingCard from './Card/FeesPendingCard'



const Fee = () => {

    const pageName = "dashboard"
    const capabilities = useSelector((store) => store.user.capabilities);
    const canView = capabilities.map(function (c) { return c.code }).includes('DASHBOARD_VIEW');
    const path = useSelector((store) => store.common.path);

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [step, setStep] = useState("init");


    const dispatch = useDispatch();
    const list = useSelector((store) => store.fee.list);
    const payments = useSelector((store) => store.payment.list);

    useEffect(() => {

        if (step === "init") {
           dispatch(getAllAction("fee",{}));
           dispatch(getAllAction("payment",{}));
           setStep("loaded");
        }

    }, [dispatch,step]);

    if (path !== pageName && path !== "") {
        return (<></>);
    } else {

        return (
            <>
                <MainPagetitle mainTitle="Panel" pageTitle="Panel" parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu/>
                    {canView && 
                    <div className="row">
                        <PaymentsReceivedCard list={payments} />
                        <FeesPaidCard list={list}  />
                        <FeesPendingCard list={list}  />
                    </div>
                    }
                </div>

            </>
        );
    }

};

export default Fee;