import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Row, Col } from "react-bootstrap";

import FormEditFeeTemplate from '../Form/FormEditFeeTemplate.js'

const ModalEditFeeTemplate = forwardRef((props, ref) => {

    const [showModal, setShowModal] = useState(false);


    useImperativeHandle(ref, () => ({
        showModal() {
            setShowModal(true)
        }
    }));

    const closeModal = () => {
        setShowModal(false)
    }

    const closeModalSave = () => {
        setShowModal(false)
        if (props.onSave !== undefined) {
            props.onSave();
        }
    }

    const closeModalCancel = () => {
        setShowModal(false)
        if (props.onCancel !== undefined) {
            props.onCancel();
        }
    }

    if (props === undefined) {
        return (<div className="row"></div>)
    } else {
        return (
            <>
                <Offcanvas show={showModal} onHide={setShowModal} className="offcanvas-end customeoff" placement='end'>
                    <div className="offcanvas-header">
                        <h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
                        <button type="button" className="btn-close"
                            onClick={() => closeModal()}
                        >
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div className="offcanvas-body">
                        <div className="container-fluid">
                            <Row>
                                <Col xl={12}>
                                    <FormEditFeeTemplate
                                        onSave={closeModalSave}
                                        onCancel={closeModalCancel}
                                    ></FormEditFeeTemplate>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Offcanvas>
            </>
        );
    }
});

export default ModalEditFeeTemplate;