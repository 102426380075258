import {
    SET_PATH_ACTION,
    SET_USER_ACTION,
    LOGOUT2_ACTION,
    MUTEX_CALL_ACTION,
    UMUTEX_CALL_ACTION
} from '../actions/CommonActions';

const initialState = {
    user: {},
    path : "",
    reloadUser : true,
    mutex : ""
};

export function CommonReducer(state = initialState, action) {
   
    //Mutex
    if (action.type === MUTEX_CALL_ACTION) {
        return {
            ...state,
            mutex:  state.mutex === "" ? action.payload : state.mutex
        };
    }

    if (action.type === UMUTEX_CALL_ACTION) {
        return {
            ...state,
            mutex:  ""
        };
    }


   
    //User
    if (action.type === SET_USER_ACTION) {
        return {
            ...state,
            user: action.payload,
            reloadUser: false
        };
    }

    if (action.type === LOGOUT2_ACTION) {
        return {
            ...state,
            user: {},
            reloadUser: true
        };
    }

    //Path
    if (action.type === SET_PATH_ACTION) {
        return {
            ...state,
            path: action.payload
        };
    }

    return state;
}