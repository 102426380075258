import React, { useState, useEffect, useRef } from 'react';
import MainPagetitle from '../../layouts/MainPagetitle';
import ModalConfirm from '../Common/Modal/ModalConfirm';
import NavMenu from '../Common/NavMenu/NavMenu.js';

import { useLocale } from "../../../context/Locale";

import {
    destroyAction,
    openEditFormAction,
    getAllAction
} from '../../../store/actions/ModelActions'


import { useSelector, useDispatch } from "react-redux";
import Grid from '../Common/Grid/Grid';
import { Navigate } from "react-router-dom";
import routers from '../../../setting/routes.json'


const Capability = () => {
    const pageName = "capabilities"
    const model = "capability";
    const path = useSelector((store) => store.common.path);

    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const canViewAdmin = HasCapability(capabilities,'MENU_ADMIN');
    const canView = HasCapability(capabilities,'PAGE_CAPABILITY_LIST');
    const newItem = HasCapability(capabilities,'BTN_CAPABILITY_CREATE');
    const viewItem = HasCapability(capabilities,'BTN_CAPABILITY_GET');
    const editItem = HasCapability(capabilities,'BTN_CAPABILITY_EDIT');
    const destroyItem = HasCapability(capabilities,"BTN_CAPABILITY_DESTROY");
    const exportData = HasCapability(capabilities,"BTN_CAPABILITY_EXPORT");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const [toDestroy, setToDestroy] = useState({});

    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);

    const [step, setStep] = useState("init");
    const [toNew, setToNew] = useState(false);
    const [toView, setToView] = useState(false);
    const [toEdit, setToEdit] = useState(false);

    const dispatch = useDispatch();


    const list = useSelector((store) => store.capability.list);

    const gridConf = [
        { label: strings.table.capability.header.code, key: "code", className: "", filter: true },
        { label: "Grupo", key: "group", className: "", filter: true },
        { label: "Acción", key: "action", className: "", filter: true },
        { label: strings.table.capability.header.description, key: "description", className: "", filter: true }
    ]

    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction(model, {}));
            setStep("loaded");
        }

    }, [dispatch,step]);

    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
    }

    function onDestroyForm() {


        dispatch(destroyAction(model, {...toDestroy, id : toDestroy.code}));
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }

    function onClickViewForm(item) {
        let itemNew = { ...item }
        dispatch(openEditFormAction(model, itemNew));
        setToView(true);

    }


    function onCancelForm() {
        setShowConfirmDestroy(false);
        setToDestroy({});
    }

    function onClickNewForm() {
        setToNew(true);
    }

    function onClickEditForm(item) {
        let itemNew = {...item}
        dispatch(openEditFormAction(model, itemNew));
        setToEdit(true);
    }

    if (path !== pageName && path !== "") {
        return (<></>);
    } if(toNew || toView || toEdit) {
        return(<>
            {toNew && (<Navigate to={routers.capability.new} replace={true} />)}
            {toEdit && (<Navigate to={routers.capability.edit} replace={true} />)}
            {toView && (<Navigate to={routers.capability.view} replace={true} />)}
        </>);
    } else {

        return (
            <>
                <MainPagetitle mainTitle={strings.page.capability.title} pageTitle={strings.page.capability.title} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {(canViewAdmin && canView) &&
                        <div className="row">
                            <div className="col-xl-12">
                                <Grid
                                    list={list}
                                    title={strings.page.capability.title}
                                    conf={gridConf}
                                    onClickNewItem={onClickNewForm}
                                    onClickDestroy={onDestroyFornConfirm}
                                    onClickEdit={onClickEditForm}
                                    onClickView={onClickViewForm}

                                    newItem={newItem}
                                    viewItem={viewItem}
                                    editItem={editItem}
                                    destroyItem={destroyItem}
                                    exportData={exportData}

                                ></Grid>
                            </div>
                        </div>
                    }
                </div>
                <ModalConfirm
                    show={showComfirmDestroy}
                    title={"Confirmación"}
                    message={"¿Está seguro de eliminar la capacidad"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                >
                </ModalConfirm>
            </>
        );
    }




};

export default Capability;