import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import FormRepresentativeView from './Form/FormRepresentativeView'
import FormRepresentativeEdit from './Form/FormRepresentativeEdit'

import { getAction } from '../../../../store/actions/ModelActions'

const Representative = (props) => {
    const dispatch = useDispatch();
    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);

    const representative = (useSelector((store) => store.person.data) || {});

    const [modeView, setModeView] = useState(true);
    const [modeEdit, setModeEdit] = useState(false);
    const [modeNew, setModeNew] = useState(false);

    const [step, setStep] = useState("init");

    const swapMode = (newMode) => {
        setModeView(newMode === "view");
        setModeEdit(newMode === "edit");
        setModeNew(newMode === "new");
    }

    const [data, setData] = useState(props ? (props.data ? props.data : {}) : {});

    const canView = HasCapability(capabilities, "REPRESENTATIVE_GET");
    const canEdit = HasCapability(capabilities, "REPRESENTATIVE_EDIT");
    const canCreate = HasCapability(capabilities, "REPRESENTATIVE_CREATE");

    useEffect(() => {

        if (step === "init") {
            if(data.id !== undefined){
                dispatch(getAction("person",{
                    id : data.id
                }));
            }
            setStep("edit");

        }


    }, [step, data]);

    const onSave = (item) => {
        swapMode("view");
    }

    const onCancel = () => {
        swapMode("view");
    }

    if (canView && modeView) {
        return (<>

            {representative.id &&
                <div className="row">
                    <div className="col-xl-12"> <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Representante</h4>
                        </div>
                        <div className="card-body">
                            <div className="basic-form">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <FormRepresentativeView data={representative} />
                                </form>
                            </div>
                            {canEdit &&
                                <div className="col-xl-3 mb-3">
                                    <Button onClick={() => swapMode("edit")} className="btn-sm" variant="primary"> {"Editar"}</Button>
                                </div>
                            }
                        </div>
                    </div>
                    </div>
                </div>
            }

        </>);
    } if (canEdit && modeEdit) {
        return (<>

            {representative.id &&
                <div className="row">
                    <div className="col-xl-12"> <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Representante</h4>
                        </div>
                        <div className="card-body">
                            <div className="basic-form">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <FormRepresentativeEdit data={representative} onSave={onSave} onCancel={onCancel} />
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            }

        </>);
    } else {
        return (<></>);
    }

}
export default Representative;