
import FormInput from '../../Form/FormInput.js'
import { useLocale } from "../../../../context/Locale/index.js";

const InputRoleDescription = (props) => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    return (
        <FormInput { ...props}   textlabel={strings.form.addRole.labelDescription} htmlFor="description"  type="text" id="description" placeholder=""   ></FormInput>
    )
}
export default InputRoleDescription;