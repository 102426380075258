import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";

import { useLocale } from "../../../../context/Locale";
import {
    filterAction,
} from '../../../../store/actions/ModelActions'

import Grid from '../Grid/Grid';

import FormExtensionNew from './Form/FormExtensionNew';
import {formatIsoDateToString} from '../../../../tools/fomatter.js'

const Extension = (props) => {

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const dispatch = useDispatch();

    const model="extension";

    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);

    const list = useSelector((store) => store.extension.list);

    const [modeView, setModeView] = useState(true);
    const [modeEdit, setModeEdit] = useState(false);
    const [modeNew, setModeNew] = useState(false);

    const [step, setStep] = useState("init");

    const swapMode = (newMode) => {
        setModeView(newMode === "view");
        setModeEdit(newMode === "edit");
        setModeNew(newMode === "new");
    }

    const [data, setData] = useState(props ? (props.data ? props.data : {}) : {});

    const canView = HasCapability(capabilities, "EXTENSION_GET");
    const canEdit = HasCapability(capabilities, "EXTENSION_EDIT");
    const canCreate = HasCapability(capabilities, "EXTENSION_CREATE");

    const gridConf = [
        { label: "Rut Empresa", key: "company_legal_id", className: "", filter: true },
        { label: "Nombre", key: "company_name", className: "", filter: true },
        { label: "Dia de Pago", key: "extension_payment_day", className: "", filter: true },
        { label: "Dia de Pago", key: "extension_payment_day", className: "", filter: true },
        { label: "Valor de Cuota", key: "extension_amount", className: "", filter: true },
        { label: "Número de Cuotas", key: "extension_amount_fees", className: "", filter: true },
        { label: strings.table.inscription.header.start_date, key: "inscription_start_date", "formatter": formatIsoDateToString },
        
    ]



    useEffect(() => {

        if (step === "init") {
           dispatch(filterAction(model,{
            "inscription_id": data.id || 0
           }));
           setStep("loaded");
        }

    }, [dispatch,step]);

    const onSave = (item) => {
        dispatch(filterAction(model,{
           "inscription_id": data.id || 0
        }));
        swapMode("view");
    }

    const onCancel = () => {
        swapMode("view");
    }

    function onClickNewForm() {
        swapMode("new");
    }

    if (canView && modeView) {
        return (<>

            {data.id &&
               <div className="row">
               <div className="col-xl-12">
                   <Grid
                       list={list}
                       title={"Extensiones"}
                       conf={gridConf}
                       
                       onClickNewItem={onClickNewForm}

                       newItem={canCreate}

                   ></Grid>
               </div>
           </div>
                }

        </>);
    } if (canCreate && modeNew) {
        return (<>

            {data.id &&
                <div className="row">
                    <div className="col-xl-12"> <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Nueva Extensión</h4>
                        </div>
                        <div className="card-body">
                            <div className="basic-form">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <FormExtensionNew data={data} onSave={onSave} onCancel={onCancel} ></FormExtensionNew>
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            }

        </>);
    } else {
        return (<></>);
    }
}

export default Extension;