import React from "react";

import { useLocale } from "../../context/Locale";
import urls from '../../setting/urls.json';

const Footer = () => {
	const { stateLocale } = useLocale();
    const { strings } = stateLocale;

	var d = new Date();
	return (
		<div className="footer out-footer">
			<div className="copyright">
				<p>{strings.copyright.textBy}
					
				{" "}{strings.copyright.textDevelopBy}{" "}
					<a href={urls.webSiteCompany} target="_blank"  rel="noreferrer">{strings.copyright.textCompany}</a>{" "}
					{d.getFullYear()}
				</p>
			</div>
		</div>
	);
};

export default Footer;
