import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const InboxSideBar = () => {
    const capabilities = useSelector((store) => store.user.capabilities);
    const  newNotificacion = capabilities.map(function (c) { return c.code }).includes('NOTIFICATION_CREATE');
    return (
        <Fragment>
            <div className="email-left-box dlab-scroll  pt-3 ps-0">
                <div className="mail-list rounded ">
                    <Link to="/notification-inbox" className="btn btn-sm  list-group-item active">
                        <i className="fa-regular fa-envelope align-middle"></i>
                        Notificaciones
                    </Link>
                </div>
                {newNotificacion && (
                <div className="p-0">
                    <Link
                        to="/notification-compose"
                        className="btn btn-sm text-white btn-block"
                    >
                        <i className="fa-solid fa-plus me-2"></i>Nueva Notificación
                    </Link>
                </div>
                )}
            </div>
        </Fragment>
    );
}
export default InboxSideBar;