import React, { Fragment, useState, useEffect } from "react";
import InboxSideBar from '../Common/InboxSideBar'
import { useSelector, useDispatch } from "react-redux";
import ModalConfirm from '../../Common/Modal/ModalConfirm';
import MainPagetitle from '../../../layouts/MainPagetitle'
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import { updateAction } from '../../../../store/actions/NotificationEditActions.js'

import {
    enableReloadAction
} from '../../../../store/actions/NotificationActions'

import {
	setDataAction
} from '../../../../store/actions/NotificationListActions.js'

import { useLocale } from "../../../../context/Locale";

const Read = (props) => {

    const pageName = "notification-edit"

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;


    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const notifications = useSelector((store) => store.notification.list);
    const notification = useSelector((store) => store.notification.data);

    const path = useSelector((store) => store.common.path);

    const canView = HasCapability(capabilities, 'NOTIFICATION_GET');
    const canSend = HasCapability(capabilities, 'NOTIFICATION_SEND');
    const canEdit = HasCapability(capabilities, 'NOTIFICATION_EDIT');


    const [showComfirmNew, setShowConfirmNew] = useState(false);





    const dispatch = useDispatch();



    const [step, setStep] = useState("init");

    useEffect(() => {

        if (step === "init") {
            setStep("loaded");
        }

    }, [dispatch, step, notifications]);

    function onChange(id, value) {
		let newNotification = { ...notification };
		newNotification[id] = value;
		dispatch(setDataAction({ ...newNotification }));
	}

    function onSendConfirm() {
        setShowConfirmNew(true);
    }

    function onSend() {
        dispatch(updateAction(notification));
        setShowConfirmNew(false);

        dispatch(enableReloadAction());
    }

    function onCancelSend() {
        setShowConfirmNew(false);
    }

    if (path !== pageName) {
        return (<>1</>);
    } else {
        return (
            <Fragment>
                <MainPagetitle mainTitle={"Notificaciones"} pageTitle={"Ver Notificación"} parentTitle={strings.page.home.title} />
                <div className="row gx-0">
                    <NavMenu />
                    <div className="col-xl-12">
                        <div className="card h-auto">
                            <div className="card-body">
                                <div className="row gx-0">
                                    <div className="col-xl-2 col-xxl-3">
                                        <InboxSideBar></InboxSideBar>
                                    </div>

                                    <div className="col-lg-10 col-xxl-9">
                                        <div className="email-right-box">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="right-box-padding border-start p-0">

                                                        {(canView) &&
                                                            <div className="read-wapper dz-scroll" id="read-content">
                                                                <div className="read-content">
                                                                    <hr />
                                                                    <div className="media mb-2 mt-3">
                                                                        <div className="media-body">
                                                                        <input value={notification.subject} onChange={(e) => { onChange("subject", e.target.value); e.preventDefault() }} type="text" className="form-control bg-transparent" placeholder="Escriba el asunto..." />                                                                            <p className="read-content-email"> Para: {notification.to || ""}</p>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div className="read-content-body">

                                                                        <textarea onChange={(e) => { onChange("content", e.target.value); e.preventDefault() }} id="email-compose-editor" className="textarea_editor form-control bg-transparent" rows="5"
                                                                            placeholder="Escriba el texto ..."
                                                                        >
                                                                            {notification.content || ""}
                                                                        </textarea>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                                <div className="text-end">

                                                                    {canEdit &&
                                                                        <button onClick={() => onSendConfirm()} className="btn btn-sm btn-primary " type="button">
                                                                            Guardar
                                                                        </button>
                                                                    }

                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <ModalConfirm
                    show={showComfirmNew}
                    title={"Confirmación"}
                    message={"¿Está seguro de guardar la notificación?"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelSend} onCancelAction={onCancelSend} onConfirmAction={onSend}
                >
                </ModalConfirm>
            </Fragment>
        );
    }




};

export default Read;
