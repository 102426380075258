import { RELOAD_ENABLE_ACTION, RELOAD_DISABLE_ACTION } from '../actions/ReportActions';

import {
    SET_FEESINPROGRESS_ACTION,
    FEES_CONFIRMED_ACTION,
    FEES_FAILED_ACTION,
    UPDATE_PARAMSREPORT_ACTION,
    UPDATE_CODEREPORT_ACTION

} from '../actions/ReportListActions';


const initialState = {

    dispathGetAll: true,
    dispathGetAllInprogress: false,
    fees: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    reportCode: "fees",
    params: {
        // Fecha desde para filtrar las cuotas
        fromDate:  (new Date()).setMonth((new Date()).getMonth() - 1),

        // Fecha hasta para filtrar las cuotas
        toDate: new Date(),

        // Id de Inscripción seleccionda
        inscriptionId: 0
    },
    reload: false

};

export function ReportReducer(state = initialState, action) {

    if (action.type === RELOAD_ENABLE_ACTION) {
        return {
            ...state,
            reload: true
        };
    }

    if (action.type === RELOAD_DISABLE_ACTION) {
        return {
            ...state,
            reload: false
        };
    }


    if (action.type === UPDATE_CODEREPORT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            reportCode: action.payload,
        };
    }

    if (action.type === UPDATE_PARAMSREPORT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            params: action.payload,
        };
    }


    if (action.type === SET_FEESINPROGRESS_ACTION) {
        return {
            ...state,
            dispathGetAllInprogress: action.payload
        };
    }

    if (action.type === FEES_CONFIRMED_ACTION) {
        return {
            ...state,
            fees: action.payload,
            dispathGetAllInprogress: false,
            errorMessage: '',
            successMessage: 'GetAll Successfully Completed',
            showLoading: false,
            reload: false,
        };
    }
    if (action.type === FEES_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: action.payload,
            dispathGetAllInprogress: false,
            successMessage: '',
            showLoading: false,
            realod: false,
        };
    }

    return state;
}