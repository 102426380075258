import React from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Link } from "react-router-dom";
import { navtoggle } from "../../../store/actions/AuthActions";

import logo from '../../../images/logo/logo-full-white.png';

import routers from '../../../setting/routes.json'

const NavHader = () => {

  const dispatch = useDispatch();
  const sideMenu = useSelector(state => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };
  return (
    <div className="nav-header">
      <Link to={routers.home} className="brand-logo" style={{ background: "white" }}>
        <img src={logo} alt="" style={{ width: "70px", color: "red" }} className="light-logo" />
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          handleToogle()
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
