import React, { useState, useEffect } from 'react';
import { useLocale } from "../../../context/Locale";
import { useSelector, useDispatch } from "react-redux";
import NavMenu from '../Common/NavMenu/NavMenu.js';
import { formatIsoDateToString, formatStatusStringToString } from '../../../tools/fomatter'
import MainPagetitle from '../../layouts/MainPagetitle';
import FormReportSelector from './Form/FormReportSelector'
import FormReportFeesbyDateRange from './Form/FormReportFeesbyDateRange'
import FormReportFeesbyInscription from './Form/FormReportFeesbyInscription'

import Grid from '../Common/Grid/Grid';

import {
    getAllAction as getAllReportAction,
    feesByInscriptionAction,
    feesByDateRangeAction,
    pendingFeesByDateRangeAction,
    defaulterFeesAction,
    feesByInscriptionByDateRangeAction,
    defaulterFeesVersusAmountBalanceAction,
    defaulterFeesVersusPaidAction,
    getAllConfirmedAction as getAllConfirmedReportAction
} from '../../../store/actions/ReportListActions'

import {
    getAllAction as getAllActionModel
} from '../../../store/actions/ModelActions'

const Report = () => {
    const pageName = "reports"
    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);

    const canView = HasCapability(capabilities, 'PAGE_REPORT_LIST');

    const path = useSelector((store) => store.common.path);
    const reportCode = useSelector((store) => store.report.reportCode);
    const paramsReport = useSelector((store) => store.report.params);
    const list = useSelector((store) => store.report.fees);

    const exportData = HasCapability(capabilities, "BTN_REPORT_EXPORT");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const [step, setStep] = useState("init");

    const confFees = getConf("fees", strings);
    const confFeesByInscription = getConf("feesByInscription", strings);
    const confFeesByInscriptionByDateRange = getConf("feesByInscriptionByDateRange", strings);
    const confFeesByDateRange = getConf("feesByDateRange", strings);
    const confPendingFeesByDateRange = getConf("pendingFeesByDateRange", strings);
    const confDefaulterFees = getConf("defaulterFees", strings);
    const confDefaulterFeesVersusAmountBalance = getConf("defaulterFeesVersusAmountBalance", strings);
    const confDefaulterFeesVersusPaid = getConf("defaulterFeesVersusPaid", strings);
    const dispatch = useDispatch();

    useEffect(() => {

        if (step === "init") {
            dispatch(getAllActionModel('inscription', {}));
            setStep("loaded");
        }

    }, [dispatch, step]);


    function getConf(name, strings) {
        if ("fees" === name) {
            return [
                // //{ label: strings.table.report.header.company_id, key: "company_id", filter: true },
                { label: strings.table.report.header.company_legal_id, key: "company_legal_id", filter: true },
                { label: strings.table.report.header.company_name, key: "company_name", filter: true },
                { label: strings.table.report.header.inscription_id, key: "inscription_id", filter: true },
                { label: strings.table.report.header.inscription_payment_day, key: "inscription_payment_day", filter: false },
                //{ label: strings.table.report.header.fee_id, key: "fee_id", filter: false },
                { label: strings.table.report.header.fee_amount, key: "fee_amount", filter: false },
                { label: strings.table.report.header.fee_sequence, key: "fee_sequence", filter: false },
                { label: strings.table.report.header.fee_amount_paid, key: "fee_amount_paid", filter: false },
                { label: strings.table.report.header.fee_payment_date, key: "fee_payment_date", "formatter": formatIsoDateToString },
                { label: strings.table.report.header.fee_balance, key: "fee_balance", filter: false },
                { label: strings.table.report.header.fee_status, key: "fee_status", "formatter": formatStatusStringToString, filter: true, options: [{ "value": "pending", "label": "Pendiente" }, { "value": "paid", "label": "Pagado" }] }
            ];
        }

        if ("feesByInscription" === name) {
            return [
                //{ label: strings.table.report.header.company_id, key: "company_id", filter: true },
                { label: strings.table.report.header.company_legal_id, key: "company_legal_id", filter: true },
                { label: strings.table.report.header.inscription_id, key: "inscription_id", filter: true },
                { label: strings.table.report.header.company_name, key: "company_name", filter: true },
                { label: strings.table.report.header.inscription_payment_day, key: "inscription_payment_day", filter: false },
                //{ label: strings.table.report.header.fee_id, key: "fee_id", filter: false },
                { label: strings.table.report.header.fee_amount, key: "fee_amount", filter: false },
                { label: strings.table.report.header.fee_sequence, key: "fee_sequence", filter: false },
                { label: strings.table.report.header.fee_amount_paid, key: "fee_amount_paid", filter: false },
                { label: strings.table.report.header.fee_payment_date, key: "fee_payment_date", "formatter": formatIsoDateToString },
                { label: strings.table.report.header.fee_balance, key: "fee_balance", filter: false },
                { label: strings.table.report.header.fee_status, key: "fee_status", "formatter": formatStatusStringToString, filter: true, options: [{ "value": "pending", "label": "Pendiente" }, { "value": "paid", "label": "Pagado" }] }
            ];
        }
        if ("feesByInscriptionByDateRange" === name) {
            return [
                //{ label: strings.table.report.header.company_id, key: "company_id", filter: true },
                { label: strings.table.report.header.inscription_id, key: "inscription_id", filter: true },
                { label: strings.table.report.header.company_legal_id, key: "company_legal_id", filter: true },
                { label: strings.table.report.header.company_name, key: "company_name", filter: true },
                { label: strings.table.report.header.inscription_payment_day, key: "inscription_payment_day", filter: false },
                //{ label: strings.table.report.header.fee_id, key: "fee_id", filter: false },
                { label: strings.table.report.header.fee_amount, key: "fee_amount", filter: false },
                { label: strings.table.report.header.fee_sequence, key: "fee_sequence", filter: false },
                { label: strings.table.report.header.fee_amount_paid, key: "fee_amount_paid", filter: false },
                { label: strings.table.report.header.fee_payment_date, key: "fee_payment_date", "formatter": formatIsoDateToString },
                { label: strings.table.report.header.fee_balance, key: "fee_balance", filter: false },
                { label: strings.table.report.header.fee_status, key: "fee_status", "formatter": formatStatusStringToString, filter: true, options: [{ "value": "pending", "label": "Pendiente" }, { "value": "paid", "label": "Pagado" }] }
            ];
        }



        if ("feesByDateRange" === name) {
            return [
                //{ label: strings.table.report.header.company_id, key: "company_id", filter: true },
                { label: strings.table.report.header.inscription_id, key: "inscription_id", filter: true },
                { label: strings.table.report.header.company_legal_id, key: "company_legal_id", filter: true },
                { label: strings.table.report.header.company_name, key: "company_name", filter: true },
                { label: strings.table.report.header.inscription_payment_day, key: "inscription_payment_day", filter: false },
                //{ label: strings.table.report.header.fee_id, key: "fee_id", filter: false },
                { label: strings.table.report.header.fee_amount, key: "fee_amount", filter: false },
                { label: strings.table.report.header.fee_sequence, key: "fee_sequence", filter: false },
                { label: strings.table.report.header.fee_amount_paid, key: "fee_amount_paid", filter: false },
                { label: strings.table.report.header.fee_payment_date, key: "fee_payment_date", "formatter": formatIsoDateToString },
                { label: strings.table.report.header.fee_balance, key: "fee_balance", filter: false },
                { label: strings.table.report.header.fee_status, key: "fee_status", "formatter": formatStatusStringToString, filter: true, options: [{ "value": "pending", "label": "Pendiente" }, { "value": "paid", "label": "Pagado" }] }
            ];
        }

        if ("pendingFeesByDateRange" === name) {
            return [
                //{ label: strings.table.report.header.company_id, key: "company_id", filter: true },
                { label: strings.table.report.header.inscription_id, key: "inscription_id", filter: true },
                { label: strings.table.report.header.company_legal_id, key: "company_legal_id", filter: true },
                { label: strings.table.report.header.company_name, key: "company_name", filter: true },
                { label: strings.table.report.header.inscription_payment_day, key: "inscription_payment_day", filter: false },
                //{ label: strings.table.report.header.fee_id, key: "fee_id", filter: false },
                { label: strings.table.report.header.fee_amount, key: "fee_amount", filter: false },
                { label: strings.table.report.header.fee_sequence, key: "fee_sequence", filter: false },
                { label: strings.table.report.header.fee_amount_paid, key: "fee_amount_paid", filter: false },
                { label: strings.table.report.header.fee_payment_date, key: "fee_payment_date", "formatter": formatIsoDateToString },
                { label: strings.table.report.header.fee_balance, key: "fee_balance", filter: false },
                { label: strings.table.report.header.fee_status, key: "fee_status", "formatter": formatStatusStringToString, filter: true, options: [{ "value": "pending", "label": "Pendiente" }, { "value": "paid", "label": "Pagado" }] }
            ];
        }
        if ("defaulterFees" === name) {
            return [
                //{ label: strings.table.report.header.company_id, key: "company_id", filter: true },
                { label: strings.table.report.header.inscription_id, key: "inscription_id", filter: true },
                { label: strings.table.report.header.company_legal_id, key: "company_legal_id", filter: true },
                { label: strings.table.report.header.company_name, key: "company_name", filter: true },
                { label: strings.table.report.header.inscription_payment_day, key: "inscription_payment_day", filter: false },
                //{ label: strings.table.report.header.fee_id, key: "fee_id", filter: false },
                { label: strings.table.report.header.fee_amount, key: "fee_amount", filter: false },
                { label: strings.table.report.header.fee_sequence, key: "fee_sequence", filter: false },
                { label: strings.table.report.header.fee_amount_paid, key: "fee_amount_paid", filter: false },
                { label: strings.table.report.header.fee_payment_date, key: "fee_payment_date", "formatter": formatIsoDateToString },
                { label: strings.table.report.header.fee_balance, key: "fee_balance", filter: false },
                { label: strings.table.report.header.fee_status, key: "fee_status", "formatter": formatStatusStringToString, filter: true, options: [{ "value": "pending", "label": "Pendiente" }, { "value": "paid", "label": "Pagado" }] },
                { label: strings.table.report.header.diff_days, key: "diff_days", filter: true },
            ];
        }

        if ("defaulterFeesVersusAmountBalance" === name) {
            return [
                { label: strings.table.report.header.inscription_id, key: "inscription_id", filter: true },
                { label: strings.table.report.header.company_legal_id, key: "company_legal_id", filter: true },
                { label: strings.table.report.header.company_name, key: "company_name", filter: true },
                { label: strings.table.report.header.inscription_balance, key: "inscription_balance" },

            ];
        }

        if ("defaulterFeesVersusPaid" === name) {
            return [
                { label: strings.table.report.header.amount_paid, key: "amount_paid" },
                { label: strings.table.report.header.amount_pending, key: "amount_pending" }

            ];
        }



    }



    function refreshData() {
        
        if (reportCode === "feesByDateRange") {
            dispatch(feesByDateRangeAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }


        if (reportCode === "pendingFeesByDateRange") {
            dispatch(pendingFeesByDateRangeAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "feesByInscription") {
            dispatch(feesByInscriptionAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "feesByInscriptionByDateRange") {
            dispatch(feesByInscriptionByDateRangeAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "fees") {
            
            dispatch(getAllReportAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "defaulterFees") {
            dispatch(defaulterFeesAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "defaulterFeesVersusAmountBalance") {
            dispatch(defaulterFeesVersusAmountBalanceAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }

        if (reportCode === "defaulterFeesVersusPaid") {
            dispatch(defaulterFeesVersusPaidAction({
                "reportCode": reportCode,
                "params": paramsReport
            }));
        }
    }

    if ((path !== pageName && path !== "")) {
        if (Array.isArray(list) && list.length > 0) {
            dispatch(getAllConfirmedReportAction([]));
        };
        return (<></>);
    } else {


        //const conf = getConf(reportCode, strings);
        return (<>
            <MainPagetitle mainTitle="Reportes" pageTitle="Reportes" parentTitle={strings.page.home.title} />
            {canView &&
                <>
                    <div className="container-fluid">
                        <NavMenu />
                        <div className="row">
                            <div className="col-xl-12">
                                <FormReportSelector onRefreshData={refreshData}></FormReportSelector>
                            </div>
                        </div>
                    </div>
                    {reportCode === "feesByInscription" &&
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12"><FormReportFeesbyInscription></FormReportFeesbyInscription></div>
                            </div>
                        </div>
                    }

                    {reportCode === "feesByInscriptionByDateRange" &&
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12"><FormReportFeesbyInscription></FormReportFeesbyInscription></div>
                            </div>
                        </div>
                    }

                    {reportCode === "feesByDateRange" &&
                        <div className="container-fluid">
                            <FormReportFeesbyDateRange></FormReportFeesbyDateRange>
                        </div>
                    }

                    {reportCode === "pendingFeesByDateRange" &&
                        <div className="container-fluid">
                            <FormReportFeesbyDateRange></FormReportFeesbyDateRange>
                        </div>
                    }

                    {reportCode === "feesByInscriptionByDateRange" &&
                        <div className="container-fluid">
                            <FormReportFeesbyDateRange></FormReportFeesbyDateRange>
                        </div>
                    }

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                {reportCode === "fees" && <Grid list={list} title={"Datos"} conf={confFees} exportData={exportData} ></Grid>}
                                {reportCode === "feesByInscription" && <Grid list={list} title={"Datos"} conf={confFeesByInscription} exportData={exportData} ></Grid>}
                                {reportCode === "feesByInscriptionByDateRange" && <Grid list={list} title={"Datos"} conf={confFeesByInscriptionByDateRange} exportData={exportData} ></Grid>}
                                {reportCode === "feesByDateRange" && <Grid list={list} title={"Datos"} conf={confFeesByDateRange} exportData={exportData} ></Grid>}
                                {reportCode === "pendingFeesByDateRange" && <Grid list={list} title={"Datos"} conf={confPendingFeesByDateRange} exportData={exportData} ></Grid>}
                                {reportCode === "defaulterFees" && <Grid list={list} title={"Datos"} conf={confDefaulterFees} exportData={exportData} ></Grid>}
                                {reportCode === "defaulterFeesVersusAmountBalance" && <Grid list={list} title={"Datos"} conf={confDefaulterFeesVersusAmountBalance} exportData={exportData} ></Grid>}
                                {reportCode === "defaulterFeesVersusPaid" && <Grid list={list} title={"Datos"} conf={confDefaulterFeesVersusPaid} exportData={exportData} ></Grid>}
                            </div>
                        </div>
                    </div>


                </>

            }


        </>);
    }
};

export default Report;