import React, { useState, useEffect } from 'react';
import MainPagetitle from '../../layouts/MainPagetitle';
import NavMenu from '../Common/NavMenu/NavMenu.js';

import { useLocale } from "../../../context/Locale";

import {
    getAllAction
} from '../../../store/actions/ModelActions'


import { useSelector, useDispatch } from "react-redux";

import FormSettingNotifications from './Form/FormSettingNotifications.js'

const Setting = () => {
    const pageName = "settings"
    const model = "setting";
    const path = useSelector((store) => store.common.path);

    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const canViewAdmin = HasCapability(capabilities,'MENU_ADMIN');
    const canView = HasCapability(capabilities,'PAGE_SETTING_LIST');

    const list = useSelector((store) => store.setting.list);

    const ENABLE_AUTOSEND_NOTIFICATION_SETTING = list.find(i=> i.code === "ENABLE_AUTOSEND_NOTIFICATION");

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const [step, setStep] = useState("init");

    const dispatch = useDispatch();



    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction(model, {}));
            setStep("loaded");
        }

    }, [dispatch,step]);


    if (path !== pageName && path !== "") {
        return (<></>);
    } else {

        return (
            <>
                <MainPagetitle mainTitle={strings.page.capability.title} pageTitle={strings.page.capability.title} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {(canViewAdmin && canView) &&
                        <div className="row">
                            <FormSettingNotifications
                             conf={ENABLE_AUTOSEND_NOTIFICATION_SETTING}
                            ></FormSettingNotifications>
                        </div>
                    }
                </div>
            </>
        );
    }




};

export default Setting;