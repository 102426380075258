import { useSelector, useDispatch } from "react-redux";
import { updateDataFormNewFormAction } from '../../../../store/actions/ModelActions.js'

import { setDataAction } from '../../../../store/actions/ModelActions.js'

import Select2RoleCode from '../Input/Select2RoleCode.js'

const FormNewUserRole = (props) => {
    const model = "userrole";
    const dispatch = useDispatch();

    const dataForm = useSelector((store) => store.userrole.dataNewForm);
    const roles = useSelector((store) => store.role.list);
    const role = useSelector((store) => store.role.data);

    function onChange(id, value) {
        let dataFormNew = { ...dataForm };
        dataFormNew[id] = value;
        if (id === "role_code") {
            let roleNew = roles.find(c => c.code === value);
            dispatch(setDataAction('role',roleNew));
        }

        dispatch(updateDataFormNewFormAction(model,dataFormNew));
    }

    if (props === undefined) {
        return (<div className="row"></div>)
    } else {
     

        let rolesList = roles.map(function (role) {
            return {
                "value": role.code,
                "label": role.description,
            };
        });

        const roleSelected = rolesList.find(r=> r.value === role.code);      


        return (
            <div>
                <div className="row">
                    {roleSelected && (
                    <Select2RoleCode defaultValue={roleSelected} options={rolesList} onChange={onChange}  ></Select2RoleCode>
                    )}
                    {!roleSelected && (
                    <Select2RoleCode options={rolesList} onChange={onChange}  ></Select2RoleCode>
                    )}
                </div>
            </div>
        )
    }


}
export default FormNewUserRole;