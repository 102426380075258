import React, { useState, useEffect } from 'react';

import { useLocale } from "../../../../../context/Locale";
import Input from '../../../Base/Input.js'
import { GetSalesLevelName } from '../../../../../tools/saleslevel.js'
import { GetSizeName } from '../../../../../tools/size.js'
import { getAction } from '../../../../../store/actions/ModelActions.js'
import { useDispatch, useSelector } from "react-redux";

const FormCompanyView = (props) => {

    const dispatch = useDispatch();
    const model = "company";
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const data = useSelector((store) => store.company.data);

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});

    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }

    useEffect(() => {

        if (step === "init") {
            if (props.data !== undefined && props.data.id !== undefined) {
                dispatch(getAction(model, {
                    id: props.data.id
                }));
            }
            setStep("edit");

        } else {
            setValue({ ...data });
        }


    }, [step, data]);


    if (getValue("id", "") === "") {
        return (<></>);
    } else {
        return (<>
            <div className="row">
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"legal_id"} className="form-label">{strings.form.addCompany.labelLegalId}<span className="text-danger">*</span></label>
                    <Input value={getValue("legal_id", "")} disabled={true} htmlFor="legal_id" type="text" id="legal_id" placeholder=""  ></Input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"name"} className="form-label">{strings.form.addCompany.labelName}<span className="text-danger">*</span></label>
                    <Input value={getValue("name", "")} disabled={true} htmlFor="name" type="text" id="name" placeholder=""  ></Input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"category"} className="form-label">{strings.form.addCompany.labelCategory}<span className="text-danger">*</span></label>
                    <Input value={getValue("category", "")} disabled={true} htmlFor="category" type="text" id="category" placeholder=""  ></Input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"size"} className="form-label">{"Tamaño"}<span className="text-danger">*</span></label>
                    <Input value={GetSizeName(getValue("size", ""))} disabled={true} htmlFor="size" type="text" id="size" placeholder=""  ></Input>
                </div>

                <div className="col-xl-3 mb-3">
                    <label htmlFor={"sales_level"} className="form-label">{strings.form.addCompany.labelSalesLevel}<span className="text-danger">*</span></label>
                    <Input value={GetSalesLevelName(getValue("sales_level", ""))} disabled={true} htmlFor="sales_level" type="text" id="sales_level" placeholder=""  ></Input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"branch_number"} className="form-label">{"Número de Locales"}<span className="text-danger">*</span></label>
                    <Input value={getValue("branch_number", 0)} disabled={true} htmlFor="branch_number" type="number" id="branch_number" placeholder=""  ></Input>
                </div>


            </div>
        </>);
    }


}

export default FormCompanyView;