import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    updateAction,
    filterAction,
    getAllAction
} from '../../../../store/actions/ModelActions.js'

import { Button } from "react-bootstrap";
import Select2 from '../../Base/Select2'

import ModalConfirm from '../../Common/Modal/ModalConfirm';
import Address from '../../Common/Address/Address.js';
import { useLocale } from "../../../../context/Locale";

import MainPagetitle from '../../../layouts/MainPagetitle.js';
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'

const CompanyEdit = (props) => {


    const { stateLocale } = useLocale();
    const { strings } = stateLocale;


    const people = useSelector((store) => store.person.list);
    const data = useSelector((store) => store.company.dataEditForm);
    const capabilities = useSelector((store) => store.user.capabilities);

    const [showComfirm, setShowConfirm] = useState(false);
    const model = "company";

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
    const [toList, setToList] = useState(false);

    const dispatch = useDispatch();
    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }

    useEffect(() => {

        if (step === "init") {

            const person = people.find(i => String(i.id) === (data.representative_id || ""));
            setValue({
                "id": data.id || 0,
                "name": data.name || "",
                "legal_id": data.legal_id || "",
                "representative_id": data.representative_id || "",
                "representative_id_selected": person? person.id : "",
                "category": data.category || "",
                "size": data.size || "",
                "size_selected": data.size || "",
                "sales_level": data.sales_level || "",
                "sales_level_selected": data.sales_level || ""
            });

            dispatch(getAllAction("person",{}));

            if (data.legal_id !== undefined && data.legal_id !== "") {
                dispatch(filterAction("address", { "company_id": data.id || 0 }));
            }

        }

        if (step !== "edit") {
            setStep("edit");
        }

    }, [dispatch, step, data,people]);



    const saleLevelValues = [].concat([
        { "label": "Alta", "value": "alta" },
        { "label": "Media", "value": "media" },
        { "label": "Baja", "value": "baja" }
    ]);

    const sizeValues = [].concat([
        { "label": "Micro", "value": "micro" },
        { "label": "Mediana", "value": "mediana" },
        { "label": "Grande", "value": "grande" }
    ]);


    const buildOptions = (list) => {
        return list.map((item) => { return { "label": item.label, "value": item.value } })
    }

    const buildOptionsPeople = (list) => {
        return [{ "label": "Seleccione", "value": 0 }].concat(list.map((item) => { return { "label": item.legal_id + " " + item.first_name + " " + item.last_name, "value": item.id } }));
    }
    const getOptionValue = (list, value) => {
        return list.find(i => String(i.value) === String(value))
    }

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;;
        if (id === "representative_id_selected") {
            let itemNew = people.find(c => String(c.id) === String(data));
            if (itemNew !== undefined) {
                newValue["representative_id"] = itemNew.legal_id;
            }

        }

        setValue(newValue);
    }


    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {
        setToList(true);
        const request = {
            "id": value.id,
            "name": value.name,
            "legal_id": value.legal_id,
            "representative_id": value.representative_id,
            "category": value.category,
            "size": value.size_selected,
            "sales_level": value.sales_level_selected
        };

        dispatch(updateAction(model, request));
        setShowConfirm(false);
    }

    function onCancelForm() {
        setShowConfirm(false);
    }

    const onChangeAddress = () => {
        if (getValue("legal_id", "") !== "") {
            dispatch(filterAction("address", { "company_id": getValue("id", 0) }));
        }
    }

    if (props === undefined || toList) {
        return (<> {toList && (<Navigate to={routers.company.admin} replace={true} />)}</>)
    } else if (!capabilities.map(function (c) { return c.code }).includes('COMPANY_EDIT')) {
        return (<></>);
    } else {

        const sizeList = buildOptions(sizeValues);
        const sizeValue = getOptionValue(sizeList, getValue("size_selected", ""));

        const saleLevelList = buildOptions(saleLevelValues);
        const saleLevelValue = getOptionValue(saleLevelList, getValue("sales_level_selected", ""));

        const personList = buildOptionsPeople(people);
        const personValue = getOptionValue(personList, getValue("representative_id_selected", ""));


        return (
            <>
                <MainPagetitle mainTitle={"Empresa"} pageTitle={"Editar Empresa"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Edición de Datos de Empresa</h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="row">
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"name"} className="form-label">Nombre<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"name"} type="text" value={getValue("name", "")} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"legal_id"} className="form-label">Rut<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"legal_id"} type="text" value={getValue("legal_id", "")} className="form-control" />
                                                </div>

                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"representative_id_selected"} className="form-label">Representante</label>
                                                    {(personValue !== undefined) &&
                                                        <Select2 defaultValue={personValue} onChange={onChange} options={personList} id={"representative_id_selected"} ></Select2>
                                                    }
                                                    {(personValue === undefined) &&
                                                        <Select2 onChange={onChange} options={personList} id={"representative_id_selected"} ></Select2>
                                                    }
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"category"} className="form-label">Rubro<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"category"} type="text" value={getValue("category", "")} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"size_selected"} className="form-label">Tamaño</label>
                                                    {(sizeValue !== undefined) &&
                                                        <Select2 onChange={onChange} defaultValue={sizeValue} options={sizeList} id={"size_selected"} ></Select2>
                                                    }
                                                    {(sizeValue === undefined) &&
                                                        <Select2 onChange={onChange} options={sizeList} id={"size_selected"} ></Select2>
                                                    }
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"sales_level_selected"} className="form-label">Nivel de Venta</label>
                                                    {(saleLevelValue !== undefined) &&
                                                        <Select2 onChange={onChange} defaultValue={saleLevelValue} options={saleLevelList} id={"sales_level_selected"} ></Select2>
                                                    }
                                                    {(saleLevelValue === undefined) &&
                                                        <Select2 onChange={onChange} options={saleLevelList} id={"sales_level_selected"} ></Select2>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <Button onClick={() => onSaveFormConfirm()} className="btn-sm" variant="primary"> {"Enviar"}</Button>
                                            </div>
                                            <ModalConfirm
                                                show={showComfirm}
                                                title={"Confirmación"}
                                                message={"¿Está seguro de editar la empresa"}
                                                cancelButtonText={"No"} confirmButtonText={"Sí"}
                                                onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                                            >
                                            </ModalConfirm>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12"> <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Direcciones</h4>
                            </div>
                            <div className="card-body">
                                <div className="basic-form">
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <Address
                                                companyId={getValue("id", "")}
                                                onChange={onChangeAddress}
                                            ></Address>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>




                </div>

            </>
        )
    }
}

export default CompanyEdit;