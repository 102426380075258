import { Logout, isLogged } from './CommonActions';
import { enableReloadAction} from './UserActions';
import { destroy } from '../../services/UserService';

export const DESTROY_CONFIRMED_ACTION = '[user destroy action] confirmed destroy';
export const DESTROY_FAILED_ACTION = '[user destroy action] failed destroy';


export function destroyAction(data) {
    return  (dispatch) => {
        
        destroy(data).then(response => {            
            if (isLogged(response)) {
                dispatch(destroyConfirmedAction(response.data.response || {}));	
                dispatch(enableReloadAction());
            } else {
                dispatch(Logout());
            }
        });
    };
}

export function destroyConfirmedAction(data) {
    return {
        type: DESTROY_CONFIRMED_ACTION,
        payload: data,
    };
}


export function destroyFailedAction(data) {
    return {
        type: DESTROY_FAILED_ACTION,
        payload: data,
    };
}
