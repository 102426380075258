import FormDate from '../../Form/FormDate.js'
import { useLocale } from "../../../../context/Locale/index.js";

const InputPaymentPaymentDate = (props) => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    return (
        <FormDate { ...props}   textlabel={strings.form.addPayment.labelPaymentDate} htmlFor="payment_date"  id="payment_date" ></FormDate>
    )
}
export default InputPaymentPaymentDate;