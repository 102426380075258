import { useSelector, useDispatch } from "react-redux";
import { updateDataFormNewFormAction } from '../../../../store/actions/ModelActions.js'

import InputRoleCode from '../Input/InputRoleCode.js'
import InputRoleDescription from '../Input/InputRoleDescription.js'

const FormNewRole = (props) => {

    const model = "role";
    const dispatch = useDispatch();

    //Datos para crear una persona
    const dataForm = useSelector((store) => store.role.dataNewForm);

    // Manejador de cambios en los datos de la nueva persona
    function onChange(id, value) {
        let dataFormNew = { ...dataForm };
        dataFormNew[id] = value;
        dispatch(updateDataFormNewFormAction(model,dataFormNew));
    }

    if (props === undefined) {
        return (<div className="row"></div>)
    } else {
        let secureValues = {
            "code": dataForm.code || "",
            "description": dataForm.description || ""
        }

        return (
            <div className="row">
                <InputRoleCode value={secureValues.code} onChange={onChange}  ></InputRoleCode>
                <InputRoleDescription value={secureValues.description} onChange={onChange}  ></InputRoleDescription>
            </div>
        )
    }


}
export default FormNewRole;