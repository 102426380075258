
import React from 'react';
import { useSelector } from "react-redux";
import { formatIsoDateToDate } from '../../../../tools/fomatter.js'
import DateInput from '../../Base/DateInput.js'

const FormViewPayment = (props) => {

    const dataForm = useSelector((store) => store.payment.dataEditForm);
    const capabilities = useSelector((store) => store.user.capabilities);
    const canView = capabilities.map(function (c) { return c.code }).includes('PAYMENT_GET');

    const value = {
        "company_legal_id": (dataForm.company_legal_id || "") + " " + (dataForm.company_name || ""),
        "amount": dataForm.amount || 0,
        "payment_date": (typeof dataForm.payment_date === "string") ? formatIsoDateToDate(dataForm.payment_date) : dataForm.payment_date,
        "comment": dataForm.comment || ""
    };

    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }


    if (props === undefined) {
        return (<></>);
    } else {
        return (
            <>  {canView && 
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Datos</h4>
                    </div>
                    <div className="card-body">
                        <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-3 mb-3">
                                        <label htmlFor={"company_legal_id"} className="form-label">Rut<span className="text-danger">*</span></label>
                                        <input id={"company_legal_id"} type="text"  disabled={true}   value={getValue("company_legal_id", 0)} className="form-control" />
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <label htmlFor={"amount"} className="form-label">Monto<span className="text-danger">*</span></label>
                                        <input id={"amount"} type="number"  disabled={true}  value={getValue("amount", 1)} min={1} className="form-control" />
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <div className="row" ><label htmlFor={"payment_date"} className="form-label">Fecha de inicio<span className="text-danger">*</span></label></div>
                                        <div className="row" ><DateInput  disabled={true}  selected={getValue("payment_date", new Date())} ></DateInput></div>
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <label htmlFor={"comment"} className="form-label">Comentario<span className="text-danger">*</span></label>
                                        <input id={"comment"} type="text"  disabled={true}  value={getValue("comment", "")} className="form-control" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                }
            </>


        )
    }




}
export default FormViewPayment;