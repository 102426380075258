import React from 'react';
import { Button } from "react-bootstrap";

/**
 * 
 * @param {*} props 
 * records
 * conf
 * onClickDestroy
 * onClickEdit
 * @returns 
 */
export const GridBody = (props) => {

    const records = props ? Array.isArray(props.records) ? props.records : [] : [];
    const conf = props ? Array.isArray(props.conf) ? props.conf : [] : [];
    const destroyItem = props ? props.destroyItem ? props.destroyItem : false : false;
    const editItem = props ? props.editItem ? props.editItem : false : false;
    const viewItem = props ? props.viewItem ? props.viewItem : false : false;
    const selectItem = props ? props.selectItem ? props.selectItem : false : false;
    const selectItemLabel = props ? props.selectItemLabel ? props.selectItemLabel : "Seleccionar" : "Seleccionar";


    function onClickDestroy(item) {
        if (props.onClickDestroy !== undefined) {
            props.onClickDestroy(item);
        }
    }

    function onClickEdit(item) {
        if (props.onClickEdit !== undefined) {
            props.onClickEdit(item);
        }
    }

    function onClickView(item) {
        if (props.onClickView !== undefined) {
            props.onClickView(item);
        }
    }

    function onClickSelect(item) {
        if (props.onClickSelect !== undefined) {
            props.onClickSelect(item);
        }
    }

    return (
        <tbody>
            {records.map((item, index) => (
                <tr key={index}>
                    {conf.map((item2, index2) => (
                        <td key={index2}>
                            {item[item2.key] !== undefined && (
                                <span className={item2.className || ""}  >{(item2.formatter ? item2.formatter(item[item2.key]) : item[item2.key])}</span>
                            )}
                        </td>
                    ))}
                    <td>
                        <div className="card-body">
                            {destroyItem && (<Button onClick={() => onClickDestroy(item)} className="me-2 btn-sm" variant="danger light">Eliminar</Button>)}
                            {editItem && (<Button onClick={() => onClickEdit(item)} className="me-2 btn-sm" variant="primary light">Editar</Button>)}
                            {viewItem && (<Button onClick={() => onClickView(item)} className="me-2 btn-sm" variant="primary light">Ver</Button>)}
                            {selectItem && (<Button onClick={() => onClickSelect(item)} className="me-2 btn-sm" variant="primary light">{selectItemLabel}</Button>)}
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

export default GridBody;