import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    enableReloadAction
} from '../../../../store/actions/ModelActions.js'

import Select2 from '../../Base/Select2.js'
import DateInput from '../../Base/DateInput.js'

import { formatIsoDateToDate } from '../../../../tools/fomatter.js'

const FormNewPayment = (props) => {
    const dispatch = useDispatch();

    const inscriptions = useSelector((store) => store.inscription.list);
    const fees = useSelector((store) => store.fee.list);

    const [step, setStep] = useState("init");
    const [inscription, setInscription] = useState({});
    const [fee, setFee] = useState({});
    const [value, setValue] = useState({
        "amount": 0,
        "payment_date": new Date(),
        "comment": "",
        "inscription_id": inscription ? (inscription.id || 0) : 0,
    });


    const buildOptionsInscriptions = (list) => {
        return list.map((item) => { return { "label":  item.company_name + " ( Rut " + item.company_legal_id + ")", "value": item.id } })
    }

    const buildOptionsFees = (list) => {
        return [{ "label": "Seleccione", "value": "" }].concat(list.map((item) => { return { "label": item.fee_payment_date + " ( pendiente $ " + item.fee_balance + ")", "value": item.fee_id } }))
    }


    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }

    const getOptionValue = (list, value) => {
        return list.find(i => String(i.value) === String(value))
    }

    const updateComponents = () => {

        if (inscription.id !== undefined) {
            setValue({
                ...value,
                inscription_id: inscription.id
            });

        }

        if (fee.fee_id !== undefined) {
            setValue({
                ...value,
                amount: fee.fee_amount,
                payment_date: formatIsoDateToDate(fee.fee_payment_date)
            });
        }
    }

    useEffect(() => {

        if (step === "init") {
            dispatch(enableReloadAction("fee", {}));

        }

        if (step !== "edit") {
            setStep("edit");
        }

    }, [dispatch, step]);

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;
        setValue(newValue);

        if (id === "inscription_id_selected") {
            const itemNew = inscriptions.find(c => String(c.id) === String(data));
            setInscription(itemNew !== undefined ? { ...itemNew } : {});
            if (itemNew !== undefined) {
                newValue = { ...newValue, inscription_id: itemNew.id };
            }


        } else if (id === "fee_id_selected") {
            const itemNew = fees.find(f => String(f.fee_id) === String(data));
            setFee(itemNew !== undefined ? { ...itemNew } : {})
            if (itemNew !== undefined) {
                newValue = {
                    ...newValue,
                    amount: itemNew.fee_amount,
                    payment_date: formatIsoDateToDate(itemNew.fee_payment_date)
                };
            }
        }
        setValue(newValue);
        //updateComponents();

    }

    if (props === undefined) {
        return (
            <></>
        )

    } else {

        const itemList = buildOptionsFees(fees.filter(i => (inscription.id === undefined) ? false : (String(i.inscription_id) === String(inscription.id))));
        const itemValue = getOptionValue(itemList, getValue("fee_id_selected", ""));

        return (
            <>
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Datos</h4>
                    </div>
                    <div className="card-body">
                        <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="col-xl-6 mb-6">
                                        <label htmlFor={"inscription_id_selected"} className="form-label">Inscripción</label>
                                        <Select2 onChange={onChange} options={buildOptionsInscriptions(inscriptions)} id={"inscription_id_selected"} placheholder={"Seleccione..."} ></Select2>
                                    </div>
                                    <div className="col-xl-6 mb-6">
                                        <label htmlFor={"fee_id_selected"} className="form-label">Cuota</label>
                                        {(itemValue !== undefined) &&
                                            <Select2 onChange={onChange} defaultValue={itemValue} options={itemList} id={"fee_id_selected"} placheholder={"Seleccione..."} ></Select2>
                                        }
                                        {(itemValue === undefined) &&
                                            <Select2 onChange={onChange} options={itemList} id={"fee_id_selected"} placheholder={"Seleccione..."} ></Select2>
                                        }
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <label htmlFor={"amount"} className="form-label">Monto<span className="text-danger">*</span></label>
                                        <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"amount"} type="number" value={getValue("amount", 1)} min={1} className="form-control" />
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <div className="row" ><label htmlFor={"payment_date"} className="form-label">Fecha<span className="text-danger">*</span></label></div>
                                        <div className="row" ><DateInput onChange={onChange} id={"payment_date"} selected={getValue("payment_date", new Date())} ></DateInput></div>
                                    </div>
                                    <div className="col-xl-3 mb-3">
                                        <label htmlFor={"comment"} className="form-label">Comentario<span className="text-danger">*</span></label>
                                        <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"comment"} type="text" value={getValue("comment", "")} className="form-control" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default FormNewPayment;