import React, { useState} from 'react';
import { useSelector, useDispatch } from "react-redux";


import { enableReloadAction as enableReloadRegionAction } from '../../../../store/actions/RegionFilterActions';
import { enableReloadAction as enableReloadProvinceAction } from '../../../../store/actions/ProvinceFilterActions';
import { enableReloadAction as enableReloadCommuneAction } from '../../../../store/actions/CommuneFilterActions';

import { destroyAction } from '../../../../store/actions/AddressFilterActions';

import Grid from '../../Common/Grid/Grid';
import FormNewAddress from './Form/FormNewAddress'
import FormEditAddress from './Form/FormEditAddress'

import ModalConfirm from '../../Common/Modal/ModalConfirm';

const Address = (props) => {

    const [showNewForm, setShowNewForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);
    const [toDestroy, setToDestroy] = useState({});

    const [dataEditForm, setDataEditForm] = useState({});

    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const list = useSelector((store) => store.address.list);
    const companyId = props? (props.companyId? props.companyId: "") : "";


    const canView = HasCapability(capabilities,"PAGE_ADDRESS_LIST");
    const newItem = HasCapability(capabilities,"BTN_ADDRESS_CREATE");
    const editItem = HasCapability(capabilities,"BTN_ADDRESS_EDIT");
    const destroyItem = HasCapability(capabilities,"ADDRESS_DESTROY");
    const exportData = HasCapability(capabilities,"ADDRESS_EXPORT");

    const dispatch = useDispatch();

    const gridConf = [
        { label: "Región", key: "region_name", className: "" },
        { label: "Provincia", key: "province_name", className: "" },
        { label: "Comuna", key: "commune_name", className: "" },
        { label: "Calle", key: "street", className: "" },
        { label: "Número", key: "number", className: "" },
        { label: "Código Postal", key: "postCode", className: "" },
        
        
        
    
    ]

    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
    }

    function onDestroyForm() {
        dispatch(destroyAction(toDestroy));
        setShowConfirmDestroy(false);
        if(props.onChange !== undefined){
            props.onChange();
        }
    }

    function onCancelForm() {
        setShowConfirmDestroy(false);
    }

    function onClickNewForm() {
        dispatch(enableReloadRegionAction());
        setShowNewForm(true);
    }

    function onClickEditForm(item) {
        dispatch(enableReloadRegionAction());
        dispatch(enableReloadProvinceAction());
        dispatch(enableReloadCommuneAction());
        let itemNew = { ...item };
        setDataEditForm(itemNew);
        setShowEditForm(true);
        
    }

    function onClickCancelNewForm() {
        setShowNewForm(false);
        setShowEditForm(false);
    }

    function onClickSaveNewForm() {
        
        setShowNewForm(false);
        setShowEditForm(false);
        if(props.onChange !== undefined){
            props.onChange();
        }
    }

    if (!canView) {
        return (<></>);
    } else {
        return (
            <>
                { (newItem && showNewForm) && 
                <FormNewAddress
                onCancel={onClickCancelNewForm}
                onSave={onClickSaveNewForm}
                companyId={companyId}
                ></FormNewAddress>
                }
                { (editItem && showEditForm) && 
                <FormEditAddress
                data={dataEditForm}
                onCancel={onClickCancelNewForm}
                onSave={onClickSaveNewForm}
                ></FormEditAddress>
                }
                {(!showNewForm && !showEditForm)  && 
                <Grid
                    list={list}
                    conf={gridConf}
                    hidePaginator={true}

                    onClickNewItem={onClickNewForm}
                    buttonAddLabel={"+ Nueva Dirección"}
                    onClickDestroy={onDestroyFornConfirm}
                    onClickEdit={onClickEditForm}

                    newItem={newItem}
                    editItem={editItem}
                    destroyItem={destroyItem}
                    exportData={exportData}

                ></Grid>
                }
                <ModalConfirm
                    show={showComfirmDestroy}
                    title={"Confirmación"}
                    message={"¿Está seguro de eliminar esta dirección"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                >
                </ModalConfirm>
            </>
        );

    }
};

export default Address;