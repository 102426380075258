
export const RELOAD_ENABLE_ACTION = '[user reload action] enable reload';
export const RELOAD_DISABLE_ACTION = '[user reload action] disable reload';

export const RELOADCAPABILITY_ENABLE_ACTION = '[user reload capability action] enable reload capability';
export const RELOADCAPABILITY_DISABLE_ACTION = '[user reload capability action] disable reload capability';



export function enableReloadAction() {
    return {
        type: RELOAD_ENABLE_ACTION,
        payload: true,
    };
}

export function disableReloadAction() {
    return {
        type: RELOAD_DISABLE_ACTION,
        payload: false,
    };

}

export function enableReloadCapabilityAction() {
    return {
        type: RELOADCAPABILITY_ENABLE_ACTION,
        payload: true,
    };
}

export function disableReloadCapabilityAction() {
    return {
        type: RELOADCAPABILITY_DISABLE_ACTION,
        payload: false,
    };

}