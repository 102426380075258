import { useSelector, useDispatch } from "react-redux";
import DateInput from '../../Base/DateInput'

import { updateParamsFormReportAction, getAllConfirmedAction } from '../../../../store/actions/ReportListActions.js'


const FormReportFeesbyDateRange = (props) => {
    const dispatch = useDispatch();

    const params = useSelector((store) => store.report.params);
    const reportCode = useSelector((store) => store.report.reportCode);

    function onChange(id, value) {
        let paramsNew = { ...params };
        paramsNew[id] = value;
        dispatch(updateParamsFormReportAction(paramsNew));
        dispatch(getAllConfirmedAction([]));
    }


    if (props === undefined || !(reportCode === "feesByDateRange" || reportCode === "pendingFeesByDateRange" || reportCode === "feesByInscriptionByDateRange")) {
        return (<div className="row"></div>)
    } else {


        let secureValues = {
            "fromDate": params.fromDate || (new Date()).setMonth((new Date()).getMonth() - 1),
            "toDate": params.toDate || new Date(),
        }

        return (
            <div className='row'>
                <div className="col-xl-3 col-lg-3">
                    <div className="mb-3">
                        <label className="col-sm-3 col-form-label">Desde</label>
                        <div className="col-sm-9">
                            <DateInput selected={secureValues.fromDate} onChange={onChange} id="fromDate"  ></DateInput>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                    <div className="mb-3">
                        <label className="col-sm-3 col-form-label">Hasta</label>
                        <div className="col-sm-9">
                            <DateInput selected={secureValues.toDate} onChange={onChange} id="toDate"  ></DateInput>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormReportFeesbyDateRange;