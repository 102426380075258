import FormNewUser from '../../User/Form/FormNewUser.js'
import FormEditUser from '../../User/Form/FormEditUser.js'
import FormNewPayment from '../../Payment/Form/FormNewPayment.js'
import FormEditPayment from '../../Payment/Form/FormEditPayment.js'
import FormNewRole from '../../Role/Form/FormNewRole.js'
import FormEditRole from '../../Role/Form/FormEditRole.js'


import FormNewUserRole from '../../UserRole/Form/FormNewUserRole.js'
import FormEditUserRole from '../../UserRole/Form/FormEditUserRole.js'

const InputForm = (props) => {
    if (props === undefined) {
        return ( <div className="row"></div>)

    } if (props.TypeForm === undefined) {     
        return (<div className="row"></div>)
    }  else if (props.TypeForm === "FormNewPayment") {
        return (<FormNewPayment Strings={props.Strings} onChange={props.onChange} Data={props.Data} ></FormNewPayment>)
    } else if (props.TypeForm === "FormEditPayment") {
        return (<FormEditPayment Strings={props.Strings} onChange={props.onChange} Data={props.Data} ></FormEditPayment>)
    } else if (props.TypeForm === "FormNewUser") {
        return (<FormNewUser Strings={props.Strings}  onChange={props.onChange} Data={props.Data} ></FormNewUser>)
    }  else if (props.TypeForm === "FormEditUser") {
        return (<FormEditUser Strings={props.Strings} onChange={props.onChange} Data={props.Data} ></FormEditUser>)
    } else if (props.TypeForm === "FormNewRole") {
        return (<FormNewRole Strings={props.Strings}></FormNewRole>)
    } else if (props.TypeForm === "FormEditRole") {
        return (<FormEditRole Strings={props.Strings} ></FormEditRole>)
    } else if (props.TypeForm === "FormNewUserRole") {
        return (<FormNewUserRole Strings={props.Strings}></FormNewUserRole>)
    } else if (props.TypeForm === "FormEditRoleCapability") {
        return (<FormEditUserRole Strings={props.Strings} ></FormEditUserRole>)
    } else {
        return (<div className="row"></div>)
    }


}
export default InputForm;