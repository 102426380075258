import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import PostsReducer, {toggleMenu} from './reducers/PostsReducer';
import {thunk} from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';

import {PersonReducer} from './reducers/PersonReducer'
import {UserReducer} from './reducers/UserReducer'
import {UserRoleReducer} from './reducers/UserRoleReducer'
import {CompanyReducer} from './reducers/CompanyReducer'
import {PaymentReducer} from './reducers/PaymentReducer'
import {InscriptionReducer} from './reducers/InscriptionReducer'
import {CommonReducer} from './reducers/CommonReducer'
import {ReportReducer} from './reducers/ReportReducer'
import {CapabilityReducer} from './reducers/CapabilityReducer'
import {RoleReducer} from './reducers/RoleReducer'
import {RoleCapabilityReducer} from './reducers/RoleCapabilityReducer'
import {FeeReducer} from './reducers/FeeReducer'
import {FeeTemplateReducer} from './reducers/FeeTemplateReducer'
import {NotificationReducer} from './reducers/NotificationReducer'
import {AddressReducer} from './reducers/AddressReducer'

import {RegionReducer} from './reducers/RegionReducer'
import {ProvinceReducer} from './reducers/ProvinceReducer'
import {CommuneReducer} from './reducers/CommuneReducer'

import {PaginateFeeReducer} from './reducers/PaginateFeeReducer'
import {SettingReducer} from './reducers/SettingReducer'
import {ExtensionReducer} from './reducers/ExtensionReducer'







//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    sideMenu: toggleMenu,
    posts: PostsReducer,
    auth: AuthReducer,
		todoReducers,
	//form: reduxFormReducer,	
    person: PersonReducer,
    userrole: UserRoleReducer,
    user: UserReducer,
    common: CommonReducer,
    company: CompanyReducer,
    inscription: InscriptionReducer,
    payment: PaymentReducer,
    report: ReportReducer,
    capability: CapabilityReducer,
    role: RoleReducer,
    rolecapability: RoleCapabilityReducer,
    fee: FeeReducer,
    feetemplate: FeeTemplateReducer,
    notification: NotificationReducer,
    address: AddressReducer,
    region: RegionReducer,
    province: ProvinceReducer,
    commune: CommuneReducer,
    paginateFee: PaginateFeeReducer,
    setting: SettingReducer,
    extension: ExtensionReducer
});

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));
