export function GetSalesLevelName(code) {
    if (code === "alta") {
        return "Alta"
    } else if (code === "media") {
        return "Media"
    } else if (code === "baja") {
        return "Baja"
    } else { return "No indicado"; }
}


export const SaleLevelOptions = [
    { "label": "Alta", "value": "alta" },
    { "label": "Media", "value": "media" },
    { "label": "Baja", "value": "baja" }
];