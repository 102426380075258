import { toast } from "react-toastify";

export function ProcessMessages(data){
    if( Array.isArray(data.errors)){
        data.errors.forEach((err) => formatError(err));
    }
    if( Array.isArray(data.messages)){
        data.messages.forEach((err) => formatError(err));
    }
}

export function formatError(response) {
   
    switch (response.code) {
        case 'MSG_SUCCESSFUL':
              toast.success("✔️ " + response.message + "!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
              });
            break;
        case 'MSG_ERROR':
            toast.error("❌ " + response.message + "!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
              });
            break;            
        default:
            return '';
    }
}