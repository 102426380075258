
import Input from '../Base/Input.js'

const FormInput = (props) => {
    
    if( props === undefined){
        <div className="col-xl-6 mb-3"></div>
    }else {
        return (
            <div className="col-xl-6 mb-3">
            <label htmlFor={props.htmlFor} className="form-label">{props.textlabel}<span className="text-danger">*</span></label>
            <Input { ...props}  ></Input>
        </div>
        )
    }
}
export default FormInput;