import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
    storeAction
} from '../../../../store/actions/ModelActions.js'

import ModalConfirm from '../../Common/Modal/ModalConfirm';
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import MainPagetitle from '../../../layouts/MainPagetitle';

import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'

import { useLocale } from "../../../../context/Locale";

const CapabilityNew = () => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const model = "capability";
    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);
    const canCreate = HasCapability(capabilities,'CAPABILITY_CREATE')

    const dispatch = useDispatch();

    const [showComfirm, setShowConfirm] = useState(false);
    const [toList, setToList] = useState(false);
    const [value, setValue] = useState({
        code: "",
        description: "",
        group: "",
        action: ""
    });

    const getValue = (id, defaultValue) => {
        return value[id] ? value[id] : defaultValue;
    }

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;
        setValue(newValue);

    }

    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {

        let request = {
            code: value.code,
            description: value.description,
            group: value.group,
            action: value.action
        }
        dispatch(storeAction(model, request));
        setShowConfirm(false);
        setToList(true);
    }

    function onCancelForm() {
        setShowConfirm(false);
    }

    if (toList) {
        return (<>  {toList && (<Navigate to={routers.capability.admin} replace={true} />)}</>)
    } else {
        return (
            <>
                <MainPagetitle mainTitle={"Capacidad"} pageTitle={"Nueva Capacidad"} parentTitle={strings.page.home.title} />
                {canCreate &&
                    <div className="container-fluid">
                        <NavMenu />
                        <div className="row">
                            <div className="col-xl-12"> <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Capacidad</h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="row">
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"code"} className="form-label">Código<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"code"} type="text" value={getValue("code", "")} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"grupo"} className="form-label">Grupo<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"group"} type="text" value={getValue("group", "")} className="form-control" />
                                                </div>                                                
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"action"} className="form-label">Acción<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"action"} type="text" value={getValue("action", "")} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"description"} className="form-label">Descripción<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"description"} type="text" value={getValue("description", "")} className="form-control" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-3 mb-3">
                                    <Button className="btn-sm" onClick={() => onSaveFormConfirm()} variant="primary"> {"Enviar"}</Button>
                                </div>
                            </div>

                        </div>
                        <ModalConfirm
                            show={showComfirm}
                            title={"Confirmación"}
                            message={"¿Está seguro de agregar la capacidad"}
                            cancelButtonText={"No"} confirmButtonText={"Sí"}
                            onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                        >
                        </ModalConfirm>
                    </div>
                }

            </>
        )

    }

}

export default CapabilityNew;