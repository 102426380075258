
import http from './httpService';
import {formatError, ProcessMessages} from './MessageService'


export async function model(data) { 

    let url = '/api/' + data.model + '/model';

    return http.get(url).then( response => {
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: []});
    });
}

export async function list(data) { 

    let url = '/api/' + data.model + '/list';;

    return http.get(url).then( response => {
       // ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: []});
    });
}

export async function filter(data) { 

    let url = '/api/' + data.model + '/filter';;

    return http.get(url,{ params: data}).then( response => {
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: []});
    });
}


export async function deleted(data) { 

    let url = '/api/' + data.model + '/deleted';

    return http.get(url).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: []});
    });
}

export async function store(data) { 

    let url = '/api/' + data.model + '/store';

    return http.post(url, data).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}

export async function update(data) { 
    let url = '/api/' + data.model + '/' + data.id +  '/update';
    return http.post(url,data).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}



export async function get(data) { 
    let url = '/api/' + data.model + '/' + data.id +  '/get';
    return http.get(url,data).then( response => {
        //ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}


export async function recover(data) { 
    let url = '/api/' + data.model + '/' + data.id +  '/recover';
    return http.post(url,data).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}


export async function destroy(data) { 

    let url = '/api/' + data.model + '/' + data.id +  '/destroy';

    return http.delete(url,data).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: {}});
    });
}
