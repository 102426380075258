export function GetGenderName(code){
    if( code === "Mujer") {
        return "Mujer"
    } else if( code === "Hombre") {
        return "Hombre"
    } else if( code === "Otro") {
        return "Otro"
    } else {
        return "No indicado";
    }
}

export const GenderOptions = [
    { "value": "Hombre", "label": "Hombre" },
    { "value": "Mujer", "label": "Mujer" },
    { "value": "Otro", "label": "Otro" }
]