import React, { useState, useEffect } from 'react';

import { useLocale } from "../../../../../context/Locale";
import Input from '../../../Base/Input.js'

import {GetGenderName} from '../../../../../tools/gender.js'
import { getAction } from '../../../../../store/actions/ModelActions.js'
import { useDispatch, useSelector } from "react-redux";

const FormRepresentativeView = (props) => {

    const dispatch = useDispatch();
    const model = "person";    
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const data = useSelector((store) => store.person.data);

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
  
    const getValue = (id, defaultValue) => { return value[id] ? value[id] : defaultValue }

    useEffect(() => {

        if (step === "init") {
            if(props.data !== undefined && props.data.id !== undefined){
                dispatch(getAction(model,{
                    id : props.data.id
                }));
            }
            setStep("edit");

        }else{
            setValue({...data});
        }


    }, [step, data]);


    if (getValue("id", "") === "") {
        return (<></>);
    } else {
        return (<>
            <div className="row">
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"legal_id"} className="form-label">{strings.form.addCompany.labelRepresentative}<span className="text-danger">*</span></label>
                    <Input value={getValue("legal_id", "")} disabled={true} htmlFor="legal_id" type="text" id="legal_id" placeholder=""  ></Input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"first_name"} className="form-label">Nombres<span className="text-danger">*</span></label>
                    <Input value={getValue("first_name", "")} disabled={true} htmlFor="first_name" type="text" id="first_name" placeholder=""  ></Input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"last_name"} className="form-label">Apellidos<span className="text-danger">*</span></label>
                    <Input value={getValue("last_name", "")} disabled={true} htmlFor="last_name" type="text" id="last_name" placeholder=""  ></Input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"gender"} className="form-label">Género<span className="text-danger">*</span></label>
                    <Input value={GetGenderName(getValue("gender", ""))} disabled={true} htmlFor="gender" type="text" id="gender" placeholder=""  ></Input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"email"} className="form-label">Correo<span className="text-danger">*</span></label>
                    <Input value={getValue("email", "")} disabled={true} htmlFor="email" type="text" id="email" placeholder=""  ></Input>
                </div>
                <div className="col-xl-3 mb-3">
                    <label htmlFor={"phone"} className="form-label">Teléfono<span className="text-danger">*</span></label>
                    <Input value={getValue("phone", "")}  disabled={true} htmlFor="phone" type="text" id="phone" placeholder=""  ></Input>
                </div>

            </div>
        </>);
    }


}

export default FormRepresentativeView;