
import { useSelector, useDispatch } from "react-redux";
import { updateDataFormEditAction } from '../../../../store/actions/ModelActions.js'

import Select2RoleCode from '../Input/Select2RoleCode.js'
import Select2UserId from '../Input/Select2UserId.js'

const FormEditUserRole = (props) => {
    const model = "userrole";
    const dispatch = useDispatch();

    const dataForm = useSelector((store) => store.userrole.dataEditForm);

    function onChange(id, value) {
        let dataFormNew = { ...dataForm };
        dataFormNew[id] = value;
        dispatch(updateDataFormEditAction(model,dataFormNew));
    }

    if (props === undefined) {
        return (<div className="row"></div>)
    } else {
        let secureValues = {
            "role_code": dataForm.role_code || "",
            "user_id": dataForm.user_id || ""
        }
        return (
            <div>
                <div className="row">
                    <Select2RoleCode defaultValue={secureValues.role_code} onChange={onChange}  ></Select2RoleCode>
                </div>
                <div className="row">

                    <Select2UserId defaultValue={secureValues.user_id} onChange={onChange}  ></Select2UserId>
                </div>
            </div>
        )
    }
}
export default FormEditUserRole;