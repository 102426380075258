
import FormInput from '../../Form/FormInput.js'
import { useLocale } from "../../../../context/Locale/index.js";

const InputUserEmail = (props) => {
    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    return (
        <FormInput { ...props} textlabel={strings.form.addUser.labelEmail} htmlFor="email"  type="email" id="email" placeholder=""   ></FormInput>
    )
}
export default InputUserEmail;