import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateAction  } from '../../../../store/actions/ModelActions.js'


import ModalConfirm from '../../Common/Modal/ModalConfirm';
import MainPagetitle from '../../../layouts/MainPagetitle.js';
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'
import { useLocale } from "../../../../context/Locale";
import Select2 from '../../Base/Select2.js'

const PersonEdit = (props) => {

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const model = "person";
    const data = useSelector((store) => store.person.dataEditForm);
    const capabilities = useSelector((store) => store.user.capabilities);

    const [showComfirm, setShowConfirm] = useState(false);
    const [step, setStep] = useState("init");
    const [value, setValue] = useState({});
    const [toList, setToList] = useState(false);

    const dispatch = useDispatch();
    const getValue = (id, defaultValue) => {
        return value[id] ? value[id] : defaultValue
    }

    const genderValues = [
        { "value": strings.table.person.gender.male, "label": strings.table.person.gender.male },
        { "value": strings.table.person.gender.female, "label": strings.table.person.gender.female },
        { "value": strings.table.person.gender.other, "label": strings.table.person.gender.other }
    ];

    const buildOptions = (list) => {
        return list.map((item) => { return { "label": item.label, "value": item.value } })
    }

    const getOptionValue = (list, value) => {
        return list.find(i => String(i.value) === String(value))
    }

    useEffect(() => {

        if (step === "init") {
            setValue({
                "id": data.id,
                "first_name": data.first_name,
                "last_name": data.last_name,
                "legal_id": data.legal_id,
                "gender": data.gender,
                "email": data.email,
                "phone": data.phone
            });

        }

        if (step !== "edit") {
            setStep("edit");
        }

    }, [dispatch, step, data]);






    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;;
        setValue(newValue);
    }


    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {

        setToList(true);

        const request = {
            "id": value.id,
            "first_name": value.first_name,
            "last_name": value.last_name,
            "legal_id": value.legal_id,
            "gender": value.gender,
            "email": value.email,
            "phone": value.phone
        }

        dispatch(updateAction(model, request));
        setShowConfirm(false);

    }

    function onCancelForm() {
        setShowConfirm(false);
        if (props.onCancel !== undefined) {
            props.onCancel();
        }
    }

    if (props === undefined || toList) {
        return (<> {toList && (<Navigate to={routers.person.admin} replace={true} />)}</>)
    } else if (!capabilities.map(function (c) { return c.code }).includes('PERSON_EDIT')) {
        return (<></>);
    } else {
        const genderList = buildOptions(genderValues);
        const genderValue = getOptionValue(genderList, getValue("gender", ""));
        return (
            <>

                <MainPagetitle mainTitle={"Persona"} pageTitle={"Editar Persona"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Edición de Datos de Persona</h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className='row' >
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"legal_id"} className="form-label">Rut<span className="text-danger">*</span></label>
                                                    <input id={"legal_id"} type="text" value={getValue("legal_id", "")} disabled={true} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"first_name"} className="form-label">Nombres<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"first_name"} type="text" value={getValue("first_name", "")} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"last_name"} className="form-label">Apellidos<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"last_name"} type="text" value={getValue("last_name", "")} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"gender"} className="form-label">Género</label>
                                                    {(genderValue !== undefined) &&
                                                        < Select2 onChange={onChange} defaultValue={genderValue} options={genderList} id={"gender"} ></Select2>
                                                    }
                                                    {(genderValue === undefined) &&
                                                        < Select2 onChange={onChange} options={genderList} id={"gender"} ></Select2>
                                                    }
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"email"} className="form-label">Correo<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"email"} type="text" value={getValue("email", "")} className="form-control" />
                                                </div>
                                                <div className="col-xl-3 mb-3">
                                                    <label htmlFor={"phone"} className="form-label">Teléfono<span className="text-danger">*</span></label>
                                                    <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"phone"} type="tel" value={getValue("phone", "")} className="form-control" />
                                                </div>
                                                <div>
                                                    <button onClick={() => onSaveFormConfirm()} className="btn btn-primary me-1">{strings.form.common.buttonSubmit}</button>
                                                </div>
                                            </div>

                                            <ModalConfirm
                                                show={showComfirm}
                                                title={"Confirmación"}
                                                message={"¿Está seguro de editar la persona"}
                                                cancelButtonText={"No"} confirmButtonText={"Sí"}
                                                onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                                            >
                                            </ModalConfirm>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </>

        )
    }
}

export default PersonEdit;