import http from './httpService';
import {formatError, ProcessMessages} from './MessageService'

export async function filter(params) { 
    return http.get('/api/region/filter',{ params: params}).then( response => {
        ProcessMessages(response.data);
        return response;
    }).catch(err =>{
        formatError(err);
        return({ data: []});
    });
}
