import { useSelector, useDispatch } from "react-redux";
import Select2 from '../../Base/Select2.js'

import { setDataAction } from '../../../../store/actions/UserListActions.js'
import { enableReloadAction } from '../../../../store/actions/ModelActions.js'

/**
 * 
 * @param {*} props 
 * userid id del usuario seleccionado
 * @returns 
 */
const FormUserSelector = (props) => {
    const dispatch = useDispatch();
    const model = "userrole";
    const users = useSelector((store) => store.user.list);

    const userid = props ? props.value ? props.value : "" : "";

    function onChange(id, value) {
        if (id === "user_id") {
            let found = users.find(r => r.id === value);

            dispatch(setDataAction(found || {}));
            dispatch(enableReloadAction(model));
        }

    }

    if (props === undefined || userid === undefined || !Array.isArray(users)) {
        return (<div className="row"></div>)
    } else {

        let userList = [{ "value": "", "label": "Todos" }].concat(users.map(function (item) {
            return {
                "value": item.id,
                "label": item.name + " " + item.email,
            };
        }));

        const userValue = userList.find(i => i.value === userid);
        return (
            <div className="row">
                <div className="mb-3 row">
                    <label className="col-sm-2 col-form-label">Usuario</label>
                    <div className="col-sm-8">
                        <Select2
                            id={"user_id"}
                            onChange={onChange}
                            defaultValue={userValue}
                            options={userList}
                        ></Select2>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormUserSelector;