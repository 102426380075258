
import { SVGICON } from '../../../constant/theme';

import TotalItemChart from '../Chart/TotalItemChart';

const PaymentsReceivedCard = (props) => {

    const list = props ? (props.list ? props.list : []) : [];

    const predata = list.filter(item => item["amount"] > 0).sort(function(a,b){
        if(a["payment_date"] > b["payment_date"]){
            return 1;
        }else if(a["payment_date"]> b["payment_date"]){
            return -1;
        }else{
            return 0;
        }
    });

    let sumarize = {}
    
    predata.forEach(item => {
        if (sumarize[item["payment_date"].substring(0,7)] === undefined){
            sumarize[item["payment_date"].substring(0,7)] = Number(item["amount"])
        }else{
            sumarize[item["payment_date"].substring(0,7)] = sumarize[item["payment_date"].substring(0,7)] + Number(item["amount"])
        }
    });



    const data = [0].concat(Object.values(predata).map(function(item){ return Number(item["amount"])}));
    const totalAmoutReceived = list.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue["amount"]), 0,
      );

    return (
        <div className="col-xl-3 col-sm-6">
        <div className="card chart-grd same-card">
            <div className="card-body depostit-card p-0">
                <div className="depostit-card-media d-flex justify-content-between pb-0">
                    <div>
                        <h6>Total Pagos Recibidos</h6>
                        <h3>${totalAmoutReceived}</h3>
                    </div>
                    <div className="icon-box bg-primary-light">
                        {SVGICON.DollerPrimary}
                    </div>
                </div>  
                <TotalItemChart data={data} chartcolor="var(--primary)" />                      
            </div>
        </div>
    </div>
    )
}
export default PaymentsReceivedCard;