const Input = (props) => {
    
    function onChange(value) {
        if(props.onChange !== undefined){
            if(props.type !== undefined && props.type === "number"){
                if(props.max !== undefined && !isNaN(props.max )) {
                    value = Math.min(props.max, value);
                }
    
                if(props.min !== undefined && !isNaN(props.min )) {
                    value = Math.max(props.min, value);
                }
            }
            props.onChange(props.id,value);
        }
       
    }


    if(props === undefined){
        return(<div></div>)
    }else {
        return (
            <input type={props.type} value={props.value} onChange={(e) => {onChange(e.target.value); e.preventDefault()}}  className="form-control" />
        )
    }
    
    
}
export default Input;