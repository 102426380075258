
import { useSelector, useDispatch } from "react-redux";
import { updateDataFormEditAction } from '../../../../store/actions/ModelActions.js'

import InputPaymentInscriptionIdReadOnly from '../Input/InputPaymentInscriptionIdReadOnly.js'
import InputPaymentAmount from '../Input/InputPaymentAmount.js'
import InputPaymentPaymentDate from '../Input/InputPaymentPaymentDate.js'
import InputPaymentComment from '../Input/InputPaymentComment.js'

const FormEditPayment = (props) => {

    const model = "payment"
    const dispatch = useDispatch();

    //Datos para editar una ubscripción
    const dataForm = useSelector((store) => store.payment.dataEditForm);

    // Manejador de cambios en los datos de la subscripción
    function onChange(id, value) {
        let dataEditFormNew = { ...dataForm };
        dataEditFormNew[id] = value;
        dispatch(updateDataFormEditAction(model,dataEditFormNew));
    }

    if (props === undefined) {
        return (
            <div className="row"></div>
        )

    } else {
        let secureValues = {
            "company_legal_id": (dataForm.company_legal_id || "") + " " + (dataForm.company_name || ""),
            "amount": dataForm.amount || 0,
            "payment_date": dataForm.payment_date || new Date(),
            "comment": dataForm.comment  || ""
        }
        return (

            <div className="row">
                <InputPaymentInscriptionIdReadOnly disabled={true} value={secureValues.company_legal_id} ></InputPaymentInscriptionIdReadOnly>
                <InputPaymentAmount readonly={true} value={secureValues.amount} ></InputPaymentAmount>
                <InputPaymentPaymentDate disabled={true}  selected={secureValues.payment_date} ></InputPaymentPaymentDate>
                <InputPaymentComment value={secureValues.comment} onChange={onChange}  ></InputPaymentComment>
            </div>
        )
    }




}
export default FormEditPayment;