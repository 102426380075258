import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
    storeAction,
    updateAction,
    getAllAction
} from '../../../../store/actions/ModelActions.js'

import Select2 from '../../Base/Select2.js'
import DateInput from '../../Base/DateInput.js'
import ModalConfirm from '../../Common/Modal/ModalConfirm';
import NavMenu from '../../Common/NavMenu/NavMenu.js';
import MainPagetitle from '../../../layouts/MainPagetitle';
import { useLocale } from "../../../../context/Locale";

import { Navigate } from "react-router-dom";
import routers from '../../../../setting/routes.json'
const InscriptionNew = (props) => {

    const { stateLocale } = useLocale();
    const { strings } = stateLocale;

    const dispatch = useDispatch();
    const model = "inscription"
    const capabilities = useSelector((store) => store.user.capabilities);
    const inscriptions = useSelector((store) => store.inscription.list);
    const companies = useSelector((store) => store.company.list);
    const feetemplates = useSelector((store) => store.feetemplate.list);
    const people = useSelector((store) => store.person.list);

    const [showComfirm, setShowConfirm] = useState(false);
    const [company, setCompany] = useState({});
    const [feeTemplate, setFeeTemplate] = useState({});
    const [person, setPerson] = useState({});

    const [sendNewPerson, setSendNewPerson] = useState(true);
    const [sendNewCompany, setSendNewCompany] = useState(true);
    const [toList, setToList] = useState(false);

    const [step, setStep] = useState("init");
    const [value, setValue] = useState({
        company_id: 0,
        company_legal_id: "",
        company_name: "",
        company_category: "",
        company_branch_number: 1,
        fee_amount: 1,
        fee_initial_fee: 0,
        fee_amount_fees: 1,
        fee_payment_day: 1,
        fee_comment: "",
        fee_start_date: new Date(),
        person_legal_id: "",
        person_first_name: "",
        person_last_name: "",
    });


    const companiesFiltered = companies.filter(function(item){
        let found = inscriptions.find( u => u.company_id === item.id);
        return found === undefined
    });

    const saleLevelValues = [].concat([
        { "label": "Alta", "value": "alta" },
        { "label": "Media", "value": "media" },
        { "label": "Baja", "value": "baja" }
    ]);

    const sizeValues = [].concat([
        { "label": "Micro", "value": "micro" },
        { "label": "Mediana", "value": "mediana" },
        { "label": "Grande", "value": "grande" }
    ]);

    const genderValues = [
        { "value": strings.table.person.gender.male, "label": strings.table.person.gender.male },
        { "value": strings.table.person.gender.female, "label": strings.table.person.gender.female },
        { "value": strings.table.person.gender.other, "label": strings.table.person.gender.other }
    ];

    const buildOptions = (list) => {
        return list.map((item) => { return { "label": item.label, "value": item.value } })
    }
    const buildOptionsCompanies = (list) => {
        return [{ "label": "Nueva", "value": "" }].concat(list.map((item) => { return { "label": item.legal_id + " " + item.name, "value": item.id } }))
    }
    const buildOptionsFeeTemplates = (list) => {
        return list.map((item) => { return { "label": item.name, "value": item.id } })
    }
    const buildOptionsPeople = (list) => {
        return [{ "label": "Nueva", "value": 0 }].concat(list.map((item) => { return { "label": item.legal_id + " " + item.first_name + " " + item.last_name, "value": item.id } }));
    }
    const getOptionValue = (list, value) => {
        const found = list.find(i => String(i.value) === String(value))
        if (found !== undefined) {
            return { ...found }
        } else {
            return found;
        }
    }



    const getValue = (id, defaultValue) => {
        return value[id] ? value[id] : defaultValue;
    }

    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction("inscription", {}));
            dispatch(getAllAction("company", {}));
            dispatch(getAllAction("person", {}));
            dispatch(getAllAction("feetemplate", {}));

        }

        if (step !== "edit") {
            setStep("edit");
        }

    }, [dispatch, setValue, setSendNewPerson, setFeeTemplate, setSendNewCompany, company, person, feeTemplate, step, companies, feetemplates, people, value]);

    function onChange(id, data) {
        let newValue = { ...value };
        newValue[id] = data;

        if (id === "company_id_selected") {
            const companyNew = companies.find(c => String(c.id) === String(data));
            if (companyNew !== undefined) {
                newValue.company_id = companyNew.id;
                newValue.company_legal_id = companyNew.legal_id;
                newValue.company_name = companyNew.name;
                newValue.company_category = companyNew.category;
                newValue.company_size = companyNew.size;
                newValue.company_size_selected = companyNew.size;
                newValue.company_sales_level = companyNew.sales_level;
                newValue.company_sales_level_selected = companyNew.sales_level;
                newValue.company_branch_number = companyNew.branch_number;

                const personNew = people.find(i => String(i.id) === String(companyNew.representative_id));
                if (personNew !== undefined) {
                    newValue.person_id = person.id;

                    newValue.person_id_selected = personNew.id;
                    newValue.person_legal_id = personNew.legal_id;
                    newValue.person_first_name = personNew.first_name;
                    newValue.person_last_name = personNew.last_name;
                    newValue.person_gender = personNew.gender;
                    newValue.person_gender_selected = personNew.gender;
                    newValue.person_email = personNew.email;
                    newValue.person_phone = personNew.phone;
                }

                //setCompany({ ...companyNew });
                //setPerson({ ...representative });
            }



            setValue(newValue);



        } else if (id === "fee_template_id_selected") {

            const feeTemplateNew = feetemplates.find(f => String(f.id) === String(data));
            //setFeeTemplate(feeTemplateNew !== undefined ? { ...feeTemplateNew } : {})
            if (feeTemplateNew !== undefined) {
                newValue.fee_template_id = feeTemplateNew.id;

                newValue.fee_template_id = feeTemplateNew.id;
                newValue.fee_template_id_selected = feeTemplateNew.id;
                newValue.fee_amount = feeTemplateNew.amount;
                newValue.fee_initial_fee = feeTemplateNew.initial_fee;

                newValue.fee_initial_fee = feeTemplateNew.initial_fee;
                newValue.fee_amount_fees = feeTemplateNew.amount_fees;
                newValue.fee_payment_day = feeTemplateNew.payment_day;
            }
            setValue(newValue);
        } else if (id === "person_id_selected") {

            const personNew = people.find(p => String(p.id) === String(data));
            //setPerson(personNew !== undefined ? { ...personNew } : {});

            if (personNew !== undefined) {

                newValue.person_id = person.id;
                newValue.person_legal_id = personNew.legal_id;
                newValue.person_first_name = personNew.first_name;
                newValue.person_last_name = personNew.last_name;
                newValue.person_gender = personNew.gender;
                newValue.person_gender_selected = personNew.gender;
                newValue.person_email = personNew.email;
                newValue.person_phone = personNew.phone;


            }
            setValue(newValue);
        } else {
            setValue(newValue);
        }

        // if (['company_legal_id', 'company_name', 'company_category', 'company_size_selected', 'company_sales_level_selected', 'company_branch_number'].indexOf(id) > 0) {
        //     setSendNewCompany(true);
        // }

        // if (['person_legal_id', 'person_first_name', 'person_last_name', 'person_gender_selected', 'person_email', 'person_phone'].indexOf(id) > 0) {
        //     setSendNewPerson(true);
        // }


    }

    function onSaveFormConfirm() {
        setShowConfirm(true);
    }

    function onSaveForm() {


        const requestPerson = {

            
        }


        const requestCompany ={
            
        }



        const requestInscription = {
            
            company_representative_legal_id: value.person_legal_id,
            company_representative_first_name: value.person_first_name || "",
            company_representative_last_name: value.person_last_name || "",
            company_representative_gender: value.person_gender_selected || "",
            company_representative_email: value.person_email || "",
            company_representative_phone: value.person_phone || "",

            company_legal_id: value.company_legal_id,
            company_name: value.company_name || "",
            company_category: value.company_category || "",
            company_size: value.company_size_selected || "",
            company_sales_level: value.company_sales_level_selected,
            company_phone: "",
            company_branch_number: value.company_branch_number || 1,

            status: 'active',
            start_date: value.fee_start_date,
            amount: value.fee_amount * (value.company_branch_number || 1),
            initial_fee: value.fee_initial_fee,
            amount_fees: value.fee_amount_fees,
            payment_day: value.fee_payment_day,
            comment: value.fee_comment
            
        }


        if(value.person_id_selected !== ""){
            requestInscription.company_representative_id = value.person_id_selected;
        }

        if(value.company_id_selected !== ""){
            requestInscription.company_id = value.company_id_selected; 
        }

        dispatch(storeAction(model, requestInscription));
        


        setShowConfirm(false);

        if (props.onSave !== undefined) {
            props.onSave();
        }
        setToList(true);
    }

    function onCancelForm() {
        setShowConfirm(false);
    }


    if (props === undefined || step === "init") {
        return (<></>)
    } else if (!capabilities.map(function (c) { return c.code }).includes('INSCRIPTION_CREATE')) {
        return (<></>);
    } else {

        const sizeList = buildOptions(sizeValues);
        const sizeValue = getOptionValue(sizeList, getValue("company_size_selected", ""));

        const saleLevelList = buildOptions(saleLevelValues);
        const saleLevelValue = getOptionValue(saleLevelList, getValue("company_sales_level_selected", ""));

        const genderList = buildOptions(genderValues);
        const genderValue = getOptionValue(genderList, getValue("person_gender_selected", ""));

        const personList = buildOptionsPeople(people);
        const personValue = getOptionValue(personList, person.id || 0);

        return (
            <>
                {toList && (<Navigate to={routers.inscription.admin} replace={true} />)}

                <MainPagetitle mainTitle={"Inscripción"} pageTitle={"Nueva Inscripción"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    <div className="row">
                        <div className="col-xl-12"> <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Plan de Cuota</h4>
                            </div>
                            <div className="card-body">
                                <div className="basic-form">
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"fee_template_id_selected"} className="form-label">Buscar</label>
                                                <Select2 onChange={onChange} options={buildOptionsFeeTemplates(feetemplates)} id={"fee_template_id_selected"} placheholder={"Seleccione..."} ></Select2>
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"fee_initial_fee"} className="form-label">Cuota Inicial<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"fee_initial_fee"} type="number" value={getValue("fee_initial_fee", 0)} min={1} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"fee_amount"} className="form-label">{"Monto Mensual"}<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"fee_amount"} type="number" value={getValue("fee_amount", 1)} min={1} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"fee_amount_fees"} className="form-label">Cantidad de Cuotas<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"fee_amount_fees"} type="number" value={getValue("fee_amount_fees", 1)} min={1} max={36} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"fee_payment_day"} className="form-label">Día de Pago<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"fee_payment_day"} type="number" value={getValue("fee_payment_day", 1)} min={1} max={31} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <div className="row" ><label htmlFor={"fee_start_date"} className="form-label">Fecha de inicio<span className="text-danger">*</span></label></div>
                                                <div className="row" ><DateInput onChange={onChange} id={"fee_start_date"} selected={getValue("fee_start_date", new Date())} ></DateInput></div>
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"fee_comment"} className="form-label">Comentario<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"fee_comment"} type="text" value={getValue("fee_comment", "")} className="form-control" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div></div>

                    </div>

                    <div className="row">
                        <div className="col-xl-12"> <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Empresa</h4>
                            </div>
                            <div className="card-body">
                                <div className="basic-form">
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"company_id_selected"} className="form-label">Buscar</label>
                                                <Select2 onChange={onChange} options={buildOptionsCompanies(companiesFiltered)} id={"company_id_selected"} ></Select2>
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"company_legal_id"} className="form-label">Rut<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"company_legal_id"} type="text" value={getValue("company_legal_id", "")} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"company_name"} className="form-label">Nombre<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"company_name"} type="text" value={getValue("company_name", "")} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"company_category"} className="form-label">Rubro<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"company_category"} type="text" value={getValue("company_category", "")} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"company_size_selected"} className="form-label">Tamaño</label>
                                                {(sizeValue !== undefined) &&
                                                    <Select2 onChange={onChange} defaultValue={sizeValue} options={sizeList} id={"company_size_selected"} ></Select2>
                                                }
                                                {(sizeValue === undefined) &&
                                                    <Select2 onChange={onChange} options={sizeList} id={"company_size_selected"} ></Select2>
                                                }
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"company_sales_level_selected"} className="form-label">Nivel de Venta</label>
                                                {(saleLevelValue !== undefined) &&
                                                    <Select2 onChange={onChange} defaultValue={saleLevelValue} options={saleLevelList} id={"company_sales_level_selected"} ></Select2>
                                                }
                                                {(saleLevelValue === undefined) &&
                                                    <Select2 onChange={onChange} options={saleLevelList} id={"company_sales_level_selected"} ></Select2>
                                                }
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"company_branch_number"} className="form-label">{"Número de Locales"}<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"company_branch_number"} type="number" min={0} value={getValue("company_branch_number", "")} className="form-control" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div></div>

                    </div>

                    <div className="row">
                        <div className="col-xl-12"> <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Representante</h4>
                            </div>
                            <div className="card-body">
                                <div className="basic-form">
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"person_id_selected"} className="form-label">Buscar</label>
                                                {(personValue !== undefined) &&
                                                    <Select2 onChange={onChange} defaultValue={personValue} options={personList} id={"person_id_selected"} ></Select2>
                                                }
                                                {(personValue === undefined) &&
                                                    <Select2 onChange={onChange} options={personList} id={"person_id_selected"} ></Select2>
                                                }
                                            </div><div className="col-xl-3 mb-3">
                                                <label htmlFor={"person_legal_id"} className="form-label">Rut<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"person_legal_id"} type="text" value={getValue("person_legal_id", "")} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"person_first_name"} className="form-label">Nombres<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"person_first_name"} type="text" value={getValue("person_first_name", "")} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"person_last_name"} className="form-label">Apellidos<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"person_last_name"} type="text" value={getValue("person_last_name", "")} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"person_gender_selected"} className="form-label">Género</label>
                                                {(genderValue !== undefined) &&
                                                    < Select2 onChange={onChange} defaultValue={genderValue} options={genderList} id={"person_gender_selected"} ></Select2>
                                                }
                                                {(genderValue === undefined) &&
                                                    < Select2 onChange={onChange} options={genderList} id={"person_gender_selected"} ></Select2>
                                                }
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"person_email"} className="form-label">Correo<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"person_email"} type="text" value={getValue("person_email", "")} className="form-control" />
                                            </div>
                                            <div className="col-xl-3 mb-3">
                                                <label htmlFor={"person_phone"} className="form-label">Teléfono<span className="text-danger">*</span></label>
                                                <input onChange={(e) => { onChange(e.target.id, e.target.value); e.preventDefault() }} id={"person_phone"} type="tel" value={getValue("person_phone", "")} className="form-control" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div >
                        </div ></div>

                    </div>

                    <div className="row">
                        <div className="col-xl-3 mb-3">
                            <Button className="btn-sm" onClick={() => onSaveFormConfirm()} variant="primary"> {"Enviar"}</Button>
                        </div>
                    </div>

                    <ModalConfirm
                        show={showComfirm}
                        title={"Confirmación"}
                        message={"¿Está seguro de agregar la inscripción"}
                        cancelButtonText={"No"} confirmButtonText={"Sí"}
                        onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onSaveForm}
                    >
                    </ModalConfirm>
                </div>

            </>
        )
    }
}
export default InscriptionNew;