
import { RELOAD_ENABLE_ACTION, RELOAD_DISABLE_ACTION } from '../actions/NotificationActions';

import {
    SET_TIMER_ACTION,
    SET_GETALLINPROGRESS_ACTION,
    GETALL_CONFIRMED_ACTION,
    GETALL_FAILED_ACTION,
    SET_DATA_ACTION

} from '../actions/NotificationListActions';

import {
    OPEN_FORMNEW_ACTION,
    UPDATE_DATAFORMNEW_ACTION,
    STORE_CONFIRMED_ACTION,
    STORE_FAILED_ACTION

} from '../actions/NotificationStoreActions';


import {
    OPEN_FORMEDIT_ACTION,
    UPDATE_DATAFORMEDIT_ACTION,
    UPDATE_CONFIRMED_ACTION,
    UPDATE_FAILED_ACTION

} from '../actions/NotificationEditActions';

import { DESTROY_CONFIRMED_ACTION, DESTROY_FAILED_ACTION } from '../actions/NotificationDestroyActions';


const initialState = {
    updated: (new Date()).getTime(),
    dispathGetAll: true,

    dispathGetAllInprogress: false,

    list: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    dataNewForm: {},
    dataEditForm: {},
    data: {},
    reload: true,
};

export function NotificationReducer(state = initialState, action) {


    
    if (action.type === STORE_FAILED_ACTION) {
        return {
            ...state,
            dataNewForm: {},
            reload: true,
        };
    }
    
    if (action.type === UPDATE_FAILED_ACTION) {
        return {
            ...state,
            dataEditForm: {},
            reload: true,
        };
    }

    if (action.type === DESTROY_FAILED_ACTION) {
        return {
            ...state,
            dataNewForm: {},
            reload: true,
        };
    }

    if (action.type === GETALL_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: action.payload,
            dispathGetAllInprogress: false,
            successMessage: '',
            showLoading: false,
            reload: false,
        };
    }


    if (action.type === GETALL_CONFIRMED_ACTION) {
        return {
            ...state,
            list: action.payload,
            dispathGetAllInprogress: false,
            errorMessage: '',
            successMessage: 'GetAll Successfully Completed',
            showLoading: false,
            reload: false,
        };
    }


    if (action.type === STORE_CONFIRMED_ACTION) {
        return {
            ...state,
            dataNewForm: {},
            dispathGetAll: true,
        };
    }

    if (action.type === UPDATE_CONFIRMED_ACTION) {
        return {
            ...state,
            dataEditForm: {},
            dispathGetAll: true,
        };
    }

    if (action.type === DESTROY_CONFIRMED_ACTION) {
        return {
            ...state,
            dataNewForm: {},
            dispathGetAll: true,
        };
    }

     

    if (action.type === OPEN_FORMEDIT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            dataEditForm: action.payload,
        };
    }


    if (action.type === UPDATE_DATAFORMEDIT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            dataEditForm: action.payload,
        };
    }


    if (action.type === RELOAD_ENABLE_ACTION) {
        return {
            ...state,
            reload: true
        };
    }

    if (action.type === RELOAD_DISABLE_ACTION) {
        return {
            ...state,
            reload: false
        };
    }

    if (action.type === SET_TIMER_ACTION) {
        return {
            ...state,
            updated: action.payload
        };
    }

    if (action.type === SET_DATA_ACTION) {
        return {
            ...state,
            data: action.payload
        };
    }

    if (action.type === SET_GETALLINPROGRESS_ACTION) {
        return {
            ...state,
            dispathGetAllInprogress: action.payload
        };
    }

    if (action.type === OPEN_FORMNEW_ACTION) {
        return {
            ...state,
            errorMessage: '',
            dataNewForm: action.payload,
        };
    }
    
    if (action.type === UPDATE_DATAFORMNEW_ACTION) {
        return {
            ...state,
            errorMessage: '',
            dataNewForm: action.payload,
        };
    }

    return state;
}