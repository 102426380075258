import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';
import NavMenu from '../Common/NavMenu/NavMenu.js';
import ModalConfirm from '../Common/Modal/ModalConfirm';

import { useLocale } from "../../../context/Locale";

import { formatIsoDateToString } from '../../../tools/fomatter'

import {
    getAllAction,
    openNewFormAction,
    destroyAction,
    openEditFormAction
} from '../../../store/actions/ModelActions'


import { useSelector, useDispatch } from "react-redux";
import Grid from '../Common/Grid/Grid';
import routers from '../../../setting/routes.json'

const Inscription = () => {


    const pageName = "inscriptions"
    const model = "inscription"
    

    const capabilities = useSelector((store) => store.user.capabilities);
    const HasCapability = useSelector((store) => store.user.HasCapability);

    const canView = HasCapability(capabilities,'PAGE_INSCRIPTION_LIST');
    const newItem = HasCapability(capabilities,"BTN_INSCRIPTION_CREATE");
    const viewItem = HasCapability(capabilities,"BTN_INSCRIPTION_GET");
    const destroyItem = HasCapability(capabilities,"BTN_INSCRIPTION_DESTROY");
    const exportData = HasCapability(capabilities,"BTN_INSCRIPTION_EXPORT");

    const path = useSelector((store) => store.common.path);
    const list = useSelector((store) => store.inscription.list);


    const { stateLocale } = useLocale();
    const { strings } = stateLocale;
    const [toDestroy, setToDestroy] = useState({});
    const [toEdit, setToEdit] = useState(false);
    const [toNew, setToNew] = useState(false);
    const [showComfirmDestroy, setShowConfirmDestroy] = useState(false);
    const [step, setStep] = useState("init");


    const dispatch = useDispatch();

    const gridConf = [
        { label: strings.table.inscription.header.legal_id, key: "company_legal_id", className: "", filter: true, type: "select" },
        { label: strings.table.inscription.header.company_name, key: "company_name", className: "", filter: true },
        { label: "Rut Representante", key: "person_legal_id", className: "", filter: true },
        { label: "Zona", key: "zone", className: "", filter: true },
        { label: "Cuota Inicial", key: "initial_fee", className: "", filter: true },
        { label: strings.table.inscription.header.amount, key: "amount", className: "" },
        { label: strings.table.inscription.header.amount_fees, key: "amount_fees", className: "" },
        // { label: strings.table.inscription.header.payment_day, key: "payment_day", filter: true },
        { label: strings.table.inscription.header.start_date, key: "start_date", "formatter": formatIsoDateToString },
        { label: "Número de Locales", key: "company_branch_number" }
    ]


    useEffect(() => {

        if (step === "init") {
            dispatch(getAllAction(model, {}));
            setStep("loaded");
        }

    }, [dispatch,step]);


    function onDestroyFornConfirm(item) {
        setToDestroy(item)
        setShowConfirmDestroy(true);
    }

    function onDestroyForm() {
        dispatch(destroyAction(model, toDestroy));
        setShowConfirmDestroy(false);
        setTimeout(()=>{dispatch(getAllAction(model, {}));}, 500);
    }


    function onCancelForm() {
        setShowConfirmDestroy(false);
    }

    function onClickNewForm() {
        dispatch(openNewFormAction(model, {
            "legal_id": "",
            "amount": 0,
            "amount_fees": 1,
            "payment_day": 1,
            "start_date": new Date(),
            "comment": "",
            "company_name": "",
            "fee_template_id": 0,
            "branch_number": 1
        }));
        setToNew(true)
    }


    function onClickViewForm(item) {
        dispatch(openEditFormAction(model, item));
        setToEdit(true);

    }

    if (path !== pageName && path !== "") {
        return (<></>);
    } else {
        return (
            <>
                {toEdit && (<Navigate to={routers.inscription.view} replace={true} />)}
                {toNew && (<Navigate to={routers.inscription.new} replace={true} />)}
                <MainPagetitle mainTitle={strings.page.inscription.title} pageTitle={"Listado de Inscripciones"} parentTitle={strings.page.home.title} />
                <div className="container-fluid">
                    <NavMenu />
                    {canView &&
                        <div className="row">
                            <div className="col-xl-12">
                                <Grid
                                    list={list}
                                    title={"Listado de Inscripciones"}
                                    conf={gridConf}
                                    onClickNewItem={onClickNewForm}
                                    onClickDestroy={onDestroyFornConfirm}
                                    onClickView={onClickViewForm}

                                    newItem={newItem}
                                    viewItem={viewItem}
                                    destroyItem={destroyItem}
                                    exportData={exportData}

                                    buttonAddLabel={"+ Nueva Inscripción"}
                                    placeholderFilter={"Buscar Inscripción"}

                                ></Grid>
                            </div>
                        </div>
                    }
                </div>
                <ModalConfirm
                    show={showComfirmDestroy}
                    title={"Confirmación"}
                    message={"¿Está seguro de eliminar la inscripción"}
                    cancelButtonText={"No"} confirmButtonText={"Sí"}
                    onCloseAction={onCancelForm} onCancelAction={onCancelForm} onConfirmAction={onDestroyForm}
                >
                </ModalConfirm>
            </>
        );
    }





};

export default Inscription;